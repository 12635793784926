import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import FormHelperText from '@material-ui/core/FormHelperText'
import CloseIcon from '@material-ui/icons/Close'
import { firestore } from '../../firebase'
import Checkbox from '@material-ui/core/Checkbox'

const useStyles = makeStyles(theme => ({
  table: {
    width: 'auto',
  },
  formControl: {
    display: 'block',
    margin: '12px 0',
    color: 'black',
    opacity: 0.9,
  },
}))

const Title = styled(DialogTitle)`
  color: white !important;
  background-color: #7f9e5d;
  padding-right: 16px !important;
  h2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`
const ChartOptionsDialog = styled(Dialog)`
  .MuiPaper-root {
    min-width: 600px;
    padding: 0;
  }
  .MuiCheckbox-root {
    margin-bottom: 4px;
    margin-left: -12px;
  }
`
const CloseModalIcon = styled(CloseIcon)`
  cursor: pointer;
`
const ActionsWrapper = styled.div`
  padding: 8px 15px;
`
const ChartOptionsModal = ({ onCancel, onSave, options }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [values, setValues] = useState(options)

  const onOk = () => {
    onSave(values)
  }

  const handleChange = (event, rest) => {
    const { name, value } = event.target
    setValues(preValue => ({ ...preValue, [name]: value }))
  }

  const handleCheckboxChange = (event, value) => {
    const { name } = event.target
    setValues(preValue => ({ ...preValue, [name]: value }))
  }

  // console.log('values', values)
  return (
    <ChartOptionsDialog open onClose={onCancel}>
      <Title>
        <span>{t('charts.configDialog.header')}</span> <CloseModalIcon onClick={onCancel} />
      </Title>
      <DialogContent style={{ minWidth: '20rem' }}>
        <FormControl className={classes.formControl}>
          <TextField
            id="chartName"
            name="chartName"
            fullWidth
            label={t('charts.configDialog.title')}
            value={values.chartName}
            onChange={handleChange}
            InputLabelProps={{ shrink: !!values.chartName }}
          />
        </FormControl>
        <br />
        <FormControl className={classes.formControl}>
          <Checkbox name="hideLabels" checked={values.hideLabels} onChange={handleCheckboxChange} /> {t('charts.configDialog.hideValues')}
        </FormControl>
        <FormControl className={classes.formControl}>
          <Checkbox name="transparentBackground" checked={values.transparentBackground} onChange={handleCheckboxChange} />{' '}
          {t('charts.configDialog.downloadTransparent')}
        </FormControl>
        <FormControl className={classes.formControl}>
          <Checkbox name="showSeconds" checked={values.showSeconds} onChange={handleCheckboxChange} /> {t('charts.configDialog.secondsUnit')}
        </FormControl>
        <FormControl className={classes.formControl}>
          <div>
            <strong>{t('charts.configDialog.showZeroValues')}</strong>
          </div>
          <Checkbox name="showZeroCategories" checked={values.showZeroCategories} onChange={handleCheckboxChange} />{' '}
          {t('charts.configDialog.categories')}
          <span style={{ padding: '0 30px' }}>{'  '}</span>
          <Checkbox name="showZeroSubcategories" checked={values.showZeroSubcategories} onChange={handleCheckboxChange} />{' '}
          {t('charts.configDialog.subcategories')}
        </FormControl>
      </DialogContent>
      <ActionsWrapper>
        <DialogActions>
          <Button onClick={onCancel} color="primary">
            {t('common.CANCEL')}
          </Button>
          <Button onClick={onOk} variant="contained" color="secondary">
            {t('common.SAVE')}
          </Button>
        </DialogActions>
      </ActionsWrapper>
    </ChartOptionsDialog>
  )
}

export default ChartOptionsModal
