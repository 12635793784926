import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CSVLink } from 'react-csv'
import { differenceInSeconds, format } from 'date-fns'
import { pathOr } from 'ramda'
import styled from '@emotion/styled'

import { makeStyles } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Chip from '@material-ui/core/Chip'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import PublishIcon from '@material-ui/icons/Publish'
import Button from '@material-ui/core/Button'
import CameraAltIcon from '@material-ui/icons/CameraAlt'
import CommentIcon from '@material-ui/icons/Comment'
import Fade from '@material-ui/core/Fade'
import Popover from '@material-ui/core/Popover'

import TabPanel from '../pageParts/TabPanel'
import { firestore } from '../../firebase'
import { formatDate, timeDifference } from '../../utils/misc'

export const MEASUREMENT_FIELDS = ['sector', 'discipline', 'department', 'contractType', 'resource', 'workType', 'shift', 'workshop']

const Summary = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 1rem;
`
const ActivitiesWrapper = styled.div``

const Activity = styled(Paper)`
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
`
const TimeInfo = styled.div`
  display: flex;
  justify-content: center;
  width: 10rem;
`
const Duration = styled.div`
  padding-right: 3rem;
  width: 8rem;
  text-align: right;
`

const Category = styled.span``
const Subcategory = styled.span``
const PicInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  svg {
    margin-left: 7px;
    cursor: pointer;
  }
  width: 6rem;
`
const Remark = styled.span`
  font-size: 0.8rem;
  margin-bottom: 7px;
`
const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3rem;
`
const MeasurementMeta = styled.div`
  text-align: center;
  margin: 0.5rem 2rem 1.5rem;
`
const DownloadIcon = styled(PublishIcon)`
  transform: rotate(180deg);
`
const Thumbnails = styled.div`
  img {
    height: 40px;
    cursor: pointer;
    margin-left: 10px;
  }
`
const ThumbnailsWrapper = styled.div`
  margin-left: -10px;
`

const useStyles = makeStyles(theme => ({
  dpAvoidable: {
    borderLeft: '7px solid #72C042',
  },
  dpUnavoidable: {
    borderLeft: '7px solid green',
  },
  ipAvoidable: {
    borderLeft: '7px solid #9DDCFF',
  },
  ipUnavoidable: {
    borderLeft: '7px solid blue',
  },
  npAvoidable: {
    borderLeft: '7px solid #FBA4A3',
  },
  npUnavoidable: {
    borderLeft: '7px solid red',
  },
  pz: {
    borderLeft: '7px solid black',
  },
  na: {
    borderLeft: '7px solid grey',
  },
  selected: {
    backgroundColor: 'gainsboro',
    border: '3px solid dimgray',
  },
  iconButton: {
    borderRadius: '50%',
    padding: '8px',
  },
}))

export const activityClassName = (categoryType, avoidable) => {
  switch (categoryType) {
    case 'dp':
      return avoidable ? 'dpAvoidable' : 'dpUnavoidable'
    case 'ip':
      return avoidable ? 'ipAvoidable' : 'ipUnavoidable'
    case 'np':
      return avoidable ? 'npAvoidable' : 'npUnavoidable'
    case 'pz':
      return 'pz'
    default:
      return 'na'
  }
}

const Details = ({ measurements = [] }) => {
  const { t, i18n } = useTranslation()
  const [tabIndex, setTabIndex] = useState(0)
  const [fetchedActivities, setFetchedActivities] = useState({})
  const [loadingThumbnails, setLoadingThumbnails] = useState({})
  const [fetchedThumbnails, setFetchedThumbnails] = useState({})
  const [fetchedPics, setFetchedPics] = useState({})
  const [commentVisible, showComment] = useState({})
  const [thumbnailsVisible, showThumbnails] = useState({})
  const [picToPreview, setPicToPreview] = useState(null)
  const [loadingPic, setLoadingPic] = useState(false)
  const { licenseId, projectId } = useParams()
  const classes = useStyles()

  const switchTab = (event, newValue) => {
    setTabIndex(newValue)
  }

  useEffect(() => {
    const measurement = measurements[tabIndex]
    if (measurement && !fetchedActivities[measurement.id]) {
      const summaryRef = firestore.doc(`data/${licenseId}/projects/${projectId}/measurements/${measurement.id}/summary/activities`)
      summaryRef.get().then(doc => {
        if (doc.exists) {
          setFetchedActivities({ ...fetchedActivities, [measurement.id]: doc.data() })
        }
      })
    }
  }, [tabIndex, measurements, licenseId, projectId, fetchedActivities])

  const toggleThumbnails = async (measurementId, activityId) => {
    const willBecomeVisible = !thumbnailsVisible[activityId]
    showThumbnails(prevValue => ({ ...prevValue, [activityId]: !prevValue[activityId] }))
    if (willBecomeVisible && !fetchedThumbnails[activityId]) {
      setLoadingThumbnails(prevValue => ({ ...prevValue, [activityId]: true }))

      const thumbnailsRef = firestore.collection(
        `data/${licenseId}/projects/${projectId}/measurements/${measurementId}/activities/${activityId}/thumbnails`,
      )
      // const result = []

      const snapshot = await thumbnailsRef.get()

      // snapshot.docs.forEach(async (doc, index) => {
      //   const upload = await firebaseStorage
      //     .ref()
      //     .child('hoho/hihi/' + activityId + '_' + doc.id + '.jpg')
      //     .putString(doc.data().data, 'data_url')
      //
      //   const dowloadUrl = await upload.ref.getDownloadURL()
      //   result.push(dowloadUrl)
      //   if (index === snapshot.docs.length - 1) {
      //     console.log('hohohoho', result)
      //   }
      //   // upload.ref.getDownloadURL().then(function(downloadURL) {
      //   //   console.log('File available at', downloadURL)
      //   //   result.push(downloadURL)
      //   // })
      //   // console.log('uploaded', snapshot);
      // })
      // console.log('result', result)

      setFetchedThumbnails(prevValue => ({ ...prevValue, [activityId]: snapshot.docs.map(doc => ({ id: doc.id, data: doc.data().data })) }))
      setLoadingThumbnails(prevValue => ({ ...prevValue, [activityId]: false }))
    }
  }

  const [anchorEl, setAnchorEl] = React.useState(null)
  const showPic = (measurementId, activityId, picId) => async event => {
    setAnchorEl(event.currentTarget)
    // setAnchorEl(anchorEl ? null : event.currentTarget)
    setPicToPreview(picId)
    if (!fetchedPics[picId]) {
      setLoadingPic(true)
      const picRef = firestore.doc(`data/${licenseId}/projects/${projectId}/measurements/${measurementId}/activities/${activityId}/pics/${picId}`)
      // const result = []

      const doc = await picRef.get()
      setFetchedPics(prevState => ({ ...prevState, [picId]: doc.data().data }))
      setLoadingPic(false)
    } else {
      setLoadingPic(false)
    }
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popper' : undefined

  return (
    <Container maxWidth="md" style={{ paddingBottom: '200px' }}>
      <Tabs
        value={tabIndex}
        onChange={switchTab}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        style={{
          backgroundColor: '#FAFAFA',
          paddingBottom: '5px',
        }}
      >
        {measurements.map(measurement => (
          <Tab key={measurement.id} label={measurement.name} />
        ))}
      </Tabs>
      {measurements.map((measurement, index) => {
        const summary = fetchedActivities[measurement.id]
        const activities = pathOr([], ['summary'], summary)

        const data = [
          ['name', measurement.name],
          ['date', formatDate(measurement.created.toDate(), 'MMM dd, yyyy', i18n.language)],
          [''],
          [t('measurement.sector'), t(`options.sector.${measurement.sector}`)],
          [t('measurement.discipline'), t(`options.discipline.${measurement.discipline}`)],
          [t('measurement.department'), t(`options.department.${measurement.department}`)],
          [t('measurement.contractType'), t(`options.contractType.${measurement.contractType}`)],
          [t('measurement.resource'), t(`options.resource.${measurement.resource}`)],
          [t('measurement.workType'), t(`options.workType.${measurement.workType}`)],
          [t('measurement.shift'), t(`options.shift.${measurement.shift}`)],
          [t('measurement.workshop'), t(`options.workshop.${measurement.workshop}`)],
          [''],
          ['Begin', 'End', 'Duration', 'Avoidable', 'Category', 'Subcategory', 'Remark'],
          ...activities.map((a, index) => {
            const end = index === activities.length - 1 ? measurement.end : activities[index + 1].begin
            return [
              format(a.begin.toDate(), 'HH:mm:ss'),
              format(end.toDate(), 'HH:mm:ss'),
              differenceInSeconds(end.toDate(), a.begin.toDate()),
              String(!!a.avoidable),
              t(`options.category.${a.category}`),
              a.subcategory ? t(`options.subcategory.${a.subcategory}`) : '',
              a.remark || '',
            ]
          }),
        ]
        return (
          <TabPanel value={tabIndex} index={index} key={measurement.id}>
            {summary ? (
              <>
                <Summary>
                  <span>{formatDate(measurement.created.toDate(), 'MMM dd, yyyy', i18n.language)}</span>
                  <span>
                    {activities.length} {t('charts.activities')}
                  </span>
                  {activities.length > 0 && <span>{timeDifference(activities[0].begin.toDate(), measurement.end.toDate())}</span>}
                  <CSVLink data={data} filename={`${measurement.name}.csv`}>
                    <Button className={classes.iconButton} variant="contained">
                      <DownloadIcon />
                    </Button>
                  </CSVLink>
                </Summary>

                <MeasurementMeta>
                  {MEASUREMENT_FIELDS.map((field, index) => (
                    <Chip variant="outlined" label={t(`options.${field}.${measurement[field]}`)} key={`${measurement.id}_${index}`} />
                  ))}
                </MeasurementMeta>

                <ActivitiesWrapper>
                  {activities.map((activity, index) => {
                    const end = index === activities.length - 1 ? measurement.end : activities[index + 1].begin
                    return (
                      <Activity
                        key={index}
                        className={
                          classes[activityClassName(activity.category ? activity.category.substring(0, 2) : undefined, !!activity.avoidable)]
                        }
                      >
                        <TimeInfo>
                          <span>{format(activity.begin.toDate(), 'HH:mm:ss')}</span>
                        </TimeInfo>
                        <TimeInfo>
                          <span>{format(end.toDate(), 'HH:mm:ss')}</span>
                        </TimeInfo>
                        <Duration>{timeDifference(activity.begin.toDate(), end.toDate())}</Duration>
                        <Grid container direction="column" justify="center">
                          <Category>{activity.category && t(`options.category.${activity.category}`)}</Category>
                          <Subcategory>{activity.subcategory && t(`options.subcategory.${activity.subcategory}`)}</Subcategory>
                          {activity.remark && commentVisible[activity.id] && <Remark>{activity.remark}</Remark>}
                          {thumbnailsVisible[activity.id] && (
                            <ThumbnailsWrapper>
                              <Thumbnails>
                                {fetchedThumbnails[activity.id] ? (
                                  fetchedThumbnails[activity.id].map(thumbnail => (
                                    <img src={thumbnail.data} alt="" onClick={showPic(measurement.id, activity.id, thumbnail.id)} />
                                  ))
                                ) : (
                                  <Fade
                                    in={loadingThumbnails[activity.id]}
                                    style={{
                                      transitionDelay: loadingThumbnails[activity.id] ? '800ms' : '0ms',
                                    }}
                                    unmountOnExit
                                  >
                                    <CircularProgress size={20} />
                                  </Fade>
                                )}
                              </Thumbnails>
                            </ThumbnailsWrapper>
                          )}
                        </Grid>
                        <PicInfo>
                          {activity.picCount > 0 && (
                            <>
                              {activity.picCount} <CameraAltIcon fontSize="small" onClick={() => toggleThumbnails(measurement.id, activity.id)} />
                            </>
                          )}
                          {activity.remark && (
                            <CommentIcon
                              fontSize="small"
                              onClick={() => showComment(oldValue => ({ ...oldValue, [activity.id]: !oldValue[activity.id] }))}
                            />
                          )}
                        </PicInfo>
                      </Activity>
                    )
                  })}
                </ActivitiesWrapper>
              </>
            ) : (
              <SpinnerWrapper>
                <CircularProgress />
              </SpinnerWrapper>
            )}
          </TabPanel>
        )
      })}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {loadingPic ? <CircularProgress size={20} /> : <img src={fetchedPics[picToPreview]} alt="" />}
      </Popover>
    </Container>
  )
}

export default Details
