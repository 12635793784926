import React from 'react'
import { Redirect, Route } from 'react-router-dom'

import { useAuth } from '../hooks/useAuth'

const PrivateRoute = ({ children, ...props }) => {
  const { user, loading } = useAuth()
  return (
    <Route
      {...props}
      render={({ location }) => {
        if (!user && !loading) {
          return (
            <Redirect
              to={{
                pathname: '/',
                state: { from: location },
              }}
            />
          )
        }
        return user && !loading ? children : null
      }}
    />
  )
}

export default PrivateRoute
