import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { keys } from 'ramda'

import { makeStyles, useTheme } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import CloseIcon from '@material-ui/icons/Close'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input'
import Chip from '@material-ui/core/Chip'
import MenuItem from '@material-ui/core/MenuItem'
import { primaryColor } from '../../theme'
import Checkbox from '@material-ui/core/Checkbox'

import { firestore } from '../../firebase'

const useStyles = makeStyles(theme => ({
  table: {
    width: 'auto',
  },
  formControl: {
    width: '100%',
    display: 'block',
    margin: '12px 0',
  },
  chip: {
    border: `1px solid darkgrey`,
    borderRadius: '3px',
    background: 'white',
    height: '28px',
    marginRight: '4px',
  },
}))

const Title = styled(DialogTitle)`
  color: white !important;
  background-color: #7f9e5d;
  padding-right: 16px !important;
  h2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`
const EditUserDialog = styled(Dialog)`
  .MuiPaper-root {
    padding: 0;
  }
  .MuiCheckbox-root {
    margin-bottom: 4px;
    margin-left: -12px;
  }
  .MuiInputLabel-root {
    opacity: 0.6;
  }
`
const CloseModalIcon = styled(CloseIcon)`
  cursor: pointer;
`
const ActionsWrapper = styled.div`
  padding: 8px 15px;
`
const User = styled.span`
  color: black;
  opacity: 0.85;
`
const ROLES = ['user', 'admin', 'data_admin', 'read_only']

const trans = role => {
  switch (role) {
    case 'user':
      return 'customer'
    case 'admin':
      return 'admin'
    case 'data_admin':
      return 'data admin'
    case 'read_only':
      return 'read only'
    default:
      return null
  }
}

const EditUserModal = ({ onCancel, onSuccess, user, licenses }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [roles, setRoles] = useState(user.roles)
  const [licenseIds, setLicenseIds] = useState(user.licenseIds)
  const [active, setActive] = useState(user.active)

  const onOk = async () => {
    const licenseMap = {}
    licenseIds.forEach(id => {
      licenseMap[id] = licenses[id]
    })
    firestore.doc(`users/${user.id}`).update({
      active,
      roles,
      licenseIds,
      licenseMap,
    })

    onSuccess({ ...user, active, roles, licenseMap, licenseIds })
  }

  const handleRoleChange = event => {
    setRoles(event.target.value)
  }
  const handleLicenseChange = event => {
    setLicenseIds(event.target.value)
  }
  const handleCheckboxChange = (event, value) => {
    setActive(value)
  }

  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  }

  function getStyles(role, roles, theme) {
    return {
      fontWeight: roles.indexOf(role) === -1 ? theme.typography.fontWeightRegular : 'bold',
      color: roles.indexOf(role) === -1 ? 'black' : primaryColor,
    }
  }
  const theme = useTheme()

  return (
    <EditUserDialog open onClose={onCancel}>
      <Title>
        <span style={{ paddingRight: '1rem' }}>Edit user</span> <CloseModalIcon onClick={onCancel} />
      </Title>
      <DialogContent style={{ minWidth: '25rem', paddingTop: '20px' }}>
        <User>{user.id}</User>

        <FormControl className={classes.formControl}>
          <InputLabel id="rolesLabel">roles</InputLabel>
          <Select
            labelId="rolesLabel"
            id="roles"
            multiple
            value={roles}
            fullWidth
            onChange={handleRoleChange}
            input={<Input id="select-multiple-chip" />}
            renderValue={selected => (
              <div className={classes.chips}>
                {selected.map(value => (
                  <Chip key={value} label={trans(value)} className={classes.chip} />
                ))}
              </div>
            )}
            MenuProps={MenuProps}
          >
            {ROLES.map(role => (
              <MenuItem key={role} value={role} style={getStyles(role, roles, theme)}>
                {trans(role)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="licensesLabel">licenses</InputLabel>
          <Select
            labelId="licensesLabel"
            id="licenses"
            multiple
            fullWidth
            value={licenseIds}
            onChange={handleLicenseChange}
            input={<Input id="select-multiple-chip" />}
            renderValue={selected => (
              <div className={classes.chips}>
                {selected.map(value => (
                  <Chip key={value} label={licenses[value]} className={classes.chip} />
                ))}
              </div>
            )}
            MenuProps={MenuProps}
          >
            {keys(licenses).map(licenseId => (
              <MenuItem key={licenseId} value={licenseId} style={getStyles(licenseId, licenseIds, theme)}>
                {licenses[licenseId]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl className={classes.formControl}>
          <Checkbox name="active" checked={active} onChange={handleCheckboxChange} /> active
        </FormControl>
      </DialogContent>
      <ActionsWrapper>
        <DialogActions>
          <Button onClick={onCancel} color="primary">
            {t('common.CANCEL')}
          </Button>
          <Button onClick={onOk} variant="contained" color="secondary">
            {t('common.SAVE')}
          </Button>
        </DialogActions>
      </ActionsWrapper>
    </EditUserDialog>
  )
}

export default EditUserModal
