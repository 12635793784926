import React from 'react'
import { useField, useFormikContext } from 'formik'
import FormControl from '@material-ui/core/FormControl'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import FormHelperText from '@material-ui/core/FormHelperText'

const DatePickerField = ({ label, wrapper: Wrapper = FormControl, ...props }) => {
  const [field, meta] = useField(props)
  const { setFieldValue } = useFormikContext()
  const error = meta.touched && meta.error
  return (
    <Wrapper error={error}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          disableToolbar
          variant="inline"
          margin="normal"
          autoOk
          label={label}
          {...field}
          {...props}
          onChange={value => setFieldValue(props.name, value)}
        />
      </MuiPickersUtilsProvider>
      {error && <FormHelperText>{meta.error}</FormHelperText>}
    </Wrapper>
  )
}

export default DatePickerField
