import React from 'react'
import Chip from '@material-ui/core/Chip'
import PersonIcon from '@material-ui/icons/Person'
import RoomIcon from '@material-ui/icons/Room'
import TimerIcon from '@material-ui/icons/Timer'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  chip: {
    borderRadius: '2px',
    padding: '2px',
    height: 'auto',
    borderColor: '#f0f0f0',
    borderWidth: '2px',
    marginRight: 0,
    '&+div': {
      marginLeft: '.5rem',
    },
  },
  chipIcon: {
    height: '16px !important',
  },
}))

const ProjectChips = ({ project }) => {
  const classes = useStyles()
  if (!project) {
    return null
  }
  return (
    <div>
      <Chip
        label={project.client}
        className={classes.chip}
        variant="outlined"
        avatar={<PersonIcon fontSize="small" className={classes.chipIcon} />}
      />
      <Chip
        label={project.location}
        className={classes.chip}
        variant="outlined"
        avatar={<RoomIcon fontSize="small" className={classes.chipIcon} />}
      />
      <Chip
        label={project.cloudMeasurementCount || 0}
        className={classes.chip}
        variant="outlined"
        avatar={<TimerIcon fontSize="small" className={classes.chipIcon} style={{ marginRight: '-3px' }} />}
      />
    </div>
  )
}

export default ProjectChips
