import React, { forwardRef, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { clone, findIndex, values } from 'ramda'
import { format } from 'date-fns'
import Container from '@material-ui/core/Container'

import NavBar from '../../components/NavBar'
import { firebaseAuth, firestore } from '../../firebase'
import MaterialTable from 'material-table'
import { saveAs } from 'file-saver'

import BlockIcon from '@material-ui/icons/Block'

import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import GroupIcon from '@material-ui/icons/Group'
import PersonIcon from '@material-ui/icons/Person'
import EditUserModal from './EditUserModal'
import CreateUserModal from './CreateUserModal'
import { primaryColor } from '../../theme'

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}

const Wrapper = styled.div`
  .MuiTypography-h6 {
    color: ${primaryColor};
    font-size: 1.5rem;
  }
`
const Users = () => {
  const [user, setUser] = useState({})
  const [showingEditModal, showEditModal] = useState(false)
  const [showingCreateModal, showCreateModal] = useState(false)
  const [licenses, setLicenses] = useState({})
  const [roles, setRoles] = useState([])
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged(user => {
      if (user) {
        firestore
          .doc(`users/${user.email.toLowerCase()}`)
          .get()
          .then(doc => {
            if (doc.exists) {
              const { roles } = doc.data()
              setRoles(roles || [])

              if (roles.includes('admin')) {
                const usersRef = firestore.collection(`users`)
                usersRef.get().then(snapshot => {
                  setUsers(snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })))
                  setLoading(false)
                })
                const licensesRef = firestore.collection(`data`)
                licensesRef.get().then(snapshot => {
                  const licenses = {}
                  snapshot.docs.forEach(doc => (licenses[doc.id] = doc.data().name))
                  setLicenses(licenses)
                })
              }
            }
          })
      }
    })

    return () => unsubscribe()
  }, [])

  const onEditSuccess = user => {
    showEditModal(false)
    const clones = clone(users)
    const index = findIndex(clone => clone.id === user.id, clones)
    clones[index] = user
    setUsers(clones)
  }

  const onCreateSuccess = user => {
    showCreateModal(false)
    setUsers(prevValue => [user, ...prevValue])
    // const clones = clone(users)
    // const index = findIndex(clone => clone.id === user.id, clones)
    // clones[index] = user
    // setUsers(clones)
  }

  const columns = [
    {
      title: 'role',
      field: 'roles',
      render: rowData =>
        !rowData.active ? (
          <BlockIcon fontSize="small" />
        ) : rowData.roles.includes('admin') ? (
          <GroupIcon fontSize="small" />
        ) : (
          <PersonIcon fontSize="small" />
        ),
      sorting: false,
      cellStyle: {
        padding: 0,
        paddingTop: '4px',
      },
    },
    { title: 'email', field: 'id' },
    {
      title: 'created',
      field: 'roles',
      render: rowData => format(rowData.created.toDate(), 'dd MMM yyyy'),
      customSort: (a, b) => a.created.seconds - b.created.seconds,
    },
    {
      title: 'licenses',
      field: 'licenseIds',
      render: rowData => (
        <>
          {values(rowData.licenseMap).map(license => (
            <div key={license}>{license}</div>
          ))}
        </>
      ),
      customSort: (a, b) => a.licenseIds.length - b.licenseIds.length,
    },
  ]

  const downloadUsers = () => {
    const data = users.map(
      user => user.roles.join('/') + ';' + user.id + ';' + format(user.created.toDate(), 'dd MMM yyyy') + ';' + values(user.licenseMap).join('/'),
    )
    var blob = new Blob(['role;email;created;licenses\n' + data.join('\n')], { type: 'text/csv;charset=utf-8' })

    saveAs(blob, 'wcm-users.csv')
  }

  return (
    <Wrapper>
      <NavBar />
      {roles.includes('admin') && (
        <Container maxWidth="md" style={{ marginTop: '2rem' }}>
          <MaterialTable
            title="User manager"
            icons={tableIcons}
            columns={columns}
            data={users}
            isLoading={loading}
            options={{
              search: true,
              pageSize: 10,
              pageSizeOptions: [10, 20, 50],
              padding: 'dense',
              headerStyle: {
                padding: '15px 0 0',
                color: 'black',
              },
              rowStyle: {
                borderBottom: '1px solid #eee',
              },
              cellStyle: {
                padding: '12px 0',
              },
            }}
            actions={[
              {
                icon: () => <Edit fontSize="small" />,
                tooltip: 'Update user',
                onClick: (event, rowData) => {
                  setUser(rowData)
                  showEditModal(true)
                },
              },
              {
                icon: () => <tableIcons.Add />,
                tooltip: 'Add User',
                isFreeAction: true,
                onClick: () => showCreateModal(true),
              },
              {
                icon: () => <tableIcons.Export />,
                tooltip: 'Download users',
                isFreeAction: true,
                onClick: () => downloadUsers(),
              },
            ]}
            localization={{
              header: {
                actions: '',
              },
              body: {
                emptyDataSourceMessage: loading ? '' : 'No users found',
              },
            }}
          />
        </Container>
      )}
      {showingEditModal && user && <EditUserModal user={user} onCancel={() => showEditModal(false)} licenses={licenses} onSuccess={onEditSuccess} />}
      {showingCreateModal && user && (
        <CreateUserModal
          onCancel={() => showCreateModal(false)}
          licenses={licenses}
          onSuccess={onCreateSuccess}
          userIds={users.map(user => user.id)}
        />
      )}
    </Wrapper>
  )
}

export default Users
