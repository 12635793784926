export const CONTRACT_TYPES = ['unit', 'hourly', 'fixed_price']
export const DISCIPLINES = ['mechanical', 'ei', 'scaffolding', 'cleaning', 'pb', 'insulation', 'piping', 'civil', 'painting', 'other']
export const DEPARTMENTS = ['maintenance', 'production', 'other']
export const RESOURCES = ['own', 'internal', 'external', 'service']
export const SECTORS = [
  'chemical',
  'oil_gas',
  'power',
  'heavy_industry',
  'manufacturing',
  'food_beverage',
  'automotive',
  'pharma',
  'public_transport',
  'logistics',
  'other',
]
export const SHIFTS = ['day', 'shift']
export const WORKSHOPS = ['centralized', 'decentralized']
export const WORK_TYPES = ['regular_planned', 'regular_unplanned', 'project_tar', 'project']

export default t => ({
  sector: SECTORS.map(option => ({ value: option, label: t(`options.sector.${option}`) })),
  discipline: DISCIPLINES.map(option => ({
    value: option,
    label: t(`options.discipline.${option}`),
  })),
  department: DEPARTMENTS.map(option => ({
    value: option,
    label: t(`options.department.${option}`),
  })),
  contractType: CONTRACT_TYPES.map(option => ({
    value: option,
    label: t(`options.contractType.${option}`),
  })),
  resource: RESOURCES.map(option => ({
    value: option,
    label: t(`options.resource.${option}`),
  })),
  workType: WORK_TYPES.map(option => ({
    value: option,
    label: t(`options.workType.${option}`),
  })),
  shift: SHIFTS.map(option => ({
    value: option,
    label: t(`options.shift.${option}`),
  })),
  workshop: WORKSHOPS.map(option => ({
    value: option,
    label: t(`options.workshop.${option}`),
  })),
})
