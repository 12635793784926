import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import Container from '@material-ui/core/Container'
import CircularProgress from '@material-ui/core/CircularProgress'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { useTranslation } from 'react-i18next'
import { dissoc, keys, remove, propOr, sort, sortBy, sum, values, reject, findIndex, clone, isNil, omit, without } from 'ramda'
import firebase from 'firebase/app'

import { firebaseAuth, firestore } from '../../firebase'
import NavBar from '../../components/NavBar'
import { primaryColor } from '../../theme'
import TabPanel from '../pageParts/TabPanel'
import { formatDate, generateStats, timeDifference } from '../../utils/misc'
import Chip from '@material-ui/core/Chip'
import { differenceInSeconds, format } from 'date-fns'
import Grid from '@material-ui/core/Grid'
import CommentIcon from '@material-ui/icons/Comment'
import { activityClassName, MEASUREMENT_FIELDS } from '../Measurements/Details'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { makeStyles } from '@material-ui/core'
import SplitActivityModal from './SplitActivityModal'
import EditIcon from '@material-ui/icons/Edit'
import VerticalAlignCenterIcon from '@material-ui/icons/VerticalAlignCenter'
import DeleteIcon from '@material-ui/icons/Delete'
import EditActivity from './EditActivity'
import Button from '@material-ui/core/Button'
import RejectConfirmationModal from './RejectConfirmationModal'

const { Timestamp, FieldValue } = firebase.firestore

const SplitIcon = styled(VerticalAlignCenterIcon)`
  transform: rotate(90deg);
`
const Wrapper = styled.div`
  h1 {
    margin-top: 2rem;
    color: ${primaryColor};
    font-size: 1.5rem;
  }
`

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
`

const Summary = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 1rem;
  a {
    color: inherit;
  }
`
const ActivitiesWrapper = styled.div``

const Activity = styled(Paper)`
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
`
const TimeInfo = styled.div`
  display: flex;
  justify-content: center;
  width: 10rem;
`
const Duration = styled.div`
  padding-right: 3rem;
  width: 8rem;
  text-align: right;
`
const NoData = styled.div`
  display: flex;
  padding: 1rem 0;
  font-size: 1.2rem;
`
const Category = styled.span``
const Subcategory = styled.span``
const PicInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  svg {
    margin-left: 7px;
    cursor: pointer;
  }
  width: 6rem;
`
const Remark = styled.span`
  font-size: 0.8rem;
  margin-bottom: 7px;
`
const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3rem;
`
const MeasurementMeta = styled.div`
  text-align: center;
  margin: 0.5rem 2rem 1.5rem;
`
const HohoWrapper = styled.div`
  display: flex;
`
const BenchWrapper = styled.div`
  margin-left: 10px;
`
const Actions = styled.div`
  margin: 10px 0;
  display: flex;
  justify-content: space-around;
`

const useStyles = makeStyles(theme => ({
  dpAvoidable: {
    borderLeft: '7px solid #72C042',
  },
  dpUnavoidable: {
    borderLeft: '7px solid green',
  },
  ipAvoidable: {
    borderLeft: '7px solid #9DDCFF',
  },
  ipUnavoidable: {
    borderLeft: '7px solid blue',
  },
  npAvoidable: {
    borderLeft: '7px solid #FBA4A3',
  },
  npUnavoidable: {
    borderLeft: '7px solid red',
  },
  pz: {
    borderLeft: '7px solid black',
  },
  na: {
    borderLeft: '7px solid grey',
  },
  selected: {
    backgroundColor: 'gainsboro',
    border: '3px solid dimgray',
  },
  iconButton: {
    borderRadius: '50%',
    padding: '8px',
  },
}))

const Quarantine = () => {
  const [ranges, setRanges] = useState({})
  const [loading, setLoading] = useState(true)
  const [measurements, setMeasurements] = useState({})
  const [quarantineEntries, setQuarantineEntries] = useState([])
  const [tabIndex, setTabIndex] = useState(0)
  const [stats, setStats] = useState({})
  const [isSplitModalVisible, showSplitModal] = useState(false)
  const [isEditModalVisible, showEditModal] = useState(false)
  const [isRejectModalVisible, showRejectModal] = useState(false)
  const { t, i18n } = useTranslation()
  const [summaries, setSummaries] = useState({})
  const classes = useStyles()
  const [previousActivity, setPreviousActivity] = useState(null)
  const [nextActivity, setNextActivity] = useState(null)
  const [targetActivity, setTargetActivity] = useState(null)

  const [selectedDuration, setSelectedDuration] = useState(0)

  const { licenseId, projectId, measurementId } = quarantineEntries.length > 0 ? quarantineEntries[tabIndex] : {}

  useEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged(user => {
      if (user) {
        firestore
          .doc(`users/${user.email.toLowerCase()}`)
          .get()
          .then(doc => {
            if (doc.exists) {
              const { roles } = doc.data()

              if (roles.includes('admin')) {
                const quarantineRef = firestore.collection(`quarantine/new/measurements`)
                quarantineRef.get().then(snapshot => {
                  setQuarantineEntries(snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })))
                  setLoading(false)
                })
                const rangesRef = firestore.doc(`benchmark/ranges`)
                rangesRef.get().then(doc => {
                  setRanges(doc.data())
                })
              } else {
                setLoading(false)
              }
            }
          })
      }
    })

    return () => unsubscribe()
  }, [])

  useEffect(() => {
    console.log('boom')
    if (tabIndex < quarantineEntries.length) {
      const { licenseId, projectId, measurementId } = quarantineEntries[tabIndex]
      const fetchMeasurement = async () => {
        const doc = await firestore.doc(`data/${licenseId}/projects/${projectId}/measurements/${measurementId}`).get()
        const measurement = doc.data()
        setMeasurements(prevState => ({ ...prevState, [doc.id]: { ...measurement, id: doc.id } }))
      }
      console.log('measurements[measurementId]', measurements[measurementId])
      if (!measurements[measurementId]) {
        fetchMeasurement()
      }
    }
  }, [tabIndex, quarantineEntries, measurements])

  useEffect(() => {
    if (tabIndex < quarantineEntries.length) {
      const { measurementId } = quarantineEntries[tabIndex]
      const measurement = measurements[measurementId]
      if (measurement) {
        const timeStats = measurement.stats.level2.prod.time
        // const timeStats = dissoc('pz', measurement.stats.level2.prod.time)
        const total = sum(values(timeStats))
        const stats = keys(timeStats).reduce((acc, curr) => {
          acc[curr] = Math.round((timeStats[curr] * 100) / total)
          return acc
        }, {})
        setStats(stats)
      }
    }
  }, [tabIndex, measurements, quarantineEntries])

  useEffect(() => {
    if (tabIndex < quarantineEntries.length) {
      const { licenseId, projectId, measurementId } = quarantineEntries[tabIndex]
      const measurement = measurements[measurementId]
      if (measurement && !summaries[measurement.id]) {
        const summaryRef = firestore.doc(`data/${licenseId}/projects/${projectId}/measurements/${measurement.id}/summary/activities`)
        summaryRef.get().then(doc => {
          if (doc.exists) {
            const activities = sortBy(a => a.begin.seconds, doc.data().summary)
            clone(activities).forEach((a, index) => {
              const end = index === activities.length - 1 ? measurement.end : activities[index + 1].begin
              activities[index].end = end
            })

            setSummaries({ ...summaries, [measurement.id]: activities })
          }
        })
      }
    }
  }, [tabIndex, measurements, summaries, quarantineEntries])

  const switchTab = (event, newValue) => {
    setTabIndex(newValue)
  }

  const onSplitActivity = async (seconds, currentFirst) => {
    const splitTimestamp = new Timestamp(targetActivity.begin.seconds + seconds, 0)
    const { licenseId, projectId, measurementId } = quarantineEntries[tabIndex]

    const measurementRef = firestore.doc(`data/${licenseId}/projects/${projectId}/measurements/${measurementId}`)
    const summaryRef = firestore.doc(`data/${licenseId}/projects/${projectId}/measurements/${measurementId}/summary/activities`)
    const summaryDoc = await summaryRef.get()
    const { summary } = summaryDoc.data()

    const newActivity = {
      begin: splitTimestamp,
    }
    const act = await measurementRef.collection('activities').add(newActivity)
    newActivity.id = act.id
    const newSummary = sortBy(a => a.begin.seconds, [...summary, newActivity])
    setSummaries({ ...summaries, [measurementId]: newSummary })
    const measurement = measurements[measurementId]

    const newStats = generateStats(newSummary, measurement)
    setMeasurements(prevState => ({ ...prevState, [measurementId]: { ...measurements[measurementId], stats: newStats } }))
    summaryRef.set({ summary: newSummary })
    measurementRef.update({ stats: newStats })
    setTargetActivity(null)
    showSplitModal(false)
  }

  const onDelete = async activity => {
    const { licenseId, projectId, measurementId } = quarantineEntries[tabIndex]

    const measurementRef = firestore.doc(`data/${licenseId}/projects/${projectId}/measurements/${measurementId}`)
    const summaryRef = firestore.doc(`data/${licenseId}/projects/${projectId}/measurements/${measurementId}/summary/activities`)
    const summaryDoc = await summaryRef.get()
    const { summary } = summaryDoc.data()
    const measurement = measurements[measurementId]

    const newSummary = sortBy(
      a => a.begin.seconds,
      reject(a => a.id === activity.id, summary),
    )
    setSummaries({ ...summaries, [measurementId]: newSummary })
    const newStats = generateStats(newSummary, measurement)
    setMeasurements(prevState => ({ ...prevState, [measurementId]: { ...measurements[measurementId], stats: newStats } }))
    summaryRef.set({ summary: newSummary })
    measurementRef.update({ stats: newStats })
  }

  const onClickSplit = (activity, end) => {
    setTargetActivity(activity)
    const beginSeconds = activity.begin.seconds
    const endSeconds = end.seconds
    setSelectedDuration(Math.max(endSeconds - beginSeconds, 0))

    showSplitModal(true)
  }

  const cleanActivity = activity => {
    let result = { ...activity }
    if (isNil(activity.category) || activity.category === 'pz1') {
      result = omit(['avoidable'], result)
    } else {
      result.avoidable = !!activity.avoidable
    }
    if (activity.subcategory === null) {
      result = omit(['subcategory'], result)
    }
    return omit(['id', 'end', 'picCount'], result)
  }

  const onClickEdit = (activity, end) => {
    setTargetActivity(activity)
    const sorted = sortBy(a => a.begin.seconds, summaries[measurementId])
    const index = findIndex(a => a.id === activity.id, sorted)
    const previous = index > 0 ? sorted[index - 1] : null
    const next = index < sorted.length - 1 ? sorted[index + 1] : null
    setNextActivity(next)
    setPreviousActivity(previous)

    showEditModal(true)
  }

  const onEditActivity = activity => {
    const batch = firestore.batch()

    const measurementRef = firestore.doc(`data/${licenseId}/projects/${projectId}/measurements/${measurementId}`)
    const summaryRef = firestore.doc(`data/${licenseId}/projects/${projectId}/measurements/${measurementId}/summary/activities`)

    const activityRef = measurementRef.collection('activities').doc(activity.id)

    const fields = ['begin', 'category', 'subcategory', 'remark', 'avoidable']
    const activityValues = cleanActivity(activity)
    fields.forEach(field => {
      batch.update(activityRef, { [field]: activityValues[field] || FieldValue.delete() })
    })
    let newSummary = sortBy(a => a.begin.seconds, [...reject(s => s.id === activity.id, summaries[measurementId]), activity])

    const didUpdateEndTime = activity.end && targetActivity.end && activity.end.seconds !== targetActivity.end.seconds
    if (didUpdateEndTime) {
      if (nextActivity) {
        const newNextActivity = clone(nextActivity)
        newNextActivity.begin = activity.end
        newSummary = sortBy(a => a.begin.seconds, [...reject(s => s.id === nextActivity.id, newSummary), newNextActivity])
        // console.log('JJAJAJAJAJJAJAJA', newSummaries)

        const nextActivityRef = measurementRef.collection('activities').doc(nextActivity.id)
        batch.update(nextActivityRef, { begin: new Timestamp(activity.end.seconds, 0) })
      } else {
        batch.update(measurementRef, { end: new Timestamp(activity.end.seconds, 0) })
      }
    }

    setSummaries(prevState => ({ ...prevState, [measurementId]: newSummary }))
    batch.set(summaryRef, { summary: newSummary })

    const measurement = measurements[measurementId]
    const newStats = generateStats(newSummary, measurement)
    setMeasurements(prevState => ({ ...prevState, [measurementId]: { ...measurements[measurementId], stats: newStats } }))
    batch.update(measurementRef, { stats: newStats })

    batch.commit()
    showEditModal(false)
  }

  const onReject = async reason => {
    showRejectModal(false)
    const quarantineEntry = quarantineEntries[tabIndex]
    // const measurementRef = firestore.doc(`data/${licenseId}/projects/${projectId}/measurements/${measurementId}`)
    const quarantineRef = firestore.doc(`quarantine/new/measurements/${quarantineEntry.id}`)
    const declinedRef = firestore.collection(`quarantine/declined/measurements`).doc()
    const batch = firestore.batch()
    batch.set(declinedRef, { ...dissoc('id', quarantineEntry), reason })
    batch.delete(quarantineRef)
    await batch.commit()
    setMeasurements(prevState => ({ ...dissoc(quarantineEntry.measurementId, prevState) }))
    setQuarantineEntries(prevState => remove(tabIndex, 1, prevState))
  }

  const categories = ['dp1', 'ip2', 'ip3', 'ip4', 'ip5', 'ip6', 'ip7', 'ip8', 'ip9', 'ip10', 'ip11', 'ip12', 'ip13', 'np14', 'np15', 'np16']

  const accept = async () => {
    const quarantineEntry = quarantineEntries[tabIndex]

    // const lic = '-KYBShq-0fA2j3cwKwqX' // stork
    // // const lic = '-KYBShq0A-bFlsJxwAep'
    //
    // // const projects = await firestore.collection(`data/${lic}/projects`).get()
    // // projects.forEach(project => {
    // //   if (project.data().cloudMeasurementCount > 0) {
    // //     // console.log('project', project.id, project.data())
    // //     // console.log('YES')
    // //     firestore
    // //       .collection(`data/${lic}/projects/${project.id}/measurements`)
    // //       .get()
    // //       .then(measurements => {
    // //         measurements.forEach(measurement => {
    // //           const measurementData = measurement.data()
    // //           if (measurementData.year >= 2020 && measurementData.upload) {
    // //             const entries = Object.keys(measurementData.stats.level2.prod.count)
    // //               .filter(catName => categories.includes(catName))
    // //               .map(catName => ({
    // //                 category: catName,
    // //                 count: measurementData.stats.level2.prod.count[catName],
    // //                 time: measurementData.stats.level2.prod.time[catName],
    // //                 year: measurementData.year,
    // //                 contractType: measurementData.contractType,
    // //                 discipline: measurementData.discipline,
    // //                 department: measurementData.department,
    // //                 resource: measurementData.resource,
    // //                 sector: measurementData.sector,
    // //                 shift: measurementData.shift,
    // //                 workshop: measurementData.workshop,
    // //                 workType: measurementData.workType,
    // //               }))
    // //             // console.log('ENTRIES', entries)
    // //
    // //             // console.log('TOO SHORT? ', isTooShort(measurementData.stats.level2.prod.time))
    // //             //
    // //             // console.log('OUT OF BOUNDS? ', isOutOfBounds(measurementData.stats.level2.prod.time))
    // //
    // //             const longEnough = !isTooShort(measurementData.stats.level2.prod.time)
    // //             const insideBounds = !isOutOfBounds(measurementData.stats.level2.prod.time)
    // //             if (longEnough && insideBounds) {
    // //               try {
    // //                 fetch('http://localhost:3000/api/new-entry', { method: 'POST', body: JSON.stringify(entries) }).then(res => {
    // //                   console.log('res', res)
    // //                 })
    // //               } catch (e) {
    // //                 console.log(e)
    // //               }
    // //             }
    // //           }
    // //         })
    // //       })
    // //   }
    // // })

    const doc = await firestore
      .doc(`data/${quarantineEntry.licenseId}/projects/${quarantineEntry.projectId}/measurements/${quarantineEntry.measurementId}`)
      .get()
    const measurementData = doc.data()
    const entries = Object.keys(measurementData.stats.level2.prod.count)
      .filter(catName => categories.includes(catName))
      .map(catName => ({
        category: catName,
        count: measurementData.stats.level2.prod.count[catName],
        time: measurementData.stats.level2.prod.time[catName],
        year: measurementData.year,
        contractType: measurementData.contractType,
        discipline: measurementData.discipline,
        department: measurementData.department,
        resource: measurementData.resource,
        sector: measurementData.sector,
        shift: measurementData.shift,
        workshop: measurementData.workshop,
        workType: measurementData.workType,
      }))
    // console.log('ENTRIES', entries)

    try {
      fetch('https://hott-benchmark.vercel.app/api/new-entry', { method: 'POST', body: JSON.stringify(entries) }).then(res => {
        console.log('saved')
      })
    } catch (e) {
      console.log(e)
    }

    const quarantineRef = firestore.doc(`quarantine/new/measurements/${quarantineEntry.id}`)
    const batch = firestore.batch()
    batch.delete(quarantineRef)
    await batch.commit()
    setMeasurements(prevState => ({ ...dissoc(quarantineEntry.measurementId, prevState) }))
    setQuarantineEntries(prevState => remove(tabIndex, 1, prevState))
  }

  return (
    <Wrapper>
      <NavBar />

      <Container>
        <h1>{t('quarantine.title')}</h1>

        {loading ? (
          <LoadingContainer>
            <CircularProgress size={40} />
          </LoadingContainer>
        ) : quarantineEntries.length === 0 ? (
          <NoData>{t('quarantine.noData')}</NoData>
        ) : (
          <>
            <Tabs
              value={tabIndex}
              onChange={switchTab}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              style={{
                backgroundColor: '#FAFAFA',
                paddingBottom: '5px',
              }}
            >
              {quarantineEntries.map(entry => (
                <Tab key={entry.id} label={entry.name} />
              ))}
            </Tabs>
            {quarantineEntries.map((qEntry, index) => {
              const measurement = Object.values(measurements).find(m => m.id === qEntry.measurementId)
              if (!measurement) {
                return (
                  <TabPanel value={tabIndex} index={index} key={index}>
                    <SpinnerWrapper>
                      <CircularProgress />
                    </SpinnerWrapper>
                  </TabPanel>
                )
              } else {
                const summary = summaries[measurement.id]
                const activities = sortBy(a => a.begin.seconds, summary || [])
                const seconds = Object.values(measurement.stats.level2.prod.time).reduce((acc, value) => acc + value, 0)
                const hours = Math.floor(seconds / 3600)
                const minutes = Math.floor((seconds - hours * 3600) / 60)
                return (
                  <TabPanel value={tabIndex} index={index} key={measurement.id}>
                    {summary ? (
                      <>
                        <Summary>
                          <span>{formatDate(measurement.created.toDate(), 'MMM dd, yyyy', i18n.language)}</span>
                          <span>
                            {activities.length} {t('charts.activities')}
                          </span>
                          {activities.length > 0 && (
                            <span style={hours < 4 ? { color: 'red', backgroundColor: 'yellow', padding: '0 10px' } : {}}>
                              {hours}h {minutes}m
                            </span>
                          )}
                          <a href={`mailto:${measurement.user}`} target="_blank" rel="noopener noreferrer">
                            {measurement.user}
                          </a>
                        </Summary>

                        <MeasurementMeta>
                          {MEASUREMENT_FIELDS.map((field, index) => (
                            <Chip variant="outlined" label={t(`options.${field}.${measurement[field]}`)} key={`${measurement.id}_${index}`} />
                          ))}
                        </MeasurementMeta>

                        <HohoWrapper>
                          <BenchWrapper>
                            <Actions>
                              <Button variant="contained" onClick={accept} style={{ color: 'green' }}>
                                {t('quarantine.accept')}
                              </Button>
                              <Button variant="contained" onClick={() => showRejectModal(true)} style={{ color: 'red' }}>
                                {t('quarantine.decline')}
                              </Button>
                            </Actions>
                            <TableContainer component={Paper}>
                              <Table className={classes.table} size="small">
                                <TableHead>
                                  <TableRow>
                                    <TableCell>category</TableCell>
                                    <TableCell align="right">%</TableCell>
                                    <TableCell align="right">min</TableCell>
                                    <TableCell align="right">max</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {keys(ranges)
                                    .filter(cat => cat !== 'pz')
                                    .map(cat => {
                                      const value = propOr(0, cat, stats)
                                      const min = ranges[cat][0]
                                      const max = ranges[cat][1]
                                      return (
                                        <TableRow key={cat}>
                                          <TableCell component="th" scope="row">
                                            {t(`options.category.${cat}`).toLowerCase()}
                                          </TableCell>
                                          <TableCell
                                            align="right"
                                            style={value < min || value > max ? { color: 'red', backgroundColor: 'yellow' } : {}}
                                          >
                                            {value}
                                          </TableCell>
                                          <TableCell align="right">{min}</TableCell>
                                          <TableCell align="right">{max}</TableCell>
                                        </TableRow>
                                      )
                                    })}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </BenchWrapper>
                          <ActivitiesWrapper>
                            {activities.map((activity, index) => {
                              const end = index === activities.length - 1 ? measurement.end : activities[index + 1].begin
                              return (
                                <Activity
                                  key={index}
                                  className={
                                    classes[
                                      activityClassName(activity.category ? activity.category.substring(0, 2) : undefined, !!activity.avoidable)
                                    ]
                                  }
                                >
                                  <TimeInfo>
                                    <span>{format(activity.begin.toDate(), 'HH:mm:ss')}</span>
                                  </TimeInfo>
                                  <TimeInfo>
                                    <span>{format(end.toDate(), 'HH:mm:ss')}</span>
                                  </TimeInfo>
                                  <Duration>{timeDifference(activity.begin.toDate(), end.toDate())}</Duration>
                                  <Grid container direction="column" justify="center">
                                    <Category>{activity.category && t(`options.category.${activity.category}`)}</Category>
                                    <Subcategory>{activity.subcategory && t(`options.subcategory.${activity.subcategory}`)}</Subcategory>
                                    {activity.remark && <Remark>{activity.remark}</Remark>}
                                  </Grid>
                                  <PicInfo>
                                    <EditIcon fontSize="small" onClick={() => onClickEdit(activity, end)} />
                                    <SplitIcon fontSize="small" onClick={() => onClickSplit(activity, end)} />
                                    <DeleteIcon fontSize="small" onClick={() => onDelete(activity, end)} />
                                  </PicInfo>
                                </Activity>
                              )
                            })}
                          </ActivitiesWrapper>
                        </HohoWrapper>
                      </>
                    ) : (
                      <SpinnerWrapper>
                        <CircularProgress />
                      </SpinnerWrapper>
                    )}
                  </TabPanel>
                )
              }
            })}
            {/*{values(measurements)*/}
            {/*  .filter(m => quarantineEntries.find(e => e.measurementId === m.id))*/}
            {/*  .map((measurement, index) => {*/}
            {/*    const summary = summaries[measurement.id]*/}
            {/*    console.log('summary', measurement, summary)*/}
            {/*    const activities = sortBy(a => a.begin.seconds, summary || [])*/}
            {/*    const seconds = Object.values(measurement.stats.level2.prod.time).reduce((acc, value) => acc + value, 0)*/}
            {/*    const hours = Math.floor(seconds / 3600)*/}
            {/*    const minutes = Math.floor((seconds - hours * 3600) / 60)*/}
            {/*    return (*/}
            {/*      <TabPanel value={tabIndex} index={index} key={measurement.id}>*/}
            {/*        <div>xxx</div>*/}
            {/*        {summary ? (*/}
            {/*          <>*/}
            {/*            <Summary>*/}
            {/*              <span>{formatDate(measurement.created.toDate(), 'MMM dd, yyyy', i18n.language)}</span>*/}
            {/*              <span>*/}
            {/*                {activities.length} {t('charts.activities')}*/}
            {/*              </span>*/}
            {/*              {activities.length > 0 && (*/}
            {/*                <span style={hours < 4 ? { color: 'red', backgroundColor: 'yellow', padding: '0 10px' } : {}}>*/}
            {/*                  {hours}h {minutes}m*/}
            {/*                </span>*/}
            {/*              )}*/}
            {/*              <a href={`mailto:${measurement.user}`} target="_blank" rel="noopener noreferrer">*/}
            {/*                {measurement.user}*/}
            {/*              </a>*/}
            {/*            </Summary>*/}

            {/*            <MeasurementMeta>*/}
            {/*              {MEASUREMENT_FIELDS.map((field, index) => (*/}
            {/*                <Chip variant="outlined" label={t(`options.${field}.${measurement[field]}`)} key={`${measurement.id}_${index}`} />*/}
            {/*              ))}*/}
            {/*            </MeasurementMeta>*/}

            {/*            <HohoWrapper>*/}
            {/*              <BenchWrapper>*/}
            {/*                <Actions>*/}
            {/*                  <Button variant="contained" onClick={accept} style={{ color: 'green' }}>*/}
            {/*                    {t('quarantine.accept')}*/}
            {/*                  </Button>*/}
            {/*                  <Button variant="contained" onClick={() => showRejectModal(true)} style={{ color: 'red' }}>*/}
            {/*                    {t('quarantine.decline')}*/}
            {/*                  </Button>*/}
            {/*                </Actions>*/}
            {/*                <TableContainer component={Paper}>*/}
            {/*                  <Table className={classes.table} size="small">*/}
            {/*                    <TableHead>*/}
            {/*                      <TableRow>*/}
            {/*                        <TableCell>category</TableCell>*/}
            {/*                        <TableCell align="right">%</TableCell>*/}
            {/*                        <TableCell align="right">min</TableCell>*/}
            {/*                        <TableCell align="right">max</TableCell>*/}
            {/*                      </TableRow>*/}
            {/*                    </TableHead>*/}
            {/*                    <TableBody>*/}
            {/*                      {keys(ranges)*/}
            {/*                        .filter(cat => cat !== 'pz')*/}
            {/*                        .map(cat => {*/}
            {/*                          const value = propOr(0, cat, stats)*/}
            {/*                          const min = ranges[cat][0]*/}
            {/*                          const max = ranges[cat][1]*/}
            {/*                          return (*/}
            {/*                            <TableRow key={cat}>*/}
            {/*                              <TableCell component="th" scope="row">*/}
            {/*                                {t(`options.category.${cat}`).toLowerCase()}*/}
            {/*                              </TableCell>*/}
            {/*                              <TableCell*/}
            {/*                                align="right"*/}
            {/*                                style={value < min || value > max ? { color: 'red', backgroundColor: 'yellow' } : {}}*/}
            {/*                              >*/}
            {/*                                {value}*/}
            {/*                              </TableCell>*/}
            {/*                              <TableCell align="right">{min}</TableCell>*/}
            {/*                              <TableCell align="right">{max}</TableCell>*/}
            {/*                            </TableRow>*/}
            {/*                          )*/}
            {/*                        })}*/}
            {/*                    </TableBody>*/}
            {/*                  </Table>*/}
            {/*                </TableContainer>*/}
            {/*              </BenchWrapper>*/}
            {/*              <ActivitiesWrapper>*/}
            {/*                {activities.map((activity, index) => {*/}
            {/*                  const end = index === activities.length - 1 ? measurement.end : activities[index + 1].begin*/}
            {/*                  return (*/}
            {/*                    <Activity*/}
            {/*                      key={index}*/}
            {/*                      className={*/}
            {/*                        classes[*/}
            {/*                          activityClassName(activity.category ? activity.category.substring(0, 2) : undefined, !!activity.avoidable)*/}
            {/*                        ]*/}
            {/*                      }*/}
            {/*                    >*/}
            {/*                      <TimeInfo>*/}
            {/*                        <span>{format(activity.begin.toDate(), 'HH:mm:ss')}</span>*/}
            {/*                      </TimeInfo>*/}
            {/*                      <TimeInfo>*/}
            {/*                        <span>{format(end.toDate(), 'HH:mm:ss')}</span>*/}
            {/*                      </TimeInfo>*/}
            {/*                      <Duration>{timeDifference(activity.begin.toDate(), end.toDate())}</Duration>*/}
            {/*                      <Grid container direction="column" justify="center">*/}
            {/*                        <Category>{activity.category && t(`options.category.${activity.category}`)}</Category>*/}
            {/*                        <Subcategory>{activity.subcategory && t(`options.subcategory.${activity.subcategory}`)}</Subcategory>*/}
            {/*                        {activity.remark && <Remark>{activity.remark}</Remark>}*/}
            {/*                      </Grid>*/}
            {/*                      <PicInfo>*/}
            {/*                        <EditIcon fontSize="small" onClick={() => onClickEdit(activity, end)} />*/}
            {/*                        <SplitIcon fontSize="small" onClick={() => onClickSplit(activity, end)} />*/}
            {/*                        <DeleteIcon fontSize="small" onClick={() => onDelete(activity, end)} />*/}
            {/*                      </PicInfo>*/}
            {/*                    </Activity>*/}
            {/*                  )*/}
            {/*                })}*/}
            {/*              </ActivitiesWrapper>*/}
            {/*            </HohoWrapper>*/}
            {/*          </>*/}
            {/*        ) : (*/}
            {/*          <SpinnerWrapper>*/}
            {/*            <CircularProgress />*/}
            {/*          </SpinnerWrapper>*/}
            {/*        )}*/}
            {/*      </TabPanel>*/}
            {/*    )*/}
            {/*  })}*/}
          </>
        )}
      </Container>
      {isSplitModalVisible && <SplitActivityModal seconds={selectedDuration} onSplit={onSplitActivity} onCancel={() => showSplitModal(false)} />}
      {isEditModalVisible && (
        <EditActivity
          activity={targetActivity}
          previousActivity={previousActivity}
          nextActivity={nextActivity}
          licenseId={licenseId}
          projectId={projectId}
          measurementId={measurementId}
          onOk={onEditActivity}
          onCancel={() => showEditModal(false)}
        />
      )}
      {isRejectModalVisible && <RejectConfirmationModal onCancel={() => showRejectModal(false)} onOk={onReject} />}
    </Wrapper>
  )
}

export default Quarantine
