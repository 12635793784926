import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { isNil, keys, prop, reject, reverse, sortBy } from 'ramda'

import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import { firestore } from '../../firebase'
import { makeStyles } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import NavBar from '../../components/NavBar'
import { useLicense } from '../../hooks/useLicense'
import Paper from '@material-ui/core/Paper'
import bolt from '../../img/bolt.svg'
import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'
import EqualizerIcon from '@material-ui/icons/Equalizer'
import IconButton from '@material-ui/core/IconButton'
import Link from '../../components/Link'
import ProjectChips from '../pageParts/ProjectChips'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import CircularProgress from '@material-ui/core/CircularProgress'
import Fade from '@material-ui/core/Fade'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'

import TimerIcon from '@material-ui/icons/Timer'
import EditIcon from '@material-ui/icons/Edit'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Button from '@material-ui/core/Button'

import { primaryColor } from '../../theme'
import { formatDate } from '../../utils/misc'
import { useAuth } from '../../hooks/useAuth'
import RenameMeasurementModal from './RenameMeasurementModal'

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: '12px 0',
  },
  menuItem: {
    backgroundColor: 'transparent !important',
  },
  icon: {
    height: '30px',
    width: '30px',
    marginLeft: '-3px',
    marginBottom: '-8px',
  },
  heading: {
    width: '100%',
    textAlign: 'right',
  },
  projectExpansion: {
    boxShadow: 'none',
    padding: 0,
    '&::before': {
      height: 0,
    },
  },
  archiveButton: {
    padding: '2px 8px',
  },
}))

const ProjectTitle = styled.h3`
  color: black;
  opacity: 0.87;
  font-size: 1.2rem;
  padding-left: 8px;
  font-weight: normal;
  margin: 5px 0 10px;
`
const ProjectHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const PanelDetails = styled(ExpansionPanelDetails)`
  flex-wrap: wrap;
  padding: 0 !important;
  span {
    font-size: 0.85rem;
  }
  svg {
    font-size: 1rem;
  }
`
const ChartsIcon = styled(EqualizerIcon)`
  background-color: ${primaryColor};
  color: white;
  border-radius: 3px;
  padding: 3px;
  margin-right: -3px;
`
const ProjectControlsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  width: 100%;
`
const ArchiveExpansionPanel = styled(ExpansionPanel)`
  width: 100%;
  box-shadow: none;
  padding: 0;
  background-color: transparent;
  &&:before {
    height: 0;
  }
`

const ProjectList = ({ licenseId, projects, onUpdateProject }) => {
  const { t, i18n } = useTranslation()
  const classes = useStyles()
  const [fetchedMeasurements, setFetchedMeasurements] = useState({})
  const [loadingMeasurements, setLoadingMeasurements] = useState({})
  const [expansionState, setExpansionState] = useState({})
  const [shouldDisplayRenameModal, showRenameModal] = useState({})

  const toggleAccordion = panel => (event, isExpanded) => {
    setExpansionState({ ...expansionState, [panel]: isExpanded })
  }

  const selectProject = projectId => {
    if (!fetchedMeasurements[projectId]) {
      setLoadingMeasurements(prevState => ({ ...prevState, [projectId]: true }))
      const measurementsRef = firestore.collection(`data/${licenseId}/projects/${projectId}/measurements`).where('upload', '==', true)
      measurementsRef.get().then(snapshot => {
        const sortedMeasurements = sortBy(
          prop('sortKey'),
          snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })),
        )
        setLoadingMeasurements(prevState => ({ ...prevState, [projectId]: false }))
        setFetchedMeasurements({ ...fetchedMeasurements, [projectId]: sortedMeasurements })
      })
    }

    return toggleAccordion('project' + projectId)
  }

  const archiveProject = async project => {
    const projectRef = firestore.doc(`data/${licenseId}/projects/${project.id}`)
    await projectRef.update({ archived: true })
    onUpdateProject({ ...project, archived: true })
  }

  const deArchiveProject = async project => {
    const projectRef = firestore.doc(`data/${licenseId}/projects/${project.id}`)
    await projectRef.update({ archived: false })
    onUpdateProject({ ...project, archived: false })
  }

  const deleteProject = async project => {
    const projectRef = firestore.doc(`data/${licenseId}/projects/${project.id}`)
    try {
      await projectRef.update({ active: false })
      onUpdateProject({ ...project, active: false })
    } catch (e) {
      console.log('error', e)
    }
  }
  const onSuccessRename = (projectId, measurement) => {
    setFetchedMeasurements(oldState => ({ ...oldState, [projectId]: [...reject(m => m.id === measurement.id, oldState[projectId]), measurement] }))
    showRenameModal(oldState => ({ ...oldState, [measurement.id]: false }))
  }

  return (
    <>
      {projects
        .filter(project => project.cloudMeasurementCount > 0)
        .map(project => (
          <Paper key={project.id} style={{ width: '100%' }}>
            <ProjectHeader>
              <ProjectTitle>
                <img src={bolt} className={classes.icon} alt="" />
                {project.name}
              </ProjectTitle>
              <IconButton component={Link} to={`/licenses/${licenseId}/projects/${project.id}/measurements`}>
                <ChartsIcon />
              </IconButton>
            </ProjectHeader>

            <ExpansionPanel
              expanded={expansionState[project.id]}
              onChange={({ ...props }) => selectProject(project.id)(props)}
              className={classes.projectExpansion}
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <ProjectChips project={project} />
              </ExpansionPanelSummary>
              <PanelDetails>
                {fetchedMeasurements[project.id] ? (
                  <List>
                    {sortBy(m => m.created.seconds, fetchedMeasurements[project.id]).map(measurement => (
                      <ListItem key={measurement.id}>
                        <ListItemAvatar>
                          <TimerIcon style={{ fontSize: '2rem' }} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={measurement.name}
                          secondary={formatDate(measurement.created.toDate(), 'MMM dd, yyyy', i18n.language)}
                        />
                        <ListItemSecondaryAction>
                          <IconButton edge="end" aria-label="edit">
                            <EditIcon onClick={() => showRenameModal(oldState => ({ ...oldState, [measurement.id]: true }))} />
                          </IconButton>
                        </ListItemSecondaryAction>
                        {shouldDisplayRenameModal[measurement.id] && (
                          <RenameMeasurementModal
                            licenseId={licenseId}
                            projectId={project.id}
                            measurement={measurement}
                            onSuccess={onSuccessRename}
                            onCancel={() => showRenameModal(oldState => ({ ...oldState, [measurement.id]: false }))}
                          />
                        )}
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <Fade
                    in={loadingMeasurements[project.id]}
                    style={{
                      transitionDelay: loadingMeasurements[project.id] ? '800ms' : '0ms',
                    }}
                    unmountOnExit
                  >
                    <CircularProgress size={30} />
                  </Fade>
                )}
                <ProjectControlsWrapper>
                  {project.archived && (
                    <>
                      <Button variant="contained" size="small" className={classes.archiveButton} onClick={() => deleteProject(project)}>
                        <span style={{ fontSize: '0.75rem' }}>{t('project.archive.actions.delete')}</span>
                      </Button>
                      <Button variant="contained" size="small" className={classes.archiveButton} onClick={() => deArchiveProject(project)}>
                        <span style={{ fontSize: '0.75rem' }}>{t('project.archive.actions.deArchive')}</span>
                      </Button>
                    </>
                  )}
                  {!project.archived && (
                    <Button variant="contained" size="small" className={classes.archiveButton} onClick={() => archiveProject(project)}>
                      <span style={{ fontSize: '0.75rem' }}>{t('project.archive.actions.archive')}</span>
                    </Button>
                  )}
                </ProjectControlsWrapper>
              </PanelDetails>
            </ExpansionPanel>
          </Paper>
        ))}
      {/*{shouldDisplayRenameMeasurementModal && <RenameMeasurementModal oldName={'hoho'} onCancel={() => showRenameMeasurementModal(false)} />}*/}
    </>
  )
}

const Projects = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { setSelectedLicense, licenses, setLicenses } = useLicense()
  const [projects, setProjects] = useState([])
  const [shouldShowArchive, showArchive] = useState(false)
  const { licenseId } = useParams()
  const history = useHistory()

  const { user } = useAuth()

  const switchLicense = event => {
    localStorage.setItem('wcm.hott.license', event.target.value)
    history.push(`/licenses/${event.target.value}/projects`)
  }

  useEffect(() => {
    if (user && (!licenses || licenses.length === 0)) {
      firestore
        .doc(`users/${user.email.toLowerCase()}`)
        .get()
        .then(doc => {
          if (doc.exists) {
            const { licenseMap } = doc.data()
            const licenses = keys(licenseMap).map(id => ({ id, name: licenseMap[id] }))
            setLicenses(licenses || [])
          }
        })
    }
  }, [licenses, setLicenses, setSelectedLicense, user])

  useEffect(() => {
    const projectsRef = firestore.collection(`data/${licenseId}/projects`) //.where('sortKey', '<', 0)
    projectsRef.get().then(snapshot =>
      setProjects(
        reverse(
          sortBy(
            p => p.created.seconds,
            snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })),
          ),
        ),
      ),
    )
    showArchive(false)
  }, [licenseId])

  useEffect(() => {
    if (shouldShowArchive) {
      setTimeout(
        () =>
          window.scrollBy({
            top: window.innerHeight / 2,
            // left: 100,
            behavior: 'smooth',
          }),
        100,
      )
    }
  }, [shouldShowArchive])

  const onUpdateProject = project => {
    setProjects([...reject(p => p.id === project.id, projects), project])
  }

  const archivedProjects = projects.filter(p => p.archived && (p.active || isNil(p.active)))

  return (
    <>
      <NavBar />
      <Container maxWidth="md">
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="project">{t(`project.license`)}</InputLabel>
          <Select id="license" name="license" value={licenseId || ''} onChange={switchLicense}>
            {licenses &&
              licenses.map(lic => (
                <MenuItem value={lic.id} key={lic.id} className={classes.menuItem}>
                  {lic.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <ProjectList
          projects={projects.filter(p => !p.archived && (p.active || isNil(p.active)))}
          licenseId={licenseId}
          onUpdateProject={onUpdateProject}
        />

        {archivedProjects.length > 0 && (
          <ArchiveExpansionPanel expanded={shouldShowArchive} onChange={(e, expanded) => showArchive(expanded)}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>
                {t('project.archive.header')} ({archivedProjects.length})
              </Typography>
            </ExpansionPanelSummary>
            <PanelDetails>
              <ProjectList projects={archivedProjects} licenseId={licenseId} onUpdateProject={onUpdateProject} />
            </PanelDetails>
          </ArchiveExpansionPanel>
        )}
      </Container>
    </>
  )
}

export default Projects
