import React from 'react'
import { useField } from 'formik'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'

const SelectField = ({ label, wrapper: Wrapper = FormControl, children, ...props }) => {
  const [field, meta] = useField(props)
  const error = meta.touched && meta.error
  const labelId = (props.id || props.name) + '_label'
  return (
    <Wrapper error={error}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select labelId={labelId} {...field} {...props}>
        {children}
      </Select>
      {error && <FormHelperText>{meta.error}</FormHelperText>}
    </Wrapper>
  )
}

export default SelectField
