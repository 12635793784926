import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { keys } from 'ramda'
import { useTranslation } from 'react-i18next'

import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@material-ui/core/Typography'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Fade from '@material-ui/core/Fade'
import CircularProgress from '@material-ui/core/CircularProgress'

import NavBar from '../components/NavBar'
import { useAuth } from '../hooks/useAuth'
import { formatDate } from '../utils/misc'
import { firestore } from '../firebase'

const Section = styled(Paper)`
  margin-top: 2rem;
  padding: 1.3rem !important;
`
const Header = styled.h2`
  color: #7f9e5d;
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 0.7rem;
`
const LicenseName = styled.h3`
  font-weight: normal;
  margin-top: 0;
  text-decoration: underline;
  color: black;
  margin-bottom: 0.4rem;
`
const LicenseInfo = styled.div``
const LicenseAttributes = styled.dl`
  line-height: 1.5;
  margin: 0.3rem 0 1rem;
`
const Label = styled.dt`
  display: inline;
  :after {
    content: ': ';
  }
`
const Value = styled.dd`
  display: inline;
  margin: 0;
`
const UsersExpansionPanel = styled(ExpansionPanel)`
  width: 100%;
  box-shadow: none !important;
  padding: 0 !important;
  background-color: transparent;
  &&:before {
    height: 0;
  }
  .MuiExpansionPanelSummary-root {
    padding: 0;
  }
  .MuiPaper-root {
    padding: 0;
  }
  .MuiIconButton-root {
    padding: 0 8px;
  }
`
const PanelDetails = styled(ExpansionPanelDetails)`
  flex-wrap: wrap;
  padding: 0 !important;
  span {
    font-size: 0.85rem;
  }
  svg {
    font-size: 1rem;
  }
`
const AccountInfo = () => {
  const { t, i18n } = useTranslation()
  const [licenses, setLicenses] = useState([])
  const [showingUsers, showUsers] = useState({})
  const [fetchedUsers, setFetchedUsers] = useState({})
  const [loadingUsers, setLoadingUsers] = useState({})

  const { user } = useAuth()

  useEffect(() => {
    if (user) {
      firestore
        .doc(`users/${user.email.toLowerCase()}`)
        .get()
        .then(doc => {
          console.log('doc', doc.data())
          if (doc.exists) {
            keys(doc.data().licenseMap).forEach(licenseId => {
              firestore
                .doc(`data/${licenseId}`)
                .get()
                .then(doc => {
                  console.log('doc', doc.data())
                  setLicenses(oldState => [...oldState, doc.data()])
                })
            })
          }
        })
    }
  }, [user])

  const toggleUsers = (licenseId, expanded) => {
    if (expanded && !fetchedUsers[licenseId]) {
      setLoadingUsers(prevState => ({ ...prevState, [licenseId]: true }))

      const usersRef = firestore.collection(`users`).where('licenseIds', 'array-contains', licenseId)
      // .where('active', '==', true)
      usersRef.get().then(snapshot => {
        setFetchedUsers(oldState => ({ ...oldState, [licenseId]: snapshot.docs.map(doc => doc.id) }))
        setLoadingUsers(prevState => ({ ...prevState, [licenseId]: false }))
      })
    }
    showUsers(oldState => ({ ...oldState, [licenseId]: expanded }))
  }

  return (
    <>
      <NavBar />
      <Container maxWidth="sm">
        <Section>
          <Header>{t('accountInfo.accountInfoTitle')}</Header>
          {user.email}
        </Section>

        <Section>
          <Header>{t('accountInfo.licenseInfoTitle')}</Header>
          {licenses.map(license => (
            <LicenseInfo key={license.code}>
              <LicenseName>{license.name}</LicenseName>
              <LicenseAttributes>
                <Label>{t('accountInfo.licenseType')}</Label>
                <Value>{t(`accountInfo.licenseTypes.${license.type}`)}</Value>
                <br />
                <Label>{t('accountInfo.tokensLeft')}</Label>
                <Value>{license.tokenCount}</Value>
                <br />
                <Label>{t('accountInfo.expire')}</Label>
                <Value>{formatDate(license.expires.toDate(), 'dd MMM yyyy', i18n.language)}</Value>
                <br />

                <UsersExpansionPanel expanded={showingUsers[license.code]} onChange={(e, expanded) => toggleUsers(license.code, expanded)}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>{t('accountInfo.users')}</Typography>
                  </ExpansionPanelSummary>
                  <PanelDetails>
                    {fetchedUsers[license.code] ? (
                      <ul>
                        {fetchedUsers[license.code].map((user, id) => (
                          <li key={id}>{user}</li>
                        ))}
                      </ul>
                    ) : (
                      <Fade
                        in={fetchedUsers[license.code]}
                        style={{
                          transitionDelay: loadingUsers[license.code] ? '800ms' : '0ms',
                        }}
                        unmountOnExit
                      >
                        <CircularProgress size={30} />
                      </Fade>
                    )}
                  </PanelDetails>
                </UsersExpansionPanel>
              </LicenseAttributes>
            </LicenseInfo>
          ))}
        </Section>
      </Container>
    </>
  )
}

export default AccountInfo
