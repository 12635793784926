import React, { useEffect, useState } from 'react'
import { NavLink, useLocation, useParams, withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'

import AppBar from '@material-ui/core/AppBar/AppBar'
import logoSrc from '../img/hott-logo.png'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import Link from './Link'
import { makeStyles, withStyles } from '@material-ui/core'
import { useAuth } from '../hooks/useAuth'
import IconButton from '@material-ui/core/IconButton'
import { firestore } from '../firebase'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Popper from '@material-ui/core/Popper'
import Grow from '@material-ui/core/Grow'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import MenuList from '@material-ui/core/MenuList'
import Paper from '@material-ui/core/Paper'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { matchPath } from 'react-router'
import Badge from '@material-ui/core/Badge'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: theme.spacing(2),
  },
  appBar: {
    // minHeight: '250px',
    backgroundImage: 'url(hero.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 20,
    marginRight: 0,
    minHeight: 128,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    alignSelf: 'flex-end',
  },
  logo: {
    position: 'absolute',
    top: -20,
    left: '10%',
  },
  select: {
    marginLeft: '40px',
    marginTop: '5px',
    marginRight: '50px',
    '&.MuiInput-underline::before': {
      borderBottomWidth: '0 !important',
    },
  },
}))

const Wrapper = styled(({ home, ...rest }) => <AppBar {...rest} />)`
  min-height: ${props => (props.home ? '400px' : '170px')};
  background-position-y: top;
`
const LangWrapper = styled.div``

const LANGUAGES = ['en', 'nl', 'de', 'fr']

const languageOptions = t =>
  LANGUAGES.map(option => ({
    value: option,
    label: t(`options.language.${option}`),
  }))

const Dropdown = styled.div`
  a {
    text-decoration: none;
    color: inherit;
  }
`
const StyledBadge = withStyles(theme => ({
  badge: {
    right: 2,
    top: 13,
    border: `2px solid white`,
    padding: '0 4px',
  },
}))(Badge)

const NavBar = ({ showLogin = false, onClickSignIn = () => {}, match }) => {
  const auth = useAuth()
  const classes = useStyles()
  const { t, i18n } = useTranslation()
  const location = useLocation()
  const [quarantineCount, setQuarantineCount] = useState(0)
  const [roles, setRoles] = useState([])

  const { licenseId } = useParams()
  const { user } = useAuth()

  const [selectedLicenseId, setSelectedLicenseId] = useState(licenseId)

  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)

  const isAdmin = roles.includes('admin')

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  useEffect(() => {
    if (user && !selectedLicenseId) {
      firestore
        .doc(`users/${user.email.toLowerCase()}`)
        .get()
        .then(doc => {
          if (doc.exists) {
            const { licenseIds, selectedLicense } = doc.data()
            const licenseLocalStorage = localStorage.getItem('wcm.hott.license')
            let preferredLicense = null
            if (licenseIds && licenseIds.length > 0) {
              if (licenseLocalStorage && licenseIds.includes(licenseLocalStorage)) {
                preferredLicense = licenseLocalStorage
              } else if (selectedLicense && licenseIds.includes(selectedLicense)) {
                preferredLicense = selectedLicense
              } else {
                preferredLicense = licenseIds[0]
              }
            }

            setSelectedLicenseId(preferredLicense)
            // setLicenses(licenses || [])
          }
        })
    }
  }, [selectedLicenseId, user])

  useEffect(() => {
    if (user) {
      firestore
        .doc(`users/${user.email.toLowerCase()}`)
        .get()
        .then(doc => {
          if (doc.exists) {
            const { roles } = doc.data()
            setRoles(roles || [])
          }
        })
    }
  }, [selectedLicenseId, user])

  useEffect(() => {
    const lang = localStorage.getItem('wcm.hott.lang')
    if (LANGUAGES.includes(lang) && lang !== i18n.language) {
      i18n.changeLanguage(lang)
    }
  }, [i18n])

  useEffect(() => {
    if (auth.user && isAdmin) {
      firestore
        .collection('quarantine/new/measurements')
        .get()
        .then(snapshot => {
          setQuarantineCount(snapshot.size)
        })
    }
  }, [auth.user, isAdmin])

  const changeLanguage = ({ target: { value } }) => {
    i18n.changeLanguage(value)
    localStorage.setItem('wcm.hott.lang', value)
  }

  const signOut = e => {
    handleClose(e)
    auth.signOut()
  }

  return (
    <Wrapper position="static" className={classes.appBar} home={location.pathname === '/'}>
      <Toolbar className={classes.toolbar}>
        <IconButton component={Link} to={'/'} className={classes.logo}>
          <img src={logoSrc} alt="logo" />
        </IconButton>

        {!auth.user && showLogin && (
          <Button edge="end" variant="contained" color="secondary" onClick={onClickSignIn}>
            {t('navigation.signIn')}
          </Button>
        )}
        {auth.user && selectedLicenseId && match.path !== '/licenses/:licenseId/projects' && (
          <Button edge="end" variant="contained" color="secondary" component={NavLink} to={`/licenses/${selectedLicenseId}/projects`}>
            {t('navigation.myProjects')}
          </Button>
        )}
        {auth.user && isAdmin && (
          <>
            <Button
              edge="end"
              variant="contained"
              color="secondary"
              component={NavLink}
              to={`/manage/users`}
              className={classes.menuButton}
              activeStyle={{
                display: 'none',
              }}
            >
              users
            </Button>
            <Button
              edge="end"
              variant="contained"
              color="secondary"
              component={NavLink}
              to={`/manage/licenses`}
              className={classes.menuButton}
              activeStyle={{
                display: 'none',
              }}
            >
              licenses
            </Button>
            <Button
              edge="end"
              variant="contained"
              color="secondary"
              component={NavLink}
              to={`/manage/quarantine`}
              className={classes.menuButton}
              activeStyle={{
                display: 'none',
              }}
            >
              <StyledBadge badgeContent={quarantineCount} color="primary">
                <span style={{ paddingRight: quarantineCount > 0 ? '20px' : 0 }}>quarantine</span>
              </StyledBadge>
            </Button>
          </>
        )}
        <LangWrapper>
          <Select id="language" value={i18n.language} onChange={changeLanguage} className={classes.select}>
            {languageOptions(t).map(item => (
              <MenuItem value={item.value} key={item.value} className={classes.menuItem}>
                {item.value.toUpperCase()}
              </MenuItem>
            ))}
          </Select>
        </LangWrapper>

        {auth.user && (
          <Dropdown>
            <Button ref={anchorRef} onClick={handleToggle} color="primary">
              <MoreVertIcon />
            </Button>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal placement="bottom-end">
              {({ TransitionProps }) => (
                <Grow {...TransitionProps} style={{ transformOrigin: 'center top' }}>
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                        <MenuItem onClick={handleClose}>
                          <Link to={'/licenses'}>{t('navigation.accountInfo')}</Link>
                        </MenuItem>
                        <MenuItem onClick={signOut}> {t('navigation.signOut')}</MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Dropdown>
        )}
      </Toolbar>
    </Wrapper>
  )
}

export default withRouter(NavBar)
