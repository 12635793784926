export default {
  translation: {
    home: {
      intro:
        'Unsere HoTT-Datenbank enthält Messungen mit mehr als {hours} Stunden, welche über mehrere Jahre hinweg gesammelt wurden und z. B. nach Sektoren, Disziplinen und Abteilungen gruppiert und über verschiedene Regionen und Länder verteilt sind.',
      header: 'Hands on Tool Time Benchmark',
    },
    charts: {
      labels: {
        avoidable: {
          yes: 'Vermeidbar',
          no: 'Unvermeidbar',
        },
        category: {
          dp: 'Direkt Produktiv',
          ip: 'Indirekt Produktiv',
          np: 'Nicht Produktiv',
          dp1: 'PRODUCTIV',
          ip2: 'DISKUSSIONEN',
          ip3: 'WARTEN INTERN',
          ip4: 'WERKZEUGE',
          ip5: 'MATERIAL',
          ip6: 'GENEHMIGUNGEN',
          ip7: 'WARTEN EXTERN',
          ip8: 'TRANSPORT',
          ip9: 'VERZÖGERUNG ANLAGE',
          ip10: 'INFORMATIONEN',
          ip11: 'SICHERHEIT VERSAMML',
          ip12: 'GENERALVERSAMMLUNGEN',
          ip13: 'KÖRPERPFLEGE',
          np14: 'FRÜH BEREIT',
          np15: 'VERZÖGERUNG',
          np16: 'EXTRA PAUSE',
          pz1: 'GEPLANTE PAUSE',
          'dp1-1': 'VOR ORT',
          'dp1-2': 'WERKSTATT',
          'dp1-3': 'EXTRA',
          'dp1-4': 'EINRICHTEN',
          'dp1-5': 'AUFRÄUMEN',
          'ip2-1': 'ÜBERGABE AUFTRÄGEN',
          'ip2-2': 'MIT LEITUNG',
          'ip2-3': 'MIT EINANDER',
          'ip2-4': 'LMRA',
          'ip4-1': 'KEINE WERKZEUGE',
          'ip4-2': 'WERKZEUGEN AUS LAGER',
          'ip5-1': 'KEIN MATERIAL',
          'ip5-2': 'MATERIALABZUG',
          'ip7-1': 'PROZESSABSICHERUNG',
          'ip7-2': 'TECHN ABSICHEREN',
          'ip7-3': 'SCHACHTWACHE',
          'ip7-4': 'REINIGUNG ARBEITSBEREICH',
          'ip7-5': 'ABISOLIEREN',
          'ip7-6': 'GERÜSTEN',
          'ip7-7': 'HEBEN/TRANSPORT',
          'ip7-8': 'FREIGABE',
          'ip7-9': 'INBETRIEBNAHME',
          'ip8-1': 'TRANSPORTZEIT',
          'ip8-2': 'HOLEN WERKZEUGEN',
          'ip9-1': 'ANLAGE NICHT VERFÜGBAR',
          'ip9-2': 'UNVORHERGESEHEN',
          'ip10-1': 'SUCHEN UNTERLAGEN',
          'ip10-2': 'KENTNIS',
          'ip10-3': 'DATEN',
          'ip10-4': 'VERWALTUNG',
          'ip10-5': 'BERICHTERSTATTUNG',
          'ip10-6': 'PLANUNG',
          'ip11-1': 'SICHERHEIT ALGEM.',
          'ip11-2': 'SICHERHEIT SPEZ.',
        },
      },
      benchmark: 'Benchmark',
      activities: 'Aktivitäten',
      hours: 'Stunden',
      hourCount: 'Anzahl der Stunden',
      activityCount: 'Anzahl der Aktivitäten',
      ownMeasurements: 'Eigene Messungen',
      hottDatabase: 'HoTT Datenbank',
      options: {
        count: 'Anzahl',
        time: 'Zeit',
      },
      legends: {
        time: 'Zeit (Stunden)',
        count: 'Anzahl (Aktivitäten)',
      },
      legendsBenchmark: {
        time: 'Zeit (Percentage)',
        count: 'Anzahl (Percentage)',
      },
      sortCriteria: {
        category: 'Kategorie',
        amount: 'Menge',
        avoid: 'Vermeidbarkeit',
      },
      titles: {
        prod: 'Productivitätsgrafik',
        avoid: 'Vermeidbarkeitgrafik',
        combo: 'Combografik',
        level: 'Niveau',
      },
      configDialog: {
        header: 'Grafikkonfiguration',
        title: 'Titel',
        maxY: 'Maximalwert Y-as',
        hideValues: 'Werte in Grafik verbergen',
        downloadTransparent: 'Grafik mit transparentem Hintergrund herunterladen',
        secondsUnit: 'Zeitmessung in Sekunden',
        showZeroValues: '0-Werte anzeigen',
        categories: 'Kategorien',
        subcategories: 'Unterkategorien',
      },
    },
    options: {
      country: {
        BE: 'Belgien',
        NL: 'Niederlande',
        FR: 'Frankreich',
        DE: 'Deutschland',
        IT: 'Italien',
        LU: 'Luxemburg',
      },
      language: {
        en: 'English',
        nl: 'Niederländisch',
        de: 'Deutsch',
        fr: 'Französisch',
      },
      category: {
        dp1: 'PRODUCTIV',
        ip2: 'DISKUSSIONEN',
        ip3: 'WARTEN INTERN',
        ip4: 'WERKZEUGE',
        ip5: 'MATERIAL',
        ip6: 'GENEHMIGUNGEN',
        ip7: 'WARTEN EXTERN',
        ip8: 'TRANSPORT',
        ip9: 'VERZÖGERUNG ANLAGE',
        ip10: 'INFORMATIONEN',
        ip11: 'SICHERHEIT VERSAMML',
        ip12: 'GENERALVERSAMMLUNGEN',
        ip13: 'KÖRPERPFLEGE',
        np14: 'FRÜH BEREIT',
        np15: 'VERZÖGERUNG',
        np16: 'EXTRA PAUSE',
        pz1: 'GEPLANTE PAUSE',
      },
      subcategory: {
        'dp1-1': 'VOR ORT',
        'dp1-2': 'WERKSTATT',
        'dp1-3': 'EXTRA',
        'dp1-4': 'EINRICHTEN',
        'dp1-5': 'AUFRÄUMEN',
        'ip2-1': 'ÜBERGABE AUFTRÄGEN',
        'ip2-2': 'MIT LEITUNG',
        'ip2-3': 'MIT EINANDER',
        'ip2-4': 'LMRA',
        'ip4-1': 'KEINE WERKZEUGE',
        'ip4-2': 'WERKZEUGEN AUS LAGER',
        'ip5-1': 'KEIN MATERIAL',
        'ip5-2': 'MATERIALABZUG',
        'ip7-1': 'PROZESSABSICHERUNG',
        'ip7-2': 'TECHN ABSICHEREN',
        'ip7-3': 'SCHACHTWACHE',
        'ip7-4': 'REINIGUNG ARBEITSBEREICH',
        'ip7-5': 'ABISOLIEREN',
        'ip7-6': 'GERÜSTEN',
        'ip7-7': 'HEBEN/TRANSPORT',
        'ip7-8': 'FREIGABE',
        'ip7-9': 'INBETRIEBNAHME',
        'ip8-1': 'TRANSPORTZEIT',
        'ip8-2': 'HOLEN WERKZEUGEN',
        'ip9-1': 'ANLAGE NICHT VERFÜGBAR',
        'ip9-2': 'UNVORHERGESEHEN',
        'ip10-1': 'SUCHEN UNTERLAGEN',
        'ip10-2': 'KENTNIS',
        'ip10-3': 'DATEN',
        'ip10-4': 'VERWALTUNG',
        'ip10-5': 'BERICHTERSTATTUNG',
        'ip10-6': 'PLANUNG',
        'ip11-1': 'SICHERHEIT ALGEM.',
        'ip11-2': 'SICHERHEIT SPEZ.',
      },
      department: {
        maintenance: 'Wartung',
        production: 'Produktion',
        other: 'Andere',
      },
      sector: {
        chemical: 'Chemie',
        oil_gas: 'Öl & Gaz',
        power: 'Energie',
        heavy_industry: 'Schwerindustrie',
        manufacturing: 'Herstellung',
        food_beverage: 'Lebensmittel & Getränke',
        automotive: 'Automotive',
        pharma: 'Pharmazeutisch',
        public_transport: 'ÖPNV',
        logistics: 'Logistik',
        other: 'Andere',
      },
      discipline: {
        mechanical: 'Schlösser',
        ei: 'EI',
        scaffolding: 'Gerüst',
        cleaning: 'Reinigung',
        pb: 'PB',
        insulation: 'Isolierung',
        piping: 'Piping',
        civil: 'Tiefbau Civil',
        painting: 'Malerei',
        other: 'Andere',
      },
      contractType: {
        unit: 'Unit Rate',
        hourly: 'Stundensatz',
        fixed_price: 'Fixpreis',
      },
      resource: {
        own: 'Eigenes Personal',
        internal: 'Interner Auftragnehmer',
        external: 'Externer Auftragnehmer',
        service: 'Service Auftragnehmer',
      },
      workType: {
        regular_planned: 'geplant',
        regular_unplanned: 'Ungeplant',
        project_tar: 'TAR',
        project: 'Projekt',
      },
      shift: {
        day: 'Tag',
        shift: 'Schicht',
      },
      workshop: {
        centralized: 'Zentral',
        decentralized: 'Lokal',
      },
    },
    common: {
      YES: 'JA',
      OK: 'OK',
      SAVE: 'SPEICHERN',
      NO: 'NEIN',
      CANCEL: 'STORNIEREN',
      required: 'Pflichtfeld',
      year: 'Jahr',
    },
    navigation: {
      myProjects: 'Meine Projekte',
      signIn: 'Einloggen',
      signOut: 'Ausloggen',
      accountInfo: 'Kontoinformation',
    },
    project: {
      license: 'Lizenz',
      archive: {
        header: 'Archiv Projekte',
        actions: {
          archive: 'Projekt archivieren',
          deArchive: 'Projekt de-archivieren',
          delete: 'Projekt löschen',
        },
      },
      create: {
        title: 'Neues Projekt',
        location: 'Ort',
        name: 'Projektname',
        client: 'Kunde',
        country: 'Land',
      },
      overview: {
        title: 'HoTT Projekte',
      },
      delete: {
        title: 'Projekt löschen',
        askConfirmation: 'Möchten Sie das ausgewählte Projekt wirklich löschen?',
      },
    },
    measurement: {
      rename: {
        title: 'Umbenennen',
      },
      viewMode: {
        chart: 'Grafik',
        details: 'Details',
      },
      benchmark: {
        filter: {
          all: 'Alle',
          none: 'Keine',
        },
      },
      project: 'Projekt',
      department: 'Abteilung',
      sector: 'Sektor',
      discipline: 'Disziplin',
      contractType: 'Vertragsart',
      resource: 'Resources',
      workType: 'Art der Arbeit',
      shift: 'Schicht',
      workshop: 'Workshop',
      create: {
        title: 'Neue Messung',
        name: 'Name der Messung',
      },
      overview: {
        title: 'Messungen',
      },
      chart: {
        title: 'Statistiken',
        legend: {
          types: {
            dp: 'direkt produktiv',
            ip: 'indirekt produktiv',
            np: 'nicht produktiv',
            na: 'nicht aufgeführt',
          },
          avoidable: {
            yes: 'vermeidbar',
            no: 'unvermeidbar',
            na: 'nicht aufgeführt',
          },
        },
        productivity: 'Produktivität',
        avoidability: 'Vermeidbarkeit',
        combo: 'combo',
      },
    },
    activity: {
      remark: 'Anmerkung',
      category: 'Kategorie',
      subcategory: 'Unterkategorie',
      create: {
        title: 'Neue Aktivität',
        titleExisting: 'Aktivität',
        takePhoto: 'Foto machen',
        avoidable: 'Vermeidbar',
        begin: 'Start',
        end: 'Ende',
        duration: 'Dauer',
      },
    },
    license: {
      title: 'HoTT Lizenz',
      tokens: 'Token',
    },
    error: {
      header: 'Hands on Tool Time',
      title: 'Ein Fehler ist aufgetreten',
      feedback: 'Feedback',
    },
    menu: {
      signOut: 'ausloggen',
      accountInfo: 'Kontoinformation',
      about: 'Infos über HoTT',
      settings: 'Einstellungen',
      switchLicense: 'Lizenz wechseln',
    },
    accountInfo: {
      title: 'Konto- und Lizenzinformationen',
      account: 'Konto',
      license: 'Lizenz',
      users: 'Benutzer',
      licenseType: 'Lizenz-Typ',
      tokensLeft: 'Token übrig',
      expire: 'Ablaufdatum',
      licenseTypes: {
        '1star': '1 Stern',
        '2star': '2 Sterne',
        '3star': '3 Sterne',
        training: 'Training',
      },
    },
    settings: {
      title: 'Einstellungen',
      language: 'Bevorzugte Sprache',
    },
  },
}
