import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/styles'

import Home from './pages/Home'
import theme from './theme'
import AccountInfo from './pages/AccountInfo'
import Projects from './pages/Projects/Projects'
import { AuthProvider } from './hooks/useAuth'
import { LicenseProvider } from './hooks/useLicense'
import PrivateRoute from './components/PrivateRoute'
import Measurements from './pages/Measurements/Measurements'
import SendInvite from './pages/SendInvite'
import Footer from './components/Footer'
import Users from './pages/Users/Users'
import Licenses from './pages/Licenses/Licenses'
import Quarantine from './pages/Quarantine/Quarantine'

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <LicenseProvider>
          <Router>
            <Switch>
              <Route path="/" exact>
                <Home />
              </Route>
              <PrivateRoute path="/licenses" exact>
                <AccountInfo />
              </PrivateRoute>
              <PrivateRoute path="/manage/users" exact>
                <Users />
              </PrivateRoute>
              <PrivateRoute path="/manage/licenses" exact>
                <Licenses />
              </PrivateRoute>
              <PrivateRoute path="/manage/quarantine" exact>
                <Quarantine />
              </PrivateRoute>
              <PrivateRoute path="/licenses/:licenseId/projects" exact>
                <Projects />
              </PrivateRoute>
              {/*<PrivateRoute path="/invite" exact>*/}
              {/*  <SendInvite />*/}
              {/*</PrivateRoute>*/}
              <PrivateRoute path="/licenses/:licenseId/projects/:projectId/measurements">
                <Measurements />
              </PrivateRoute>
            </Switch>
          </Router>
          <Footer />
        </LicenseProvider>
      </AuthProvider>
    </ThemeProvider>
  )
}

export default App
