import React from 'react'
import styled from '@emotion/styled'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import logo from '../img/logo-footer.png'

const Wrapper = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  height: 112px;
  background-color: #eeeeee;
  width: 100%;
  padding: 10px 10%;
  a {
    text-decoration: none;
    color: #333333;
    margin: 0 10px;
  }
`
const Logo = styled.img``
const Links = styled.div`
  display: flex;
  align-items: center;
`

const Footer = () => {
  return (
    <Wrapper>
      <Logo src={logo} />
      <Links>
        <a href="https://www.worldclassmaintenance.com/" target="_blank" rel="noopener noreferrer">
          World Class Maintenance
        </a>
        <FiberManualRecordIcon style={{ fontSize: '6px' }} />
        <a href="https://www.worldclassmaintenance.com/project/hands-on-tool-time/" target="_blank" rel="noopener noreferrer">
          About
        </a>
        <FiberManualRecordIcon style={{ fontSize: '6px' }} />
        <a href="https://www.worldclassmaintenance.com/wp-content/uploads/2017/05/WCM-HoTT-IP-EN.pdf" target="_blank" rel="noopener noreferrer">
          Privacy
        </a>
        <FiberManualRecordIcon style={{ fontSize: '6px' }} />
        <a
          href="https://www.worldclassmaintenance.com/wp-content/uploads/2017/05/WCM-HoTT-licentiemodel-2-EN.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms
        </a>
      </Links>
    </Wrapper>
  )
}

export default Footer
