export default {
  translation: {
    home: {
      intro:
        'Our HoTT database contains more than {{hours}} hours of measurements, collected over several years and neatly grouped according to, among others, sector, discipline, department and spread over different regions and countries.',
      header: 'Hands on Tool Time benchmark',
    },
    charts: {
      labels: {
        avoidable: {
          yes: 'Avoidable',
          no: 'Not Avoidable',
        },
        category: {
          dp: 'Directly Productive',
          ip: 'Indirectly Productive',
          np: 'Not Productive',
          dp1: 'PRODUCTIVE',
          ip2: 'DISCUSSION',
          ip3: 'WAITING INTERNAL',
          ip4: 'TOOLS',
          ip5: 'MATERIAL',
          ip6: 'PERMITS',
          ip7: 'WAITING EXTERNAL',
          ip8: 'TRANSPORT',
          ip9: 'DELAYS INSTALLATION',
          ip10: 'INFORMATION',
          ip11: 'MEETING SAFETY',
          ip12: 'MEETING GENERAL',
          ip13: 'PERSONAL CARE',
          np14: 'READY EARLY',
          np15: 'DELAY EXTERNAL',
          np16: 'EXTRA BREAK',
          pz1: 'PLANNED BREAK',
          'dp1-1': 'ON SITE',
          'dp1-2': 'WORKSHOP',
          'dp1-3': 'EXTRA',
          'dp1-4': 'SET UP WORK PLACE',
          'dp1-5': 'CLEAN UP',
          'ip2-1': 'HANDING OUT WO',
          'ip2-2': 'MANAGEMENT',
          'ip2-3': 'TOGETHER',
          'ip2-4': 'LMRA',
          'ip4-1': 'NO TOOLS',
          'ip4-2': 'TOOLS FROM STORE',
          'ip5-1': 'NO MATERIALS',
          'ip5-2': 'MATERIALS STORE',
          'ip7-1': 'SAFETY PROCESS',
          'ip7-2': 'SAFETY TECHNICAL',
          'ip7-3': 'MANHOLE GUARD',
          'ip7-4': 'CLEANING WORK AREA',
          'ip7-5': 'INSULATION REMOVAL',
          'ip7-6': 'SCAFFOLDING',
          'ip7-7': 'LIFTING/TRANSPORT',
          'ip7-8': 'UNLOCKING',
          'ip7-9': 'COMMISSIONING',
          'ip8-1': 'TRANSPORT TIME',
          'ip8-2': 'COLLECTING TOOLS/MATERIALS',
          'ip9-1': 'EQUIPMENT NOT AVAILABLE',
          'ip9-2': 'UNFORESEEN',
          'ip10-1': 'FIND DOCUMENTATION',
          'ip10-2': 'KNOWLEDGE',
          'ip10-3': 'DATA',
          'ip10-4': 'PAPER WORK',
          'ip10-5': 'REPORTING',
          'ip10-6': 'PLANNING',
          'ip11-1': 'SAFETY GEN',
          'ip11-2': 'SAFETY SPEC',
        },
      },
      benchmark: 'Benchmark',
      activities: 'activities',
      hours: 'hours',
      hourCount: 'Number of hours',
      activityCount: 'Number of activities',
      ownMeasurements: 'Own Measurements',
      hottDatabase: 'HoTT Database',
      options: {
        count: 'count',
        time: 'time',
      },
      legends: {
        time: 'Time (hours)',
        count: 'Count (activities)',
      },
      legendsBenchmark: {
        time: 'Time (percentage)',
        count: 'Count (percentage)',
      },
      sortCriteria: {
        category: 'category',
        amount: 'amount',
        avoid: 'avoidability',
      },
      titles: {
        prod: 'Productivity Chart',
        avoid: 'Avoidability Chart',
        combo: 'Combo Chart',
        level: 'Level',
      },
      configDialog: {
        header: 'Chart Configuration',
        title: 'Title',
        maxY: 'Max value Y-axis',
        hideValues: 'Hide values in chart',
        downloadTransparent: 'Download chart with transparent background',
        secondsUnit: 'Time measurement in seconds',
        showZeroValues: 'Show zero values',
        categories: 'categories',
        subcategories: 'subcategories',
      },
    },
    options: {
      country: {
        BE: 'Belgium',
        NL: 'Netherlands',
        FR: 'France',
        DE: 'Germany',
        IT: 'Italy',
        LU: 'Luxembourg',
      },
      language: {
        en: 'English',
        nl: 'Dutch',
        de: 'German',
        fr: 'French',
      },
      category: {
        dp1: 'PRODUCTIVE',
        ip2: 'DISCUSSION',
        ip3: 'WAITING INTERNAL',
        ip4: 'TOOLS',
        ip5: 'MATERIAL',
        ip6: 'PERMITS',
        ip7: 'WAITING EXTERNAL',
        ip8: 'TRANSPORT',
        ip9: 'DELAYS INSTALLATION',
        ip10: 'INFORMATION',
        ip11: 'MEETING SAFETY',
        ip12: 'MEETING GENERAL',
        ip13: 'PERSONAL CARE',
        np14: 'READY EARLY',
        np15: 'DELAY EXTERNAL',
        np16: 'EXTRA BREAK',
        pz1: 'PLANNED BREAK',
      },
      subcategory: {
        'dp1-1': 'ON SITE',
        'dp1-2': 'WORKSHOP',
        'dp1-3': 'EXTRA',
        'dp1-4': 'SET UP WORK PLACE',
        'dp1-5': 'CLEAN UP',
        'ip2-1': 'HANDING OUT WO',
        'ip2-2': 'MANAGEMENT',
        'ip2-3': 'TOGETHER',
        'ip2-4': 'LMRA',
        'ip4-1': 'NO TOOLS',
        'ip4-2': 'TOOLS FROM STORE',
        'ip5-1': 'NO MATERIALS',
        'ip5-2': 'MATERIALS STORE',
        'ip7-1': 'SAFETY PROCESS',
        'ip7-2': 'SAFETY TECHNICAL',
        'ip7-3': 'MANHOLE GUARD',
        'ip7-4': 'CLEANING WORK AREA',
        'ip7-5': 'INSULATION REMOVAL',
        'ip7-6': 'SCAFFOLDING',
        'ip7-7': 'LIFTING/TRANSPORT',
        'ip7-8': 'UNLOCKING',
        'ip7-9': 'COMMISSIONING',
        'ip8-1': 'TRANSPORT TIME',
        'ip8-2': 'COLLECTING TOOLS/MATERIALS',
        'ip9-1': 'EQUIPMENT NOT AVAILABLE',
        'ip9-2': 'UNFORESEEN',
        'ip10-1': 'FIND DOCUMENTATION',
        'ip10-2': 'KNOWLEDGE',
        'ip10-3': 'DATA',
        'ip10-4': 'PAPER WORK',
        'ip10-5': 'REPORTING',
        'ip10-6': 'PLANNING',
        'ip11-1': 'SAFETY GEN',
        'ip11-2': 'SAFETY SPEC',
      },
      department: {
        maintenance: 'Maintenance',
        production: 'Production',
        other: 'Other',
      },
      sector: {
        chemical: 'Chemical',
        oil_gas: 'Oil & Gas',
        power: 'Power',
        heavy_industry: 'Heavy Industry',
        manufacturing: 'Manufacturing',
        food_beverage: 'Food & Beverage',
        automotive: 'Automotive',
        pharma: 'Pharmaceutical',
        public_transport: 'Public Transport',
        logistics: 'Logistics',
        other: 'Other',
      },
      discipline: {
        mechanical: 'Mechanical',
        ei: 'EI',
        scaffolding: 'Scaffolding',
        cleaning: 'Cleaning',
        pb: 'PB',
        insulation: 'Insulation',
        piping: 'Piping',
        civil: 'Civil',
        painting: 'Painting',
        other: 'Other',
      },
      contractType: {
        unit: 'Unit rate',
        hourly: 'Hourly rate',
        fixed_price: 'Fixed price',
      },
      resource: {
        own: 'Own staff',
        internal: 'Internal Contractor',
        external: 'External Contractor',
        service: 'Service Contractor',
      },
      workType: {
        regular_planned: 'Regular, Planned',
        regular_unplanned: 'Regular, Unplanned',
        project_tar: 'TAR',
        project: 'Project',
      },
      shift: {
        day: 'Day',
        shift: 'Shift',
      },
      workshop: {
        centralized: 'Centralized',
        decentralized: 'Decentralized',
      },
    },
    common: {
      YES: 'YES',
      OK: 'OK',
      SAVE: 'SAVE',
      NO: 'NO',
      CANCEL: 'CANCEL',
      required: 'Required',
      year: 'year',
    },
    navigation: {
      myProjects: 'my projects',
      signIn: 'Sign in',
      signOut: 'Sign out',
      accountInfo: 'Account info',
    },
    project: {
      license: 'License',
      archive: {
        header: 'projects archive',
        actions: {
          archive: 'archive project',
          deArchive: 'de-archive project',
          delete: 'delete project',
        },
      },
      create: {
        title: 'New project',
        location: 'location',
        name: 'project name',
        client: 'client',
        country: 'country',
      },
      overview: {
        title: 'HoTT projects',
      },
      delete: {
        title: 'Delete project',
        askConfirmation: 'Are you sure you want to delete the selected project?',
      },
    },
    measurement: {
      rename: {
        title: 'Rename',
      },
      viewMode: {
        chart: 'chart',
        details: 'details',
      },
      benchmark: {
        filter: {
          all: 'all',
          none: 'none',
        },
      },
      project: 'Project',
      department: 'Department',
      sector: 'Sector',
      discipline: 'Discipline',
      contractType: 'Contract Type',
      resource: 'Resource',
      workType: 'Work Type',
      shift: 'Shift',
      workshop: 'Workshop',
      chart: {
        title: 'Stats',
        legend: {
          types: {
            dp: 'Directly Productive',
            ip: 'Indirectly Productive',
            np: 'Not Productive',
            na: 'Not Specified',
          },
          avoidable: {
            yes: 'Avoidable',
            no: 'Unavoidable',
            na: 'Not Specified',
          },
        },
        productivity: 'Productivity',
        avoidability: 'Avoidability',
        combo: 'Combo',
        actions: {
          downloadData: 'download data',
          downloadChart: 'download chart',
        },
      },
    },
    activity: {
      remark: 'remark',
      category: 'category',
      subcategory: 'subcategory',
      overview: {
        title: 'Activities',
      },
    },
    license: {
      title: 'HoTT License',
      tokens: 'tokens',
    },
    error: {
      header: 'Hands on Tool Time',
      title: 'An error occurred',
      feedback: 'feedback',
    },
    menu: {
      signOut: 'Sign out',
      accountInfo: 'Account info',
      about: 'About HoTT',
      settings: 'Settings',
      switchLicense: 'Switch license',
    },
    accountInfo: {
      accountInfoTitle: 'Account info',
      licenseInfoTitle: 'License info',
      licenseType: 'Type',
      tokensLeft: 'Tokens left',
      expire: 'Valid until',
      users: 'Users',
      licenseTypes: {
        '1star': '1 star',
        '2star': '2 star',
        '3star': '3 star',
        training: 'training',
      },
    },
    settings: {
      title: 'Settings',
      language: 'language',
    },
    licenseManager: {
      editModal: {
        title: 'Edit license',
      },
      usersModal: {
        title: 'Manage licensees for {{license}}',
        licensees: 'Licensees',
        otherUsers: 'Other users',
        addToLicense: 'Add to license',
        removeFromLicense: 'Remove from license',
        noLicenseesFound: 'No licensees found',
        noUsersFound: 'No users found',
      },
      createModal: {
        title: 'New license',
        nameLabel: 'name',
        typeLabel: 'type',
        tokenLabel: 'tokens',
        activeLabel: 'active',
        expires: 'expire date',
      },
    },
    quarantine: {
      title: 'Quarantine',
      decline: 'afwijzen',
      accept: 'aanvaarden',
      noData: 'the quarantine is empty',
      declineReason: {
        TRAINING: 'test/training meting',
        OUT_OF_BOUNDS: 'wijkt te veel af',
        TOO_SHORT: 'te kort',
      },
      acceptModal: {
        title: 'Meting in benchmark opnemen',
      },
      rejectModal: {
        title: 'Afwijzen',
        description: 'De meting zal NIET in benchmark opgenomen worden.',
        reason: 'reden',
      },
    },
  },
}
