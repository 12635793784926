import React from 'react'
import { useField } from 'formik'
import { isNil, isEmpty } from 'ramda'
import FormControl from '@material-ui/core/FormControl'
import BaseInputField from '@material-ui/core/TextField'
import FormHelperText from '@material-ui/core/FormHelperText'

const TextField = ({ label, wrapper: Wrapper = FormControl, ...props }) => {
  const [field, meta] = useField(props)
  const error = meta.touched && meta.error
  return (
    <Wrapper error={error}>
      <BaseInputField label={label} {...field} {...props} InputLabelProps={{ shrink: !isNil(field.value) && !isEmpty(field.value) }} error={error} />
      {error && <FormHelperText>{meta.error}</FormHelperText>}
    </Wrapper>
  )
}

export default TextField
