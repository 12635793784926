import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import styled from '@emotion/styled'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import {makeStyles} from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import FormHelperText from '@material-ui/core/FormHelperText'
import CloseIcon from '@material-ui/icons/Close'
import {firestore} from '../../firebase'

const useStyles = makeStyles(theme => ({
  table: {
    width: 'auto',
  },
  formControl: {
    width: '100%',
    display: 'block',
    margin: '12px 0',
  },
}))

const Title = styled(DialogTitle)`
  color: white !important;
  background-color: #7f9e5d;
  padding-right: 16px !important;
  h2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`
const RenameDialog = styled(Dialog)`
  .MuiPaper-root {
    padding: 0;
  }
`
const CloseModalIcon = styled(CloseIcon)`
  cursor: pointer;
`
const ActionsWrapper = styled.div`
  padding: 8px 15px;
`
const RenameMeasurementModal = ({ onCancel, onSuccess, licenseId, projectId, measurement }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [name, setName] = useState(measurement.name)
  const [error, setError] = useState(false)

  const onOk = async () => {
    if (!name) {
      setError(true)
    } else {
      const measurementRef = firestore.doc(`data/${licenseId}/projects/${projectId}/measurements/${measurement.id}`)
      try {
        await measurementRef.update({ name })
        onSuccess(projectId, { ...measurement, name })
      } catch (e) {
        console.log('error', e)
      }
    }
  }

  const handleChange = event => {
    setName(event.target.value)
  }

  return (
    <RenameDialog open onClose={onCancel}>
      <Title>
        <span>{t('measurement.rename.title')}</span> <CloseModalIcon onClick={onCancel} />
      </Title>
      <DialogContent style={{ minWidth: '20rem' }}>
        <FormControl className={classes.formControl} error={error && !name}>
          <TextField
            fullWidth
            label={t('measurement.create.name')}
            value={name}
            onChange={handleChange}
            InputLabelProps={{ shrink: !!name }}
            error={error && !name}
          />
          {error && !name && <FormHelperText>{t('common.required')}</FormHelperText>}
        </FormControl>
      </DialogContent>
      <ActionsWrapper>
        <DialogActions>
          <Button onClick={onCancel} color="primary">
            {t('common.CANCEL')}
          </Button>
          <Button onClick={onOk} variant="contained" color="secondary">
            {t('common.SAVE')}
          </Button>
        </DialogActions>
      </ActionsWrapper>
    </RenameDialog>
  )
}

export default RenameMeasurementModal
