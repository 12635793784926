import { createMuiTheme } from '@material-ui/core'

export const primaryColor = '#7F9E5D'
export const secondaryColor = '#000000'
export const textColor = '#616161'

export default createMuiTheme({
  colors: {
    primaryColor,
    secondaryColor,
    textColor,
  },
  palette: {
    primary: {
      light: '#ff7961',
      main: primaryColor,
      dark: primaryColor,
      contrastText: '#fff',
    },
    secondary: {
      light: secondaryColor,
      main: secondaryColor,
      dark: secondaryColor,
      contrastText: '#fff',
    },
  },

  overrides: {
    MuiToolbar: {
      regular: {
        minHeight: '48px !important',
        maxHeight: '48px !important',
      },
      gutters: {
        paddingLeft: '7px',
        paddingRight: '0px',
      },
    },
    MuiContainer: {
      root: {
        // padding: '0 8px 8px !important',
        // marginTop: '70px',
      },
    },
    MuiDrawer: {
      paper: {
        padding: 0,
      },
    },
    MuiPaper: {
      root: {
        color: textColor,
        padding: '8px',
        marginBottom: '12px',
        userSelect: 'none',
      },
    },
    MuiDialogTitle: {
      root: {
        color: 'black',
      },
    },
    MuiFab: {
      root: {
        right: '40px',
        bottom: '40px',
        position: 'fixed',
      },
    },
    MuiFormLabel: {
      root: {
        userSelect: 'none',
        color: `${textColor} !important`,
      },
    },
    MuiChip: {
      label: {
        color: textColor,
        paddingLeft: '4px',
        paddingRight: '4px',
        fontSize: '12px',
        marginBottom: '-2px',
      },
      avatar: {
        height: '14px',
        width: '22px',
        marginLeft: '0 !important',
      },
      outlined: {
        borderRadius: '2px',
        padding: '2px 0',
        height: 'auto',
        borderColor: '#f0f0f0',
        borderWidth: '2px',
        marginRight: '.5rem',
        marginBottom: '.5rem',
      },
    },
    MuiLink: {
      userSelect: 'none',
      underlineHover: {
        '&:hover': {
          textDecoration: 'none',
        },
      },
    },
    MuiTypography: {
      h1: {
        fontSize: '20px',
        fontWeight: 500,
        userSelect: 'none',
      },
      h2: {
        fontSize: '18px',
        fontWeight: 400,
        userSelect: 'none',
      },
      h3: {
        userSelect: 'none',
        display: 'flex',
        alignItems: 'center',
        color: textColor,
        fontSize: '16px',
        lineHeight: 1.2,
        fontWeight: 400,
        marginBottom: '5px',
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      },
      root: {
        width: '100%',
      },
    },
    MuiListItem: {
      root: {
        '&$selected': {
          backgroundColor: 'inherit !important',
          fontWeight: 'bold',
          color: secondaryColor,
        },
      },
      button: {
        '&:hover': {
          backgroundColor: 'transparent',
          color: secondaryColor,
        },
      },
    },
    MuiListItemAvatar: {
      root: {
        minWidth: '45px',
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: secondaryColor,
      },
    },
    MuiTab: {
      textColorPrimary: {
        fontWeight: 'bold',
        '&.Mui-selected': {
          fontWeight: 'bold',
          color: secondaryColor,
        },
      },
    },
    MuiBox: {
      root: {
        padding: 0,
      },
    },
    MuiButton: {
      root: {
        fontWeight: 'bold',
        minWidth: '36px',
        color: primaryColor,
        '&:hover': {
          backgroundColor: 'transparent',
        },
        '&+button': {
          marginLeft: '16px',
        },
      },
      contained: {
        color: '#737373',
        backgroundColor: 'rgb(250,250,250)',
        '&:hover': {
          // boxShadow: 'none',
          backgroundColor: 'rgb(250,250,250)',
          // color: primaryColor,
        },
      },
      textPrimary: {
        color: 'black',
      },
    },
    MuiDialogActions: {
      spacing: {
        '&> * + *': {
          marginLeft: '20px',
        },
      },
    },
    MuiListItemText: {
      root: {
        fontWeight: 'bold !important',
      },
      primary: {
        fontSize: '.95rem',
        color: '#333333',
      },
      secondary: {
        fontSize: '.8rem',
      },
    },
    MuiTableCell: {
      root: {
        borderBottomWidth: 0,
      },
      head: {
        fontWeight: 'bold',
        color: textColor,
      },
      sizeSmall: {
        padding: '2px 24px 2px 16px',
      },
    },
    MuiInput: {
      underline: {
        '&:after': {
          borderBottomColor: secondaryColor,
        },
        '&:before': {
          borderBottomStyle: 'solid !important',
        },
      },
    },
    MuiSlider: {
      thumb: {
        height: '21px',
        width: '21px',
        marginTop: '-8px',
      },
      track: {
        height: '4px',
      },
      rail: {
        height: '4px',
      },
    },
    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: 'transparent',
          color: primaryColor,
        },
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        minHeight: 'auto !important',
        padding: '0 10px',
      },
      content: {
        margin: '0 !important',
        '&.Mui-expanded': {
          margin: 0,
        },
      },
    },
    MuiExpansionPanel: {
      root: {
        '&.Mui-expanded': {
          margin: 0,
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: primaryColor,
        fontSize: '.75rem',
      },
    },
    MuiPickersDay: {
      daySelected: {
        '&:hover': {
          color: 'white !important',
        },
      },
    },
    MuiFormControl: {
      root: {
        margin: '12px 0 0',
        display: 'block',
      },
    },
  },

  props: {
    MuiButtonBase: {
      // The default props to change
      disableRipple: true, // No more ripple, on the whole application 💣!
    },
  },
})
