import React, { forwardRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { difference, dissoc, pathOr } from 'ramda'
import MaterialTable from 'material-table'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import Edit from '@material-ui/icons/Edit'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'

import { firestore } from '../../firebase'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarBody from '../../components/SnackbarBody'

const tableIcons = {
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}

const Title = styled(DialogTitle)`
  color: white !important;
  background-color: #7f9e5d;
  padding-right: 16px !important;
  h2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`
const ManageUserDialog = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    max-width: 1200px;
  }
  .MuiPaper-root {
    padding: 0;
  }
  .MuiCheckbox-root {
    margin-bottom: 4px;
    margin-left: -12px;
  }
  .MuiInputLabel-root {
    opacity: 0.6;
  }
`
const CloseModalIcon = styled(CloseIcon)`
  cursor: pointer;
`
const ActionsWrapper = styled.div`
  padding: 8px 15px;
`
const TableWrapper = styled.div`
  + div {
    margin-left: 20px;
  }
`
const TableTitle = styled.div`
  font-size: 1.2rem;
  color: black;
  opacity: 0.9;
  margin-bottom: 7px;
`

const ManageUsersModal = ({ onCancel, onSuccess, license, users = [], allUsers = [] }) => {
  const { t } = useTranslation()
  const [licensees, setLicensees] = useState(users.sort().map(user => ({ name: user })))
  const [otherUsers, setOtherUsers] = useState(allUsers.filter(user => !users.includes(user.name)))
  const [error, setError] = useState(null)

  const onOk = async () => {
    console.log('license', license)
    const newUsers = licensees.map(licensee => licensee.name)
    const addedUsers = difference(newUsers, users)
    const removedUsers = difference(users, newUsers)

    try {
      for (const email of addedUsers) {
        const userRef = firestore.doc(`users/${email}`)
        const snapshot = await userRef.get()
        const { licenseIds, licenseMap } = snapshot.data()
        await userRef.update({ licenseIds: [...licenseIds, license.id], licenseMap: { ...licenseMap, [license.id]: license.name } })
      }

      for (const email of removedUsers) {
        const userRef = firestore.doc(`users/${email}`)
        const snapshot = await userRef.get()
        const { licenseIds, licenseMap } = snapshot.data()
        await userRef.update({ licenseIds: licenseIds.filter(id => id !== license.id), licenseMap: dissoc(license.id, licenseMap) })
      }

      onSuccess(
        license.id,
        licensees.map(user => user.name),
      )
    } catch (e) {
      setError(pathOr('An error occurred', ['message'], e))
    }
  }

  const commonTableOptions = {
    search: true,
    pageSize: 10,
    pageSizeOptions: [5, 10, 20],
    padding: 'dense',
    headerStyle: {
      display: 'none',
    },
    cellStyle: {
      padding: '4px 0',
      color: 'black',
      opacity: 0.8,
    },
    searchFieldAlignment: 'left',
  }

  const licenseesColumns = [
    {
      title: 'user',
      field: 'name',
      cellStyle: cellData => (!users.includes(cellData) ? { fontWeight: 'bold', ...commonTableOptions.cellStyle } : commonTableOptions.cellStyle),
    },
  ]
  const usersColumns = [
    {
      title: 'user',
      field: 'name',
      cellStyle: cellData => (users.includes(cellData) ? { fontWeight: 'bold', ...commonTableOptions.cellStyle } : commonTableOptions.cellStyle),
    },
  ]

  return (
    <>
      <ManageUserDialog open onClose={onCancel}>
        <Title>
          <span style={{ paddingRight: '1rem' }}>{t('licenseManager.usersModal.title', { license: license.name })}</span>{' '}
          <CloseModalIcon onClick={onCancel} />
        </Title>
        <DialogContent style={{ minWidth: '25rem', paddingTop: '20px' }}>
          <div style={{ display: 'flex' }}>
            <TableWrapper>
              <TableTitle>{t('licenseManager.usersModal.licensees')}</TableTitle>

              <MaterialTable
                title=""
                icons={tableIcons}
                columns={licenseesColumns}
                data={licensees}
                options={commonTableOptions}
                actions={[
                  {
                    icon: () => <Clear fontSize="small" />,
                    tooltip: t('licenseManager.usersModal.removeFromLicense'),
                    onClick: (event, rowData) => {
                      setLicensees(prevState => prevState.filter(user => user.name !== rowData.name))
                      setOtherUsers(prevState => [rowData, ...prevState])
                    },
                  },
                ]}
                localization={{
                  header: {
                    actions: '',
                  },
                  body: {
                    emptyDataSourceMessage: t('licenseManager.usersModal.noLicenseesFound'),
                  },
                }}
              />
            </TableWrapper>
            <TableWrapper>
              <TableTitle>{t('licenseManager.usersModal.otherUsers')}</TableTitle>
              <MaterialTable
                title=""
                icons={tableIcons}
                columns={usersColumns}
                data={otherUsers}
                options={commonTableOptions}
                actions={[
                  {
                    icon: () => <ArrowBackIcon fontSize="small" />,
                    tooltip: t('licenseManager.usersModal.addToLicense'),
                    onClick: (event, rowData) => {
                      setLicensees(prevState => [rowData, ...prevState])
                      setOtherUsers(prevState => prevState.filter(user => user.name !== rowData.name))
                    },
                  },
                ]}
                localization={{
                  header: {
                    actions: '',
                  },
                  body: {
                    emptyDataSourceMessage: t('licenseManager.usersModal.noUsersFound'),
                  },
                }}
              />
            </TableWrapper>
          </div>
        </DialogContent>
        <ActionsWrapper>
          <DialogActions>
            <Button onClick={onCancel} color="primary">
              {t('common.CANCEL')}
            </Button>
            <Button onClick={onOk} variant="contained" color="secondary">
              {t('common.SAVE')}
            </Button>
          </DialogActions>
        </ActionsWrapper>
      </ManageUserDialog>

      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={error}
        onClose={() => setError(false)}
      >
        <SnackbarBody onClose={() => setError(false)} variant="error" message={error} />
      </Snackbar>
    </>
  )
}

export default ManageUsersModal
