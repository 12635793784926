import React, { forwardRef, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { clone, findIndex } from 'ramda'
import { format } from 'date-fns'
import Container from '@material-ui/core/Container'

import NavBar from '../../components/NavBar'
import { firebaseAuth, firestore } from '../../firebase'
import MaterialTable from 'material-table'

import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import ManageUsersModal from './ManageUsersModal'
import CreateLicenseModal from './CreateLicenseModal'
import { primaryColor } from '../../theme'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import Tooltip from '@material-ui/core/Tooltip'
import EditLicenseModal from './EditLicenseModal'

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}

const Wrapper = styled.div`
  .MuiTypography-h6 {
    color: ${primaryColor};
    font-size: 1.5rem;
  }
`
const ManageUsersIcon = styled(OpenInNewIcon)`
  cursor: pointer;
  font-size: 1.1rem !important;
  position: relative;
  bottom: -1px;
`

const Licenses = () => {
  const [license, setLicense] = useState({})
  const [showingEditModal, showEditModal] = useState(false)
  const [showingManageUsersModal, showManageUsersModal] = useState(false)
  const [showingCreateModal, showCreateModal] = useState(false)
  const [licenses, setLicenses] = useState([])
  const [roles, setRoles] = useState([])
  const [allUsers, setAllUsers] = useState([])
  const [userMap, setUserMap] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged(user => {
      if (user) {
        firestore
          .doc(`users/${user.email.toLowerCase()}`)
          .get()
          .then(doc => {
            if (doc.exists) {
              const { roles } = doc.data()
              setRoles(roles || [])

              if (roles.includes('admin')) {
                const licensesRef = firestore.collection(`data`)
                licensesRef.get().then(snapshot => {
                  setLicenses(snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })))
                  setLoading(false)
                })
                const usersRef = firestore.collection(`users`)
                usersRef.get().then(snapshot => {
                  setAllUsers(snapshot.docs.map(doc => ({ name: doc.id })))
                  const users = {}
                  snapshot.docs.forEach(doc => {
                    const { licenseIds } = doc.data()
                    if (licenseIds) {
                      licenseIds.forEach(licenseId => (users[licenseId] = users[licenseId] ? [...users[licenseId], doc.id] : [doc.id]))
                    }
                  })
                  setUserMap(users)
                })
              }
            }
          })
      }
    })

    return () => unsubscribe()
  }, [])

  const manageUsers = license => {
    setLicense(license)
    showManageUsersModal(true)
  }

  const onEditSuccess = license => {
    showEditModal(false)
    const clones = clone(licenses)
    const index = findIndex(clone => clone.id === license.id, clones)
    clones[index] = license
    setLicenses(clones)
  }

  const onCreateSuccess = license => {
    showCreateModal(false)
    setLicenses(prevState => [license, ...prevState])
  }

  const onManageUsersSuccess = (licenseCode, users) => {
    setUserMap(prevState => ({ ...prevState, [licenseCode]: users }))
    showManageUsersModal(false)
  }

  const columns = [
    { title: 'name', field: 'name' },
    { title: 'type', field: 'type' },
    { title: 'tokens', field: 'tokenCount' },
    {
      title: 'expires',
      render: rowData => format(rowData.expires.toDate(), 'dd MMM yyyy'),
      customSort: (a, b) => a.expires.seconds - b.expires.seconds,
    },
    {
      title: 'users',
      render: rowData => {
        return (
          <div style={{ width: '3.5rem', display: 'flex', justifyContent: 'space-between' }}>
            <span>{userMap[rowData.id] ? userMap[rowData.id].length : 0}</span>
            <Tooltip title="manage users" enterDelay={500}>
              <ManageUsersIcon onClick={() => manageUsers(rowData)} />
            </Tooltip>
          </div>
        )
      },
      customSort: (a, b) => a.licenseIds.length - b.licenseIds.length,
    },
  ]

  return (
    <Wrapper>
      <NavBar />
      {roles.includes('admin') && (
        <Container maxWidth="md" style={{ marginTop: '2rem' }}>
          <MaterialTable
            title="License manager"
            icons={tableIcons}
            columns={columns}
            data={licenses}
            isLoading={loading}
            options={{
              search: true,
              pageSize: 10,
              pageSizeOptions: [10, 20, 50],
              padding: 'dense',
              headerStyle: {
                padding: '15px 0 0',
                color: 'black',
              },
              rowStyle: {
                borderBottom: '1px solid #eee',
              },
              cellStyle: {
                padding: '12px 0',
              },
            }}
            actions={[
              {
                icon: () => <Edit fontSize="small" />,
                tooltip: 'Update license',
                onClick: (event, rowData) => {
                  setLicense(rowData)
                  showEditModal(true)
                },
              },
              {
                icon: () => <tableIcons.Add />,
                tooltip: 'Add License',
                isFreeAction: true,
                onClick: event => showCreateModal(true),
              },
            ]}
            localization={{
              header: {
                actions: '',
              },
              body: {
                emptyDataSourceMessage: loading ? '' : 'No licenses found',
              },
            }}
          />
        </Container>
      )}

      {showingManageUsersModal && license && (
        <ManageUsersModal
          license={license}
          onCancel={() => showManageUsersModal(false)}
          users={userMap[license.id]}
          allUsers={allUsers}
          onSuccess={onManageUsersSuccess}
        />
      )}

      {showingEditModal && license && <EditLicenseModal license={license} onCancel={() => showEditModal(false)} onSuccess={onEditSuccess} />}
      {showingCreateModal && license && <CreateLicenseModal onCancel={() => showCreateModal(false)} onSuccess={onCreateSuccess} />}
    </Wrapper>
  )
}

export default Licenses
