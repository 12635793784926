export default {
  translation: {
    home: {
      intro:
        'Onze HoTT database bevat meer dan {{hours}} uren aan metingen, verzameld over meerdere jaren en netjes gegroepeerd volgens o.a. sector, discipline, department en verspreid over verschillende regios en landen.',
      header: 'Hands on Tool Time benchmark',
    },
    charts: {
      labels: {
        avoidable: {
          yes: 'Vermijdbaar',
          no: 'Niet Vermijdbaar',
        },
        category: {
          dp: 'Direct Productief',
          ip: 'Indirect Productief',
          np: 'Niet Productief',
          na: 'Niet Gespecifieerd',
          dp1: 'PRODUCTIEF',
          ip2: 'DISCUSSIES',
          ip3: 'WACHTEN INTERN',
          ip4: 'GEREEDSCHAP',
          ip5: 'MATERIAAL',
          ip6: 'VERGUNNING',
          ip7: 'WACHTEN EXTERN',
          ip8: 'TRANSPORT',
          ip9: 'OPONTHOUD INSTALLATIE',
          ip10: 'GEGEVENS',
          ip11: 'BIJEENKOMST VEILIGHEID',
          ip12: 'BIJEENKOMST ALGEMEEN',
          ip13: 'PERSOONL VERZORGING',
          np14: 'TE VROEG',
          np15: 'OPONTHOUD BUITENAF',
          np16: 'EXTRA PAUZE',
          pz1: 'GEPLANDE PAUZE',
          'dp1-1': 'ON SITE',
          'dp1-2': 'WERKPLAATS',
          'dp1-3': 'EXTRA',
          'dp1-4': 'INRICHTEN WERKPLEK',
          'dp1-5': 'HOUSEKEEPING',
          'ip2-1': 'OVERHANDIGEN WO',
          'ip2-2': 'MET LEIDING',
          'ip2-3': 'ONDERLING',
          'ip2-4': 'LMRA',
          'ip4-1': 'GEREEDSCHAP ONTBREEKT',
          'ip4-2': 'GEREEDSCHAP MAGAZIJN',
          'ip5-1': 'MATERIAAL ONTBREEKT',
          'ip5-2': 'MATERIAAL MAGAZIJN',
          'ip7-1': 'PROCES VEILIGSTELLEN',
          'ip7-2': 'TECHN VEILIGSTELLEN',
          'ip7-3': 'BRANDWACHT WACHTEN',
          'ip7-4': 'REININGING WACHTEN',
          'ip7-5': 'ISOLEREN WACHTEN',
          'ip7-6': 'STEIGER WACHTEN',
          'ip7-7': 'HIJSEN, TRANSPORT',
          'ip7-8': 'ONTGRENDELING WACHTEN',
          'ip7-9': 'INBEDRIJFNAME',
          'ip8-1': 'TRANSPORTTIJD',
          'ip8-2': 'HALEN GER / MATERIAAL',
          'ip9-1': 'EQUIP NIET  BESCHIKB.',
          'ip9-2': 'ONVOORZIEN',
          'ip10-1': 'VERZAMELEN DOCUMENTEN',
          'ip10-2': 'KENNIS ONTBREEKT',
          'ip10-3': 'ONDUIDELIJKE DOCUMENTEN',
          'ip10-4': 'ADMIN WERKZ.HEDEN',
          'ip10-5': 'RAPPORTAGE',
          'ip10-6': 'WERKVOORBEREIDING',
          'ip11-1': 'ALGEM VEILIGH INSTR',
          'ip11-2': 'SPECIFIEKE VEILIGH INST',
        },
      },
      benchmark: 'Benchmark',
      activities: 'activiteiten',
      hours: 'uren',
      hourCount: 'Aantal uren',
      activityCount: 'Aantal activiteiten',
      ownMeasurements: 'Eigen metingen',
      hottDatabase: 'HoTT Database',
      options: {
        count: 'aantal',
        time: 'tijd',
      },
      legends: {
        time: 'Tijd (uren)',
        count: 'Aantal (activiteiten)',
      },
      legendsBenchmark: {
        time: 'Tijd (percentage)',
        count: 'Aantal (percentage)',
      },
      sortCriteria: {
        category: 'categorie',
        amount: 'aantal',
        avoid: 'vermijdbaarheid',
      },
      titles: {
        prod: 'Productiviteits grafiek',
        avoid: 'Vermeidbaarheids grafiek',
        combo: 'Combo grafiek',
        level: 'Level',
      },
      configDialog: {
        header: 'Configuratie Grafiek',
        title: 'Titel',
        maxY: 'Maximum waarde Y-as',
        hideValues: 'Verberg waarden in grafiek',
        downloadTransparent: 'Download grafiek met doorzichtige achtergrond',
        secondsUnit: 'Tijdsmeting in seconden',
        showZeroValues: '0-waarden tonen',
        categories: 'categorieën',
        subcategories: 'subcategorieën',
      },
    },
    options: {
      country: {
        BE: 'Belgium',
        NL: 'Netherlands',
        FR: 'France',
        DE: 'Germany',
        IT: 'Italy',
        LU: 'Luxembourg',
      },
      language: {
        en: 'Engels',
        nl: 'Nederlands',
        de: 'Duits',
        fr: 'Frans',
      },
      category: {
        dp1: 'PRODUCTIEF',
        ip2: 'DISCUSSIES',
        ip3: 'WACHTEN INTERN',
        ip4: 'GEREEDSCHAP',
        ip5: 'MATERIAAL',
        ip6: 'VERGUNNING',
        ip7: 'WACHTEN EXTERN',
        ip8: 'TRANSPORT',
        ip9: 'OPONTHOUD INSTALLATIE',
        ip10: 'GEGEVENS',
        ip11: 'BIJEENKOMST VEILIGHEID',
        ip12: 'BIJEENKOMST ALGEMEEN',
        ip13: 'PERSOONL VERZORGING',
        np14: 'TE VROEG',
        np15: 'OPONTHOUD BUITENAF',
        np16: 'EXTRA PAUZE',
        pz1: 'GEPLANDE PAUZE',
      },
      subcategory: {
        'dp1-1': 'ON SITE',
        'dp1-2': 'WERKPLAATS',
        'dp1-3': 'EXTRA',
        'dp1-4': 'INRICHTEN WERKPLEK',
        'dp1-5': 'HOUSEKEEPING',
        'ip2-1': 'OVERHANDIGEN WO',
        'ip2-2': 'MET LEIDING',
        'ip2-3': 'ONDERLING',
        'ip2-4': 'LMRA',
        'ip4-1': 'GEREEDSCHAP ONTBREEKT',
        'ip4-2': 'GEREEDSCHAP MAGAZIJN',
        'ip5-1': 'MATERIAAL ONTBREEKT',
        'ip5-2': 'MATERIAAL MAGAZIJN',
        'ip7-1': 'PROCES VEILIGSTELLEN',
        'ip7-2': 'TECHN VEILIGSTELLEN',
        'ip7-3': 'BRANDWACHT WACHTEN',
        'ip7-4': 'REININGING WACHTEN',
        'ip7-5': 'ISOLEREN WACHTEN',
        'ip7-6': 'STEIGER WACHTEN',
        'ip7-7': 'HIJSEN, TRANSPORT',
        'ip7-8': 'ONTGRENDELING WACHTEN',
        'ip7-9': 'INBEDRIJFNAME',
        'ip8-1': 'TRANSPORTTIJD',
        'ip8-2': 'HALEN GER / MATERIAAL',
        'ip9-1': 'EQUIP NIET  BESCHIKB.',
        'ip9-2': 'ONVOORZIEN',
        'ip10-1': 'VERZAMELEN DOCUMENTEN',
        'ip10-2': 'KENNIS ONTBREEKT',
        'ip10-3': 'ONDUIDELIJKE DOCUMENTEN',
        'ip10-4': 'ADMIN WERKZ.HEDEN',
        'ip10-5': 'RAPPORTAGE',
        'ip10-6': 'WERKVOORBEREIDING',
        'ip11-1': 'ALGEM VEILIGH INSTR',
        'ip11-2': 'SPECIFIEKE VEILIGH INST',
      },
      department: {
        maintenance: 'Maintenance',
        production: 'Productie',
        other: 'Anders',
      },
      sector: {
        chemical: 'Chemie',
        oil_gas: 'Oil & Gas',
        power: 'Power',
        heavy_industry: 'Heavy Industry',
        manufacturing: 'Maak Industrie',
        food_beverage: 'Food & Beverage',
        automotive: 'Automotive',
        pharma: 'Pharma',
        public_transport: 'Openbaar Vervoer',
        logistics: 'Logistiek',
        other: 'Anders',
      },
      discipline: {
        mechanical: 'WTB',
        ei: 'EI',
        scaffolding: 'Steigerbouw',
        cleaning: 'Reiniging',
        pb: 'PB',
        insulation: 'Isolatie',
        piping: 'Piping',
        civil: 'Civiel',
        painting: 'Schilderen',
        other: 'Anders',
      },
      contractType: {
        unit: 'Unit rate',
        hourly: 'Hourly rate',
        fixed_price: 'Fixed price',
      },
      resource: {
        own: 'Eigen Staf',
        internal: 'Interne Contractor',
        external: 'Externe Contractor',
        service: 'Service Provider',
      },
      workType: {
        regular_planned: 'Gepland',
        regular_unplanned: 'Ongepland',
        project_tar: 'TAR',
        project: 'Project',
      },
      shift: {
        day: 'Dag',
        shift: 'Ploegen',
      },
      workshop: {
        centralized: 'Centraal',
        decentralized: 'Decentraal',
      },
    },
    common: {
      YES: 'JA',
      OK: 'OK',
      SAVE: 'OK',
      NO: 'NEE',
      CANCEL: 'ANNULEER',
      required: 'verplicht veld',
      year: 'jaar',
    },
    navigation: {
      myProjects: 'mijn projecten',
      signIn: 'Aanmelden',
      signOut: 'Afmelden',
    },
    accountInfo: {
      accountInfoTitle: 'Account info',
      licenseInfoTitle: 'Licentie info',
      licenseType: 'Type',
      tokensLeft: 'Resterende metingen',
      expire: 'Geldig tot',
      users: 'Gebruikers',
      licenseTypes: {
        '1star': '1 ster',
        '2star': '2 ster',
        '3star': '3 ster',
        training: 'training',
      },
    },
    project: {
      create: {
        title: 'Nieuw project',
        location: 'locatie',
        name: 'project naam',
        client: 'klant',
        country: 'land',
      },
      overview: {
        title: 'HoTT Projecten',
      },
      delete: {
        title: 'Project verwijderen',
        askConfirmation: 'Bent u zeker dat u het geselcteerd project wilt verwijderen?',
      },
    },
    measurement: {
      rename: {
        title: 'Hernoemen',
      },
      viewMode: {
        chart: 'Grafiek',
        details: 'Details',
      },
      benchmark: {
        filter: {
          all: 'Alle',
          none: 'Geen',
        },
      },
      project: 'Project',
      department: 'department',
      sector: 'sector',
      discipline: 'discipline',
      contractType: 'contract type',
      resource: 'resource',
      workType: 'werk type',
      shift: 'shift',
      workshop: 'werkplaats',
      tabs: {
        open: 'open',
        cloud: 'cloud',
      },
      create: {
        title: 'Nieuwe meting',
        name: 'naam meting',
      },
      overview: {
        title: 'Metingen',
      },
      upload: {
        title: 'Upload',
        askConfirmation: {
          areYouSure: 'Bent u zeker dat u deze meting definitief wilt uploaden naar de WCM databank?',
          noMoreChanges: 'Hierna kunt u de meting niet meer aanpassen en wordt deze na validatie door WCM opgenomen in de benchmark.',
          benchmark: '',
          tokensLeft: 'Met uw huidige licentie kunt u nog {{count}} meting uploaden.',
          tokensLeft_plural: 'Met uw huidige licentie kunt u nog {{count}} metingen uploaden.',
        },
        success: 'Meting geüpload',
        offline: 'U lijkt offline te zijn. Gelieve uw internet connectie te controleren en opnieuw te proberen.',
        error: 'De upload is niet gelukt. Gelieve later nog eens te proberen',
        noLicense: 'Geen licentie informatie beschikbaar.',
        noTokensLeft: 'Uw licentie heeft geen tokens meer.',
        licenseExpired: 'Uw licentie is vervallen op {{expireDate}}',
      },
      stop: {
        title: 'Meting beëindigen',
        askConfirmation:
          'Bent u zeker dat u deze meting definitief wilt beëindigen? Hierna kunnen geen nieuwe activiteiten worden toegevoegd aan de meting.',
      },
      delete: {
        title: 'Meting verwijderen',
        askConfirmation: 'Bent u zeker dat u de geselecteerde meting wilt verwijderen?',
      },
      chart: {
        title: 'Statistieken',
        legend: {
          types: {
            dp: 'direct productief',
            ip: 'indirect productief',
            np: 'niet productief',
            na: 'niet gespecifieerd',
          },
          avoidable: {
            yes: 'Vermijdbaar',
            no: 'Niet Vermijdbaar',
            na: 'niet gespecifieerd',
          },
        },
        productivity: 'productiviteit',
        avoidability: 'vermijdbaarheid',
        combo: 'combo',
        actions: {
          downloadData: 'data downloaden',
          downloadChart: 'grafiek downloaden',
        },
      },
    },
    activity: {
      remark: 'opmerking',
      category: 'categorie',
      subcategory: 'subcategorie',
      overview: {
        title: 'Activiteiten',
        stop: 'STOP',
      },
      create: {
        title: 'Nieuwe activiteit',
        titleExisting: 'Activiteit',
        takePhoto: 'FOTO NEMEN',
        avoidable: 'Vermijdbaar',
        begin: 'Begin',
        end: 'Einde',
        duration: 'Duur',
      },
      duration: {
        title: 'Duur aanpassen',
      },
      split: {
        title: 'Activiteit splitsen',
        current: 'Huidige activiteit',
        new: 'Nieuwe activiteit',
      },
      delete: {
        title: 'Activiteit verwijderen',
        askConfirmation: 'Ben je zeker dat je de geselecteerde activiteit wilt verwijderen?',
      },
    },
    license: {
      title: 'HoTT Licentie',
      tokens: 'tokens',
    },
    error: {
      header: 'Hands on Tool Time',
      title: 'Een fout is opgetreden',
      feedback: 'feedback',
    },
    menu: {
      signOut: 'Uitloggen',
      accountInfo: 'Account info',
      about: 'Over HoTT',
      settings: 'Instellingen',
      switchLicense: 'Licentie kiezen',
    },
    settings: {
      title: 'Instellingen',
      language: 'taal',
    },
  },
}
