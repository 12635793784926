import React from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import { primaryColor } from '../../theme'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core'
import SelectField from '../../components/form/SelectField'

const REASONS = ['OUT_OF_BOUNDS', 'TOO_SHORT', 'TRAINING']

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: '12px 0',
  },
  menuItem: {
    backgroundColor: 'transparent !important',
  },
}))

const RejectConfirmationModal = ({ reason, onCancel, onOk }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Formik
      initialValues={{
        reason: null,
      }}
      validationSchema={Yup.object({
        reason: Yup.string()
          .nullable()
          .required('Required'),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        onOk(values.reason)
        setSubmitting(false)
      }}
    >
      <Dialog open onClose={onCancel} maxWidth="sm">
        <DialogTitle>
          <span style={{ color: primaryColor, fontWeight: 'bold', paddingLeft: '7px' }}>{t('quarantine.rejectModal.title')}</span>
        </DialogTitle>
        <Form noValidate>
          <DialogContent style={{ minWidth: '30rem', padding: '0 2rem' }}>
            {t('quarantine.rejectModal.description')}

            <FormControl className={classes.formControl}>
              <SelectField name="reason" fullWidth label={t('quarantine.rejectModal.reason')} required>
                {REASONS.map(reason => (
                  <MenuItem value={reason} key={reason} className={classes.menuItem}>
                    {t(`quarantine.declineReason.${reason}`)}
                  </MenuItem>
                ))}
              </SelectField>
            </FormControl>
          </DialogContent>
          <DialogActions style={{ paddingRight: '2rem', marginTop: '10px' }}>
            <Button onClick={onCancel} color="primary">
              {t('common.CANCEL')}
            </Button>
            <Button type="submit" variant="contained" color="secondary">
              {t('common.OK')}
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </Formik>
  )
}

export default RejectConfirmationModal
