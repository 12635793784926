import React, { useEffect, useState } from 'react'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import styled from '@emotion/styled'
import firebase from 'firebase/app'
import { keys } from 'ramda'

import { useAuth } from '../hooks/useAuth'
import { Container, makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { firebaseAuth, firestore } from '../firebase'
import NavBar from '../components/NavBar'
import { useLicense } from '../hooks/useLicense'

const { Timestamp } = firebase.firestore

const uiConfig = {
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/',
  immediateFederatedRedirect: true,
  signInOptions: [firebase.auth.EmailAuthProvider.PROVIDER_ID],
  credentialHelper: 'none',
}
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  appBar: {
    minHeight: 200,
    backgroundImage: 'url(hero.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '0 -160px',
  },
  toolbar: {
    marginTop: 20,
    marginRight: 20,
    minHeight: 128,
    alignItems: 'flex-start',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    alignSelf: 'flex-end',
  },
}))

const SignInContainer = styled.div`
  margin-top: 12rem;
`
const Home = () => {
  const auth = useAuth()
  const { setSelectedLicense, setLicenses } = useLicense()
  const [shouldShowSignIn, showSignIn] = useState(false)
  const [loading, setLoading] = useState(true)

  console.log('==**********', firebaseAuth.currentUser)
  useEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged(user => {
      setLoading(false)
      if (user) {
        firestore
          .doc(`users/${user.email.toLowerCase()}`)
          .get()
          .then(doc => {
            if (doc.exists) {
              const licenseIds = doc.data().licenses || []
              const { selectedLicense, licenseMap } = doc.data()
              const licenses = keys(licenseMap).map(id => ({ id, name: licenseMap[id] }))
              setSelectedLicense(selectedLicense)
              setLicenses(licenses)
              // console.log('licenseInfo', licenseInfo)
              //         if (language && language !== i18n.language && LANGUAGES.includes(language)) {
              //           i18n.changeLanguage(language)
              //         }
              //         if (selectedLicense && licenseIds.includes(selectedLicense) && !switchLicense) {
              //           navigate('/licenses/' + selectedLicense + '/projects', { replace: true })
              //         } else if (licenseIds.length === 1) {
              //           navigate('/licenses/' + licenseIds[0] + '/projects', { replace: true })
              //         } else {
              //           navigate('/licenses', { state: { licenseIds }, replace: true })
              //         }
            }
          })
      }
    })

    return () => unsubscribe()
  }, [])

  // useEffect(() => {
  //   if (auth.user) {
  //     // firestore          .doc(`users/${user.email.toLowerCase()}`)
  //     console.log('===', auth.user)
  //
  //     try {
  //       firestore
  //         .doc('test/-KYLzrXIWVMQ4XKCIywb')
  //         .get()
  //         .then(snapshot => {
  //           console.log('HOHO', snapshot.data())
  //         })
  //         .catch(e => {
  //           console.log('ERROR6654654654', e)
  //         })
  //     } catch (e) {
  //       console.log('ERROR', e)
  //     }
  //   }
  // }, [auth.user])

  const sendInviteLink = () => {
    setLoading(true)
    console.log('SENDING LINK.....')
    firebaseAuth
      .sendSignInLinkToEmail('svdwyer@gmail.com', {
        url: 'https://hott-pwa.firebaseapp.com',
        handleCodeInApp: true,
      })
      .then(function() {
        setLoading(false)
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        // window.localStorage.setItem('emailForSignIn', email);
        console.log('HHHJHJOHIOHJUOHLJLJ:HIKJLH:KLJK:JKLJKLJLK')
      })
      .catch(function(error) {
        setLoading(false)
        console.log('SOMEHING WHEN WRONG', error)
        // Some error occurred, you can inspect the code: error.code
      })
  }

  return (
    <>
      <NavBar showLogin={shouldShowSignIn} onClickSignIn={() => shouldShowSignIn(true)} />

      <Container>
        {!loading && !firebaseAuth.currentUser && (
          <SignInContainer>
            <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebaseAuth} />
          </SignInContainer>
        )}

        {!loading && (
          <Button variant="contained" color="secondary" onClick={sendInviteLink}>
            send invite link
          </Button>
        )}
      </Container>
    </>
  )
}

export default Home
