import React from 'react'
import styled from '@emotion/styled'
import { CSVLink } from 'react-csv'
import { saveSvgAsPng } from 'save-svg-as-png'

import Button from '@material-ui/core/Button'
import EqualizerIcon from '@material-ui/icons/Equalizer'
import DonutSmallIcon from '@material-ui/icons/DonutSmall'
import Switch from '@material-ui/core/Switch'
import DescriptionIcon from '@material-ui/icons/Description'
import { makeStyles } from '@material-ui/core'
import PublishIcon from '@material-ui/icons/Publish'
import Tooltip from '@material-ui/core/Tooltip'
import SettingsIcon from '@material-ui/icons/Settings'
import { useTranslation } from 'react-i18next'

const OptionsBar = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 1rem 2rem;
`
const DownloadIcon = styled(PublishIcon)`
  transform: rotate(180deg);
`
const useStyles = makeStyles(theme => ({
  iconButton: {
    borderRadius: '50%',
    padding: '12px',
  },
}))

const saveChart = (tabIndex, chartTitle, transparent) => {
  var svg = document.querySelector(`#chart${tabIndex} svg`)
  saveSvgAsPng(svg, `${chartTitle || 'chart'}.png`, { backgroundColor: transparent ? undefined : 'white' })
}

const ChartOptionsBottom = ({
  chart,
  level,
  chartType,
  onChangeChartType,
  onChangeBenchmark,
  benchmarkActive,
  csvData,
  tabIndex,
  chartTitle,
  onShowChartOptions,
  transparentBackground,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <OptionsBar>
      <div>
        {chart !== 'combo' && level === 1 && (
          <>
            <Button variant={chartType === 'bar' ? undefined : 'contained'} onClick={() => onChangeChartType('bar')} className={classes.iconButton}>
              <EqualizerIcon />
            </Button>
            {!benchmarkActive && (
              <Button variant={chartType === 'pie' ? undefined : 'contained'} onClick={() => onChangeChartType('pie')} className={classes.iconButton}>
                <DonutSmallIcon />
              </Button>
            )}
          </>
        )}
        <Button variant={'contained'} onClick={() => onShowChartOptions(true)} className={classes.iconButton}>
          <SettingsIcon />
        </Button>
      </div>
      <div>
        {chart === 'prod' && chartType === 'bar' && level !== 3 && (
          <>
            <Switch checked={benchmarkActive} onChange={e => onChangeBenchmark(e.target.checked)} value="avoidable" color="secondary" /> Benchmark
          </>
        )}
      </div>
      <div>
        <CSVLink data={csvData[tabIndex]} filename={`${chartTitle}.csv`} style={{ marginRight: '20px' }}>
          <Tooltip title={t('measurement.chart.actions.downloadData')} enterDelay={500}>
            <Button className={classes.iconButton} variant="contained">
              <DescriptionIcon />
            </Button>
          </Tooltip>
        </CSVLink>
        <Tooltip title={t('measurement.chart.actions.downloadChart')} enterDelay={500}>
          <Button className={classes.iconButton} variant="contained" onClick={() => saveChart(tabIndex, chartTitle, transparentBackground)}>
            <DownloadIcon />
          </Button>
        </Tooltip>
      </div>
    </OptionsBar>
  )
}

export default ChartOptionsBottom
