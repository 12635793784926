import { path, pathOr, sortBy } from 'ramda'
import { enGB, nl, de, fr } from 'date-fns/locale'
import { differenceInHours, differenceInMinutes, differenceInSeconds, format, startOfSecond } from 'date-fns'

export const timeDifference = (begin, end) => {
  const diffSeconds = differenceInSeconds(startOfSecond(end), startOfSecond(begin))
  const diffMinutes = differenceInMinutes(startOfSecond(end), startOfSecond(begin))
  const diffHours = differenceInHours(startOfSecond(end), startOfSecond(begin))
  let diff = ''
  if (diffHours > 0) {
    diff += diffHours + 'h '
  }
  if (diffMinutes > 0) {
    diff += (diffMinutes % 60) + 'm '
  }
  if (diffMinutes === 0) {
    diff += diffSeconds + 's'
  }
  return diff.trim()
}

export const getDayTimestamp = () => format(new Date(), 'yyyy-MM-dd')

const locales = { en: enGB, nl, de, fr }

export const formatDate = (date, formatStr, locale) => format(date, formatStr, { locale: locales[locale] })

export const hoursToHHMM = hours => {
  var hour = Math.floor(Math.abs(hours))
  var minutes = Math.floor((Math.abs(hours) * 60) % 60)
  var seconds = Math.floor((Math.abs(hours) * 3600) % 60)
  return hour === 0 && minutes === 0 ? seconds + 's' : hour + ':' + (minutes < 10 ? '0' : '') + minutes
}

const colors = {
  DP: '#228B22',
  DP_BENCH: '#98CEB4',
  IP: '#4169E1',
  IP_BENCH: '#B3D0FF',
  NP: '#D62D20',
  NP_BENCH: '#EFABA4',
}

export const type2color = (type, bench = false) => {
  switch (type.substr(0, 2)) {
    case 'dp':
      return bench ? colors.DP_BENCH : colors.DP
    case 'ip':
      return bench ? colors.IP_BENCH : colors.IP
    case 'np':
      return bench ? colors.NP_BENCH : colors.NP
    case 'na':
      return 'darkgray'
    default:
      return 'white'
  }
}

export const catList = [
  'dp1',
  'dp1-1',
  'dp1-2',
  'dp1-3',
  'dp1-4',
  'dp1-5',
  'ip2',
  'ip2-1',
  'ip2-2',
  'ip2-3',
  'ip2-4',
  'ip3',
  'ip4',
  'ip4-1',
  'ip4-2',
  'ip5',
  'ip5-1',
  'ip5-2',
  'ip6',
  'ip7',
  'ip7-1',
  'ip7-2',
  'ip7-3',
  'ip7-4',
  'ip7-5',
  'ip7-6',
  'ip7-7',
  'ip7-8',
  'ip7-9',
  'ip8',
  'ip8-1',
  'ip8-2',
  'ip9',
  'ip9-1',
  'ip9-2',
  'ip10',
  'ip10-1',
  'ip10-2',
  'ip10-3',
  'ip10-4',
  'ip10-5',
  'ip10-6',
  'ip11',
  'ip11-1',
  'ip11-2',
  'ip12',
  'ip13',
  'np14',
  'np15',
  'np16',
  'pz1',
]

const categoryType = category => {
  if (category && category.length > 2) {
    return category.substr(0, 2)
  }
  return null
}
export const generateStats = (activities, measurement) => {
  const level1TimeValues = {
    dp: 0,
    ip: 0,
    np: 0,
  }
  const level1CountValues = {
    dp: 0,
    ip: 0,
    np: 0,
  }
  const level2CountValues = {}
  const level2TimeValues = {}
  const level3CountValues = {}
  const level3TimeValues = {}
  const level1AvoidCountValues = {}
  const level1AvoidTimeValues = {}

  const level1ComboCountValues = {}
  const level1ComboTimeValues = {}
  const level2ComboCountValues = {}
  const level2ComboTimeValues = {}
  const level3ComboCountValues = {}
  const level3ComboTimeValues = {}

  const sortedActivities = sortBy(activity => activity.begin.seconds, activities)
  sortedActivities.forEach((activity, index) => {
    const type = categoryType(activity.category) || 'na'
    if (type.match(/dp|ip|np/)) {
      const end = index < sortedActivities.length - 1 ? sortedActivities[index + 1].begin : measurement.end
      const duration = end.seconds - activity.begin.seconds

      level1TimeValues[type] = (level1TimeValues[type] || 0) + duration
      level1CountValues[type] = (level1CountValues[type] || 0) + 1

      const avoidableKey = activity.avoidable ? 'yes' : 'no'
      level1AvoidTimeValues[avoidableKey] = (level1AvoidTimeValues[avoidableKey] || 0) + duration
      level1AvoidCountValues[avoidableKey] = (level1AvoidCountValues[avoidableKey] || 0) + 1

      level2CountValues[activity.category] = (level2CountValues[activity.category] || 0) + 1
      level2TimeValues[activity.category] = (level2TimeValues[activity.category] || 0) + duration

      const level3Key = activity.subcategory || activity.category // if there is no subcat, then we add the values to the main cat
      level3CountValues[level3Key] = (level3CountValues[level3Key] || 0) + 1
      level3TimeValues[level3Key] = (level3TimeValues[level3Key] || 0) + duration

      // level 1 combo
      const comboCountParent = pathOr({}, [type], level1ComboCountValues)
      const oldComboCountValue = pathOr(0, [type, avoidableKey], level1ComboCountValues)
      level1ComboCountValues[type] = { ...comboCountParent, [avoidableKey]: oldComboCountValue + 1 }
      const comboTimeParent = pathOr({}, [type], level1ComboTimeValues)
      const oldComboTimeValue = pathOr(0, [type, avoidableKey], level1ComboTimeValues)
      level1ComboTimeValues[type] = { ...comboTimeParent, [avoidableKey]: oldComboTimeValue + duration }

      // level 2 combo
      const level2ComboCountParent = pathOr({}, [activity.category], level2ComboCountValues)
      const level2OldComboCountValue = pathOr(0, [activity.category, avoidableKey], level2ComboCountValues)
      level2ComboCountValues[activity.category] = { ...level2ComboCountParent, [avoidableKey]: level2OldComboCountValue + 1 }
      const level2ComboTimeParent = pathOr({}, [activity.category], level2ComboTimeValues)
      const level2OldComboTimeValue = pathOr(0, [activity.category, avoidableKey], level2ComboTimeValues)
      level2ComboTimeValues[activity.category] = { ...level2ComboTimeParent, [avoidableKey]: level2OldComboTimeValue + duration }

      // level 3 combo
      const level3ComboCountParent = pathOr({}, [level3Key], level3ComboCountValues)
      const level3OldComboCountValue = pathOr(0, [level3Key, avoidableKey], level3ComboCountValues)
      level3ComboCountValues[level3Key] = { ...level3ComboCountParent, [avoidableKey]: level3OldComboCountValue + 1 }
      const level3ComboTimeParent = pathOr({}, [level3Key], level3ComboTimeValues)
      const level3OldComboTimeValue = pathOr(0, [level3Key, avoidableKey], level3ComboTimeValues)
      level3ComboTimeValues[level3Key] = { ...level3ComboTimeParent, [avoidableKey]: level3OldComboTimeValue + duration }
    }
  })

  return {
    level1: {
      prod: {
        time: level1TimeValues,
        count: level1CountValues,
      },
      avoid: {
        time: level1AvoidTimeValues,
        count: level1AvoidCountValues,
      },
      combo: {
        time: level1ComboTimeValues,
        count: level1ComboCountValues,
      },
    },
    level2: {
      prod: {
        time: level2TimeValues,
        count: level2CountValues,
      },
      combo: {
        time: level2ComboTimeValues,
        count: level2ComboCountValues,
      },
    },
    level3: {
      prod: {
        time: level3TimeValues,
        count: level3CountValues,
      },
      combo: {
        time: level3ComboTimeValues,
        count: level3ComboCountValues,
      },
    },
  }
}
