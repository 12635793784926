import React from 'react'
import { useField } from 'formik'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Checkbox from '@material-ui/core/Checkbox'

const CheckboxField = ({ label, wrapper: Wrapper = FormControl, ...props }) => {
  const [field, meta] = useField({ ...props, type: 'checkbox' })
  const error = meta.touched && meta.error
  return (
    <Wrapper error={error}>
      {/*<BaseInputField label={label} {...field} {...props} InputLabelProps={{ shrink: !isNil(field.value) && !isEmpty(field.value) }} error={error} />*/}
      <Checkbox {...field} {...props} /> {label}
      {error && <FormHelperText>{meta.error}</FormHelperText>}
    </Wrapper>
  )
}

export default CheckboxField
