import React, { useState, useEffect, useContext, createContext } from 'react'

const licenseContext = createContext()
// const history = useHistory()

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function LicenseProvider({ children }) {
  const license = useProviderLicense()
  return <licenseContext.Provider value={license}>{children}</licenseContext.Provider>
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useLicense = () => {
  return useContext(licenseContext)
}

// Provider hook that creates auth object and handles state
function useProviderLicense() {
  const [license, setSelectedLicense] = useState(null)
  const [licenses, setLicenses] = useState([])

  // // Subscribe to user on mount
  // // Because this sets state in the callback it will cause any ...
  // // ... component that utilizes this hook to re-render with the ...
  // // ... latest auth object.
  // useEffect(() => {
  //   const unsubscribe = firebaseAuth.onAuthStateChanged(user => {
  //     if (user) {
  //       setLicense(user)
  //     } else {
  //       console.log('*** NO LONGER AUTHENTICATED ***')
  //       setLicense(false)
  //       history.push(`/`)
  //     }
  //   })
  //
  //   return () => unsubscribe()
  // }, [])

  // Return the user object and auth methods
  return {
    license,
    setSelectedLicense,
    licenses,
    setLicenses,
  }
}
