export default {
  translation: {
    home: {
      intro:
        'Notre HoTT-database contient plus de {{hours}} heures de mesures. Elles sont rassemblées sur plusieurs d’années et classées par secteur, discipline et département sur différentes régions et pays.',
      header: 'Hands on Tool Time Benchmark',
    },
    charts: {
      labels: {
        avoidable: {
          yes: 'Évitable',
          no: 'Inévitable',
        },
        category: {
          dp: 'Directement Productif',
          ip: 'Indirectement Productif',
          np: 'Pas Productif',
          dp1: 'PRODUCTIF',
          ip2: 'DISCUSSION',
          ip3: 'ATTENDRE INTERNES',
          ip4: 'DES OUTILS',
          ip5: 'MATÉRIAUX',
          ip6: 'PERMIS',
          ip7: 'ATTENDRE EXTERNES',
          ip8: 'TRANSPORT',
          ip9: 'RETARD INSTALLATION',
          ip10: 'DONÉES',
          ip11: 'ASSEMBLÉES SÉCURITÉS',
          ip12: 'ASSEMBLÉES GÉNÉRALES',
          ip13: 'PERSONAL CARE',
          np14: 'TROP TÔT',
          np15: 'RETARD',
          np16: 'PAUSES EXTRA',
          pz1: 'PAUSE PRÉVUE',
          'dp1-1': 'SUR LE SITE',
          'dp1-2': 'ATELIER',
          'dp1-3': 'EXTRA',
          'dp1-4': 'ORGANISATION DES POSTES DE TRAVAIL',
          'dp1-5': 'NETTOYAGE',
          'ip2-1': 'REMETTRE ORDRES DE TRAVAIL',
          'ip2-2': 'GESTION',
          'ip2-3': 'ENTRE EUX',
          'ip2-4': 'LMRA',
          'ip4-1': 'AUCUN OUTI',
          'ip4-2': 'OUTIL MAGAZINE',
          'ip5-1': 'LE MATÉRIEL MANQUE',
          'ip5-2': 'MATÉRIAUX AU ENTREPÔT',
          'ip7-1': 'PROCESSUS DE SÉCURITÉ',
          'ip7-2': 'SÉCURITÉ TECHNIQUE',
          'ip7-3': 'SURVEILLANCE INCINDIE',
          'ip7-4': 'NETTOYAGE',
          'ip7-5': "ATTENDRE L'ISOLATION",
          'ip7-6': 'ATTENDRE ÉCHAFAUDAGE',
          'ip7-7': 'ÉLEVER / TRANSPORT',
          'ip7-8': 'DÉVERROUILLAGE',
          'ip7-9': 'MISE EN SERVICE',
          'ip8-1': 'TRANSPORT',
          'ip8-2': 'COLLECTE DES OUTILS',
          'ip9-1': 'ÉQUIP. NON DISPONIBLES',
          'ip9-2': 'IMPRÉVUES',
          'ip10-1': 'TROUVER DE DOCUMENTATION',
          'ip10-2': 'CONNAISSANCE',
          'ip10-3': 'DONNÉES',
          'ip10-4': 'ADMINISTRATION',
          'ip10-5': 'RÉDACTION',
          'ip10-6': 'PRÉPARATION DU TRAVAIL',
          'ip11-1': 'SÉCURITÉ GÉNÉRALE',
          'ip11-2': 'SÉCURITÉ PRÉCISE',
        },
      },
      benchmark: 'Benchmark',
      activities: 'activités',
      hours: 'heurs',
      hourCount: "Nombre d'heures",
      activityCount: "Nombre d'activités",
      ownMeasurements: 'Propres mesures',
      hottDatabase: 'HoTT Database',
      options: {
        count: 'total',
        time: 'temps',
      },
      legends: {
        time: 'Temps (heurs)',
        count: 'Total (activités)',
      },
      legendsBenchmark: {
        time: 'Temps (percentage)',
        count: 'Total (percentage)',
      },
      sortCriteria: {
        category: 'catégorie',
        amount: 'total',
        avoid: 'évidence',
      },
      titles: {
        prod: 'Tableau de productivité',
        avoid: "Tableau d'évitabilité",
        combo: 'Tableau du combo',
        level: 'Niveau',
      },
      configDialog: {
        header: 'Configuration du Graphique',
        title: 'Titre',
        maxY: "Valeur maximale de l'axe Y",
        hideValues: 'Masquer les valeurs dans le graphique',
        downloadTransparent: 'Télécharger le graphique avec fond transparent',
        secondsUnit: 'Mesure du temps en secondes',
        showZeroValues: 'Afficher les valeurs nulles',
        categories: 'catégories',
        subcategories: 'sous-catégories',
      },
    },
    options: {
      country: {
        BE: 'Belgique',
        NL: 'Pays-Bas',
        FR: 'France',
        DE: 'Allemagne',
        IT: 'Italie',
        LU: 'Luxembourg',
      },
      language: {
        en: 'Anglais',
        nl: 'Néerlandais',
        de: 'Allemand',
        fr: 'Français',
      },
      category: {
        dp1: 'PRODUCTIF',
        ip2: 'DISCUSSION',
        ip3: 'ATTENDRE INTERNES',
        ip4: 'DES OUTILS',
        ip5: 'MATÉRIAUX',
        ip6: 'PERMIS',
        ip7: 'ATTENDRE EXTERNES',
        ip8: 'TRANSPORT',
        ip9: 'RETARD INSTALLATION',
        ip10: 'DONÉES',
        ip11: 'ASSEMBLÉES SÉCURITÉS',
        ip12: 'ASSEMBLÉES GÉNÉRALES',
        ip13: 'PERSONAL CARE',
        np14: 'TROP TÔT',
        np15: 'RETARD',
        np16: 'PAUSES EXTRA',
        pz1: 'PAUSE PRÉVUE',
      },
      subcategory: {
        'dp1-1': 'SUR LE SITE',
        'dp1-2': 'ATELIER',
        'dp1-3': 'EXTRA',
        'dp1-4': 'ORGANISATION DES POSTES DE TRAVAIL',
        'dp1-5': 'NETTOYAGE',
        'ip2-1': 'REMETTRE ORDRES DE TRAVAIL',
        'ip2-2': 'GESTION',
        'ip2-3': 'ENTRE EUX',
        'ip2-4': 'LMRA',
        'ip4-1': 'AUCUN OUTI',
        'ip4-2': 'OUTIL MAGAZINE',
        'ip5-1': 'LE MATÉRIEL MANQUE',
        'ip5-2': 'MATÉRIAUX AU ENTREPÔT',
        'ip7-1': 'PROCESSUS DE SÉCURITÉ',
        'ip7-2': 'SÉCURITÉ TECHNIQUE',
        'ip7-3': 'SURVEILLANCE INCINDIE',
        'ip7-4': 'NETTOYAGE',
        'ip7-5': "ATTENDRE L'ISOLATION",
        'ip7-6': 'ATTENDRE ÉCHAFAUDAGE',
        'ip7-7': 'ÉLEVER / TRANSPORT',
        'ip7-8': 'DÉVERROUILLAGE',
        'ip7-9': 'MISE EN SERVICE',
        'ip8-1': 'TRANSPORT',
        'ip8-2': 'COLLECTE DES OUTILS',
        'ip9-1': 'ÉQUIP. NON DISPONIBLES',
        'ip9-2': 'IMPRÉVUES',
        'ip10-1': 'TROUVER DE DOCUMENTATION',
        'ip10-2': 'CONNAISSANCE',
        'ip10-3': 'DONNÉES',
        'ip10-4': 'ADMINISTRATION',
        'ip10-5': 'RÉDACTION',
        'ip10-6': 'PRÉPARATION DU TRAVAIL',
        'ip11-1': 'SÉCURITÉ GÉNÉRALE',
        'ip11-2': 'SÉCURITÉ PRÉCISE',
      },
      department: {
        maintenance: 'Entretien',
        production: 'Production',
        other: 'Autre',
      },
      sector: {
        chemical: 'Chimie',
        oil_gas: 'Pétrole - Gaz',
        power: 'Puissance',
        heavy_industry: 'Industrie Lourde',
        manufacturing: 'Industrie Manufacturière',
        food_beverage: 'Aliments & Boissons',
        automotive: 'Automobile',
        pharma: 'Pharmaceutique',
        public_transport: 'Transports en Commun',
        logistics: 'Logistique',
        other: 'Autre',
      },
      discipline: {
        mechanical: 'WTB',
        ei: 'EI',
        scaffolding: 'Échafaudage',
        cleaning: 'Nettoyage',
        pb: 'PB',
        insulation: 'Isolation',
        piping: 'Tuyauterie',
        civil: 'Civil',
        painting: 'Peinture',
        other: 'Autre',
      },
      contractType: {
        unit: 'Taux unitaire',
        hourly: 'Taux horaire',
        fixed_price: 'Prix fixe',
      },
      resource: {
        own: 'Personnel Propre',
        internal: 'Contractants Internes',
        external: 'Contractants Externes',
        service: 'Contractants de Service',
      },
      workType: {
        regular_planned: 'Prévu',
        regular_unplanned: 'Imprévu',
        project_tar: 'TAR',
        project: 'Projet',
      },
      shift: {
        day: 'Jour',
        shift: 'Quarts de Travail',
      },
      workshop: {
        centralized: 'Central',
        decentralized: 'Exentré',
      },
    },
    common: {
      YES: 'OUI',
      OK: 'OK',
      SAVE: 'OK',
      NO: 'NON',
      CANCEL: 'ANNULER',
      required: 'champs requis',
      year: 'année',
    },
    navigation: {
      myProjects: 'Mes projets',
      signIn: 'Sign in',
      signOut: 'Sign out',
      accountInfo: 'Account info',
    },
    project: {
      create: {
        title: 'Nouveau Projet',
        location: 'lieu',
        name: 'nom du projet',
        client: 'client',
        country: 'pays',
      },
      overview: {
        title: 'Projets HoTT',
      },
      delete: {
        title: 'Supprimer le projet',
        askConfirmation: 'Êtes-vous sûr de vouloir supprimer le projet sélectionné?',
      },
    },
    measurement: {
      rename: {
        title: 'Renommer',
      },
      viewMode: {
        chart: 'graphique',
        details: 'détails',
      },
      benchmark: {
        filter: {
          all: 'tout',
          none: 'aucun',
        },
      },
      project: 'Projet',
      department: 'département',
      sector: 'secteur',
      discipline: 'disciplines',
      contractType: 'type de contrat',
      resource: 'resources',
      workType: 'type de travail',
      shift: 'maj',
      workshop: 'atelier',
      create: {
        title: 'Nouvelle mesure',
        name: 'nom de la mesure',
      },
      overview: {
        title: 'Mesures',
      },
      chart: {
        title: 'Statistiques',
        legend: {
          types: {
            dp: 'directement productif',
            ip: 'indirectement productif',
            np: 'pas productif',
            na: 'non précisé',
          },
          avoidable: {
            yes: 'évitable',
            no: 'inévitable',
            na: 'non précisé',
          },
        },
        productivity: 'productivité',
        avoidability: 'évitabilité',
        combo: 'combo',
      },
    },
    activity: {
      remark: 'remarque',
      category: 'catégorie',
      subcategory: 'sous-catégorie',
      overview: {
        title: 'Activités',
      },
    },
    license: {
      title: 'HoTT Licence',
      tokens: 'jetons',
    },
    error: {
      header: 'Hands on Tool Time',
      title: 'Une erreur est survenue',
      feedback: "retour d'information",
    },
    menu: {
      signOut: 'Se déconnecter',
      accountInfo: 'Informations de compte',
      about: 'À propos de HoTT',
      settings: 'Réglages',
      switchLicense: 'Changer de licence',
    },
    accountInfo: {
      title: 'Compte et informations de licence',
      account: 'compte',
      license: 'licence',
      users: 'Utilisateurs',
      licenseType: 'Type de licence',
      tokensLeft: 'Jetons restants',
      expire: "Date d'expiration",
      licenseTypes: {
        '1star': '1 étoile',
        '2star': '2 étoiles',
        '3star': '3 étoiles',
        training: 'training',
      },
    },
    settings: {
      title: 'Réglages',
      language: 'Langue préférée',
    },
  },
}
