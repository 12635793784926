import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ResponsivePie } from '@nivo/pie'
import { ResponsiveBar } from '@nivo/bar'
import firebase from 'firebase/app'
import { isIE } from 'react-device-detect'

import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import { firestore } from '../../firebase'
import { makeStyles } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import NavBar from '../../components/NavBar'
import { flatten, isEmpty, keys, pathOr, sortBy, sum, uniq, values, without, path, difference, reverse } from 'ramda'
import Paper from '@material-ui/core/Paper'
import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Button from '@material-ui/core/Button'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'

import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import measurementOptions, {
  CONTRACT_TYPES,
  DEPARTMENTS,
  DISCIPLINES,
  RESOURCES,
  SECTORS,
  SHIFTS,
  WORK_TYPES,
  WORKSHOPS,
} from '../measurementOptions'
import CircularProgress from '@material-ui/core/CircularProgress'
import ProjectChips from '../pageParts/ProjectChips'
import TabPanel from '../pageParts/TabPanel'
import Details from './Details'
import { hoursToHHMM, type2color } from '../../utils/misc'

import ChartOptionsBottom from './ChartOptionsBottom'
import ChartOptionsModal from './ChartOptionsModal'

const { Timestamp } = firebase.firestore

const catList = [
  'dp',
  'dp1',
  'dp1-1',
  'dp1-2',
  'dp1-3',
  'dp1-4',
  'dp1-5',
  'ip',
  'ip2',
  'ip2-1',
  'ip2-2',
  'ip2-3',
  'ip2-4',
  'ip3',
  'ip4',
  'ip4-1',
  'ip4-2',
  'ip5',
  'ip5-1',
  'ip5-2',
  'ip6',
  'ip7',
  'ip7-1',
  'ip7-2',
  'ip7-3',
  'ip7-4',
  'ip7-5',
  'ip7-6',
  'ip7-7',
  'ip7-8',
  'ip7-9',
  'ip8',
  'ip8-1',
  'ip8-2',
  'ip9',
  'ip9-1',
  'ip9-2',
  'ip10',
  'ip10-1',
  'ip10-2',
  'ip10-3',
  'ip10-4',
  'ip10-5',
  'ip10-6',
  'ip11',
  'ip11-1',
  'ip11-2',
  'ip12',
  'ip13',
  'np',
  'np14',
  'np15',
  'np16',
  'pz1',
]

const calLevel2List = ['dp1', 'ip2', 'ip3', 'ip4', 'ip5', 'ip6', 'ip7', 'ip8', 'ip9', 'ip10', 'ip11', 'ip12', 'ip13', 'np14', 'np15', 'np16']
const calLevel3List = [
  'dp1-1',
  'dp1-2',
  'dp1-3',
  'dp1-4',
  'dp1-5',
  'ip2-1',
  'ip2-2',
  'ip2-3',
  'ip2-4',
  'ip4-1',
  'ip4-2',
  'ip5-1',
  'ip5-2',
  'ip7-1',
  'ip7-2',
  'ip7-3',
  'ip7-4',
  'ip7-5',
  'ip7-6',
  'ip7-7',
  'ip7-8',
  'ip7-9',
  'ip8-1',
  'ip8-2',
  'ip9-1',
  'ip9-2',
  'ip10-1',
  'ip10-2',
  'ip10-3',
  'ip10-4',
  'ip10-5',
  'ip10-6',
  'ip11-1',
  'ip11-2',
]

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: '12px 0',
  },
  menuItem: {
    backgroundColor: 'transparent !important',
  },
  chip: {
    borderRadius: '2px',
    padding: '2px',
    height: 'auto',
    borderColor: '#f0f0f0',
    borderWidth: '2px',
    marginRight: '.5rem',
  },
  icon: {
    height: '24px',
    width: '24px',
    marginLeft: '-4px',
    marginBottom: '-2px',
  },
  chipIcon: {
    color: 'red',
    height: '16px !important',
  },
  benchmarkPanel: {
    margin: '0 !important',
  },
  benchmarkSubPanel: {
    margin: '0 !important',
    width: '100%',
  },
  heading: {
    fontSize: '0.9rem',
  },
}))

const barChartProps = ({ level, leftAxisLegend, bench }) => ({
  axisLeft: {
    tickSize: 0,
    tickPadding: 20,
    tickRotation: 2,
    legend: leftAxisLegend,
    legendPosition: 'middle',
    legendOffset: -80,
  },
  axisBottom: {
    tickSize: 0,
    tickPadding: level === 1 ? (isIE ? 30 : 15) : 15,
    tickRotation: level === 1 ? 0 : -20,
    format: d => <tspan style={{ fontSize: level === 1 ? '1rem' : level === 2 ? '1rem' : '.85rem' }}>{d}</tspan>,
  },
  labelSkipWidth: 0,
  labelSkipHeight: 0,
  margin: { top: 90, right: 0, bottom: 130, left: 90 },
  animate: true,
  padding: 0.3,
  theme: {
    fontSize: '17px',
    labels: {
      text: { fontSize: level === 1 ? 16 : bench ? 13 : 15 },
    },
    axis: {
      legend: {
        text: {
          fontSize: '18px',
        },
      },
    },
  },
})
const SortDirectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 7px;
  svg {
    font-size: 0.9rem;
    cursor: pointer;
  }
`
const MeasurementsControlWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
const MeasurementsWrapper = styled.div`
  margin-bottom: 1rem;
  span {
    font-size: 0.85rem;
  }
  svg {
    font-size: 1rem;
  }
`
const ChartContainer = styled.div`
  height: 680px;
  width: 100%;
  padding: 20px 0;
  position: relative;
`
const Wrapper = styled.div`
  display: flex;
  width: 100%;
`
const ChartsWrapper = styled.div`
  width: 100%;
`
const BenchmarkPanel = styled(Paper)`
  padding: 0 !important;
  margin-left: 1rem;
  margin-top: 48px;
  border-radius: 0 !important;
  max-width: 20rem;
  min-width: 18rem;
  height: 100%;
  .MuiPaper-root.Mui-expanded {
    border-top: 1px solid silver;
    border-bottom: 1px solid silver;
  }
`
const BenchmarkHeader = styled.div`
  background-color: #7f9e5d;
  color: white;
  margin-top: 0;
  padding: 1rem;
  font-weight: bold;
  h2 {
    margin: 0 0 10px;
    font-size: 1.2rem;
  }
`
const ProjectWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const ViewModeControlsWrapper = styled.div`
  display: flex;
  margin: 1rem;
  button {
    align-self: flex-end;
  }
`
const PieTitle = styled.div`
  position: absolute;
  top: 30px;
  left: 100px;
  font-size: 16px;
  font-weight: bold;
  pointer-events: none;
`

const pieChartProps = {
  margin: { top: 80, right: 20, bottom: 50, left: 20 },
  innerRadius: 0.4,
  colors: ({ color }) => color,
  borderWidth: 0,
  enableRadialLabels: false,
  slicesLabelsSkipAngle: 0,
  sliceLabel: ({ pct }) => pct,
  slicesLabelsTextColor: 'white',
  isInteractive: true,
  legends: [
    {
      anchor: 'bottom',
      direction: 'row',
      translateY: 50,
      translateX: 20,
      itemWidth: 160,
      itemHeight: 25,
      // itemTextColor: 'black',
      symbolSize: 16,
      symbolShape: 'circle',
    },
  ],
  theme: {
    labels: {
      text: { fontSize: 20 },
    },
  },
  tooltip: ({ color, id, pct }) => (
    <>
      <div style={{ fontSize: '1rem' }}>{id}</div>
      <strong style={{ color }}>{pct}</strong>
    </>
  ),
}

const OptionsBar = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 1rem 2rem;
`

const PanelDetails = styled(ExpansionPanelDetails)`
  flex-wrap: wrap;
  padding: 0 10px !important;
  span {
    font-size: 0.85rem;
  }
  svg {
    font-size: 1rem;
  }
`
const PanelButtons = styled.div`
  padding: 0 12px;
  display: flex;
  justify-content: flex-end;
  button {
    padding: 3px 5px;
    font-size: 11px;
  }
`

const ChartOptions = ({
  chart,
  type,
  onChangeType,
  level,
  onChangeLevel,
  benchmark,
  sortCategory,
  onChangeSortCriterium,
  sortDirection,
  onChangeSortDirection,
}) => {
  const { t } = useTranslation()
  return (
    <OptionsBar>
      <div>
        <Button variant={type === 'time' ? undefined : 'contained'} onClick={() => onChangeType('time')}>
          {t('charts.options.time')}
        </Button>
        <Button variant={type === 'count' ? undefined : 'contained'} onClick={() => onChangeType('count')}>
          {t('charts.options.count')}
        </Button>
      </div>

      {level > 1 && (
        <div style={{ display: 'flex', alignItems: 'flex-end', marginTop: '-10px' }}>
          <SortDirectionWrapper>
            <ExpandLessIcon color={sortDirection === 'asc' ? 'secondary' : 'disabled'} onClick={() => onChangeSortDirection('asc')} />
            <ExpandMoreIcon color={sortDirection === 'desc' ? 'secondary' : 'disabled'} onClick={() => onChangeSortDirection('desc')} />
          </SortDirectionWrapper>
          <FormControl>
            <Select id="sortCriterium" name="sortCriterium" value={sortCategory} onChange={onChangeSortCriterium}>
              <MenuItem value="category">{t('charts.sortCriteria.category')}</MenuItem>
              <MenuItem value="amount">{t('charts.sortCriteria.amount')}</MenuItem>
              {chart === 'combo' && <MenuItem value="avoid">{t('charts.sortCriteria.avoid')}</MenuItem>}
            </Select>
          </FormControl>
        </div>
      )}

      <div>
        <Button variant={level === 1 ? undefined : 'contained'} onClick={() => onChangeLevel(1)}>
          {t('charts.titles.level')} 1
        </Button>
        <Button variant={level === 2 ? undefined : 'contained'} onClick={() => onChangeLevel(2)}>
          {t('charts.titles.level')} 2
        </Button>
        {!benchmark && (
          <Button variant={level === 3 ? undefined : 'contained'} onClick={() => onChangeLevel(3)}>
            {t('charts.titles.level')} 3
          </Button>
        )}
      </div>
    </OptionsBar>
  )
}

const colors = {
  DP: '#228B22',
  DP_BENCH: '#98CEB4',
  IP: '#4169E1',
  IP_BENCH: '#B3D0FF',
  NP: '#D62D20',
  NP_BENCH: '#EFABA4',
}

const avoid2color = type => {
  switch (type) {
    case 'yes':
      return colors.NP
    case 'no':
      return colors.IP
    case 'na':
      return 'darkgray'
    default:
      return 'white'
  }
}

const Measurements = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  // const { setSelectedLicense, licenses, setLicenses } = useLicense()
  const [licenseType, setLicenseType] = useState()
  const [projects, setProjects] = useState([])
  const [measurements, setMeasurements] = useState([])
  const { licenseId, projectId } = useParams()
  const history = useHistory()
  const [measurementSelection, setMeasurementSelection] = useState({})
  const [tabIndex, setTabIndex] = useState(0)
  const [viewMode, setViewMode] = useState('chart') // chart or details
  const [sourceType, setSourceType] = useState('time')
  const [chartType, setChartType] = useState({ prod: 'bar', avoid: 'pie' })
  const [level, setLevel] = useState({ prod: 1, combo: 1 })
  const [shouldShowBenchmark, showBenchmark] = useState(false)
  const [sectorWhiteList, setSectorWhiteList] = useState(null)

  const [prodData, setProdData] = useState([])
  const [avoidData, setAvoidData] = useState([])
  const [comboData, setComboData] = useState([])
  const [benchData, setBenchData] = useState([])

  const [expanded, setExpanded] = useState(false)
  const [accordionState, setAccordionState] = useState({})
  const [csvData, setCsvData] = useState([[], [], []])
  const [sortCriterium, setSortCriterium] = useState({ prod: 'category', combo: 'category' })
  const [sortDirection, setSortDirection] = useState({ prod: 'desc', combo: 'desc' })

  const [showingChartOptions, showChartOptions] = useState(false)
  const [chartOptions, setChartOptions] = useState({})

  const [transparentChartBackground, setTransparentChartBackground] = useState(false)
  const [hidingLabels, hideLabels] = useState(false)
  const [showingZeroCategories, showZeroCategories] = useState(false)
  const [showingZeroSubcategories, showZeroSubcategories] = useState(false)
  // const [showingSeconds, showSeconds] = useState(false)

  const [benchmark, setBenchmark] = useState([])
  const [benchmarkYears, setBenchmarkYears] = useState([])

  const [filters, setFilters] = useState({
    year: benchmarkYears,
    contractType: CONTRACT_TYPES,
    department: DEPARTMENTS,
    discipline: DISCIPLINES,
    resource: RESOURCES,
    sector: SECTORS,
    shift: SHIFTS,
    workshop: WORKSHOPS,
    workType: WORK_TYPES,
    measurement: [],
  })

  const [benchCount, setBenchCount] = useState(0)
  const [benchHours, setBenchHours] = useState(0)
  const [targetBenchmark, setTargetBenchmark] = useState('hott')
  const [fetchedMeasurements, setFetchedMeasurements] = useState({})

  const [chartTitle, setChartTitle] = useState('')
  const [customChartTitles, setCustomChartTitles] = useState({})

  const allowHottBenchmark = licenseType === '2star' || licenseType === '3star' || licenseType === 'training'

  const generateUniqueKeyForMeasurementSelection = () =>
    chartType +
    '/' +
    level[tabIndex === 0 ? 'prod' : 'combo'] +
    '/' +
    tabIndex +
    '/' +
    sourceType +
    '/' +
    keys(measurementSelection)
      .filter(id => measurementSelection[id])
      .join('/')

  useEffect(() => {
    async function getData() {
      const bench = (await fetch('https://hott-benchmark.vercel.app/api/bench').then(res => res.json())).data
      setBenchmark(bench)
      const years = uniq(bench.map(i => String(2000 + i[4])))
      setBenchmarkYears(years)
      setFilters(prevState => ({ ...prevState, year: years }))
    }
    getData()
  }, [])

  useEffect(() => {
    const types = [t('charts.titles.prod'), t('charts.titles.avoid'), t('charts.titles.combo')]
    const suffix =
      tabIndex === 0 ? ` - ${t('charts.titles.level')} ${level.prod}` : tabIndex === 1 ? '' : ` - ${t('charts.titles.level')} ${level.combo}`
    const title = `${types[tabIndex]}${suffix}`

    setChartTitle(customChartTitles[generateUniqueKeyForMeasurementSelection()] || title)
    setChartOptions(prevValue => ({ ...prevValue, chartName: title }))
  }, [level, tabIndex, measurementSelection, chartType, sourceType])

  const ChartTitle = () => (
    <text x="0" y="-70" fill="#000000" fontSize="16px" fontWeight="bold">
      {chartTitle}
    </text>
  )

  const toggleAccordion = panel => (event, isExpanded) => {
    setAccordionState({ ...accordionState, [panel]: isExpanded })
  }

  useEffect(() => {
    if (sectorWhiteList) {
      setFilters(prevValue => ({ ...prevValue, sector: sectorWhiteList }))
    }
  }, [sectorWhiteList])

  const filterBenchmark = useCallback(() => {
    const years = filters.year.map(i => parseInt(i) - 2000)
    const sectors = filters.sector.map(i => SECTORS.indexOf(i))
    const contractTypes = filters.contractType.map(i => CONTRACT_TYPES.indexOf(i))
    const departments = filters.department.map(i => DEPARTMENTS.indexOf(i))
    const disciplines = filters.discipline.map(i => DISCIPLINES.indexOf(i))
    const shifts = filters.shift.map(i => SHIFTS.indexOf(i))
    const workTypes = filters.workType.map(i => WORK_TYPES.indexOf(i))
    const resources = filters.resource.map(i => RESOURCES.indexOf(i))
    const workshops = filters.workshop.map(i => WORKSHOPS.indexOf(i))

    const YEAR_INDEX = 4
    const SECTOR_INDEX = 5
    const CONTRACT_TYPE_INDEX = 6
    const DEPARTMENT_INDEX = 7
    const DISCIPLINE_INDEX = 8
    const SHIFT_INDEX = 9
    const WORK_TYPE_INDEX = 10
    const RESOURCE_INDEX = 11
    const WORKSHOP_INDEX = 12

    return benchmark
      .filter(i => years.includes(i[YEAR_INDEX]))
      .filter(i => sectors.includes(i[SECTOR_INDEX]))
      .filter(i => contractTypes.includes(i[CONTRACT_TYPE_INDEX]))
      .filter(i => departments.includes(i[DEPARTMENT_INDEX]))
      .filter(i => disciplines.includes(i[DISCIPLINE_INDEX]))
      .filter(i => shifts.includes(i[SHIFT_INDEX]))
      .filter(i => workTypes.includes(i[WORK_TYPE_INDEX]))
      .filter(i => resources.includes(i[RESOURCE_INDEX]))
      .filter(i => workshops.includes(i[WORKSHOP_INDEX]))
  })

  useEffect(() => {
    if (targetBenchmark === 'hott') {
      const result = filterBenchmark()
      setBenchCount(result.reduce((acc, curr) => acc + curr[0], 0))
      setBenchHours(Math.round(result.reduce((acc, curr) => acc + parseInt(curr[1]), 0) / 3600))
    } else {
      const count = flatten(values(fetchedMeasurements))
        .filter(measurement => filters.measurement.includes(measurement.id))
        .reduce((acc, curr) => {
          const value = path(['stats', 'level1', 'prod', 'count'], curr)
          return acc + pathOr(0, ['dp'], value) + pathOr(0, ['ip'], value) + pathOr(0, ['np'], value)
        }, 0)
      const time = flatten(values(fetchedMeasurements))
        .filter(measurement => filters.measurement.includes(measurement.id))
        .reduce((acc, curr) => {
          const value = path(['stats', 'level1', 'prod', 'time'], curr)
          return acc + pathOr(0, ['dp'], value) + pathOr(0, ['ip'], value) + pathOr(0, ['np'], value)
        }, 0)
      setBenchCount(count)
      setBenchHours(Math.round(parseInt(time, 0) / 3600))
    }
  }, [filters, targetBenchmark, fetchedMeasurements])

  const toggleFilter = filter => ({ target: { value } }) => {
    const values = filters[filter]
    if (values.includes(value)) {
      setFilters({ ...filters, [filter]: without([value], values) })
    } else {
      setFilters({ ...filters, [filter]: [...values, value] })
    }
  }

  const toggleFilterAllMeasurements = projectId => {
    setFilters({ ...filters, measurement: uniq([...filters.measurement, ...fetchedMeasurements[projectId].map(m => m.id)]) })
  }

  const toggleFilterNoneMeasurements = projectId => {
    setFilters({
      ...filters,
      measurement: without(
        fetchedMeasurements[projectId].map(m => m.id),
        filters.measurement,
      ),
    })
  }

  const toggleFilterAll = filter => () => {
    if (filter === 'year') {
      setFilters({ ...filters, [filter]: benchmarkYears })
    } else {
      if (filter === 'sector' && sectorWhiteList) {
        setFilters({ ...filters, sector: sectorWhiteList })
      } else {
        setFilters({ ...filters, [filter]: measurementOptions(t)[filter].map(i => i.value) })
      }
    }
    // const values = filters[filter]
    // if (values.includes(value)) {
    //   setFilters({ ...filters, [filter]: without([value], values) })
    // } else {
    // }
  }

  const toggleFilterNone = filter => () => {
    setFilters({ ...filters, [filter]: [] })
  }

  const switchProject = event => {
    history.push(`../${event.target.value}/measurements`)
  }

  const toggleMeasurement = name => event => {
    setMeasurementSelection({ ...measurementSelection, [name]: event.target.checked })
  }
  const switchTab = (event, newValue) => {
    setTabIndex(newValue)
  }

  useEffect(() => {
    firestore
      .doc(`data/${licenseId}`)
      .get()
      .then(doc => {
        if (doc.exists) {
          const { type, sectors } = doc.data()
          setLicenseType(type)
          if (type === '2star') {
            setSectorWhiteList(sectors)
          } else if (type === '1star') {
            setTargetBenchmark('own')
          }
        }
      })
  }, [licenseId])

  useEffect(
    function getProjects() {
      const projectsRef = firestore.collection(`data/${licenseId}/projects`) //.where('sortKey', '<', 0)

      projectsRef.get().then(snapshot => {
        const projects = reverse(
          sortBy(
            p => p.created.seconds,
            snapshot.docs
              .filter(doc => !doc.data().archived && doc.data().cloudMeasurementCount > 0)
              .map(doc => ({ ...doc.data(), id: doc.id, year: new Timestamp(doc.data().created.seconds, 0).toDate().getFullYear() })),
          ),
        )
        setProjects(projects)
      })
    },
    [licenseId],
  )

  useEffect(
    function getMeasurements() {
      if (fetchedMeasurements[projectId]) {
        setMeasurements(fetchedMeasurements[projectId])
        setMeasurementSelection(fetchedMeasurements[projectId].reduce((acc, curr) => ({ [curr.id]: true, ...acc }), {}))
        return
      }
      const measurementsRef = firestore.collection(`data/${licenseId}/projects/${projectId}/measurements`).where('upload', '==', true)
      measurementsRef.get().then(snapshot => {
        const sortedMeasurements = sortBy(
          m => m.created.seconds,
          snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })),
        )
        setFetchedMeasurements({ ...fetchedMeasurements, [projectId]: sortedMeasurements })
        setMeasurements(sortedMeasurements)
        setMeasurementSelection(sortedMeasurements.reduce((acc, curr) => ({ [curr.id]: true, ...acc }), {}))
      })
    },
    [licenseId, projectId],
  )

  useEffect(() => {
    if (shouldShowBenchmark && targetBenchmark === 'own') {
      const filtersToRemove = filters.measurement.filter(measId => measurementSelection[measId])
      if (filtersToRemove.length > 0) {
        setFilters({ ...filters, measurement: filters.measurement.filter(m => !filtersToRemove.includes(m)) })
      }
    }
  }, [measurementSelection, shouldShowBenchmark, targetBenchmark, filters])

  const formatType = (type, level) => {
    const label = t(`charts.labels.category.${type}`)
    if (level === 1) {
      return label
    }
    const prefix = type.toUpperCase().replace('-', '.')
    return `${prefix} ${label}`
  }

  const fixSeconds = value => (value.indexOf('s') !== -1 ? '0:00' : value)

  const generateCsvData = (prodValues, avoidValues, comboValues, benchData) => {
    const prodCsv = benchData
      ? benchData.map(value => [value.id, value.own, value.bench])
      : prodValues.map(value => [value.id, value.formattedValue === '0s' ? '0:00' : value.formattedValue])
    const avoidCsv = avoidValues.map(value => [value.id, value.pct])
    // const noValue = sourceType === 'time' ? fixSeconds(value.formattedValues.no) : value.formattedValues.no
    const comboCsv = comboValues.map(value => [
      value.id,
      sourceType === 'time' ? fixSeconds(value.formattedValues.no) : value.formattedValues.no,
      sourceType === 'time' ? fixSeconds(value.formattedValues.yes) : value.formattedValues.yes,
    ])
    setCsvData([prodCsv, avoidCsv, comboCsv])
  }

  useEffect(() => {
    const isSelected = measurement => measurementSelection[measurement.id]
    let prodTotal = 0
    let prodValues = {}
    let avoidTotal = 0
    let avoidValues = {}
    let comboValues = {}
    let benchValues = {}
    let benchTotal = 0

    if (showingZeroCategories) {
      if (level.prod > 1) {
        calLevel2List.forEach(lvl => (prodValues[lvl] = 0))
      }
      if (level.combo > 1) {
        calLevel2List.forEach(lvl => (comboValues[lvl] = 0))
      }
    }
    if (showingZeroSubcategories) {
      if (level.prod > 2) {
        calLevel3List.forEach(lvl => (prodValues[lvl] = 0))
      }
      if (level.combo > 2) {
        calLevel3List.forEach(lvl => (comboValues[lvl] = 0))
      }
    }
    if (!isEmpty(measurementSelection)) {
      const divider = sourceType === 'time' ? 3600 : 1
      measurements.filter(isSelected).forEach(measurement => {
        if (measurement.stats) {
          const prodStats = measurement.stats[`level${level.prod}`].prod[sourceType]
          prodTotal += sum(values(prodStats)) / divider

          keys(prodStats).forEach(key => {
            prodValues[key] = (prodValues[key] || 0) + prodStats[key] / divider
          })

          const avoidStats = measurement.stats['level1'].avoid[sourceType]
          avoidTotal += sum(values(avoidStats)) / divider
          keys(avoidStats).forEach(key => {
            avoidValues[key] = (avoidValues[key] || 0) + avoidStats[key] / divider
          })

          const comboStats = measurement.stats[`level${level.combo}`].combo[sourceType]
          keys(comboStats).forEach(key => {
            const yesValue = pathOr(0, [key, 'yes'], comboStats) / divider
            const noValue = pathOr(0, [key, 'no'], comboStats) / divider
            // const targetParent = pathOr({}, [key], comboValues)
            const oldYesValue = pathOr(0, [key, 'yes'], comboValues)
            const oldNoValue = pathOr(0, [key, 'no'], comboValues)
            comboValues[key] = { yes: yesValue + oldYesValue, no: noValue + oldNoValue }
          })
        }
      })
    }

    let benchData = null
    if (shouldShowBenchmark) {
      if (targetBenchmark === 'hott') {
        filterBenchmark().forEach(measurement => {
          const catId = measurement[3]
          const cat = catId === 1 ? 'dp' : catId <= 13 ? 'ip' : 'np'

          const key = level.prod === 1 ? cat.substr(0, 2) : cat + catId
          const value = sourceType === 'time' ? parseInt(measurement[1]) : parseInt(measurement[0])
          benchValues[key] = (benchValues[key] || 0) + value
          benchTotal += value
        })
      } else {
        const benchFilter = values(filters.measurement)
        const benchMeasurements = flatten(values(fetchedMeasurements)).filter(({ id }) => benchFilter.includes(id))
        const divider = sourceType === 'time' ? 3600 : 1
        benchMeasurements.forEach(meas => {
          if (meas.stats) {
            const stats = meas.stats[`level${level.prod}`].prod[sourceType]
            benchTotal += sum(values(stats)) / divider
            keys(stats).forEach(key => {
              benchValues[key] = (benchValues[key] || 0) + stats[key] / divider
            })
          }
        })
      }

      // todo: get all keys that are in proValues OR benchValues and iterate over those
      const benchResult = keys(prodValues).map(type => {
        const prodPct = Math.round((1000 * prodValues[type]) / prodTotal) / 10
        const benchPct = Math.round((1000 * benchValues[type]) / benchTotal) / 10
        const result = {
          type,
          label: formatType(type, level.prod),
          id: formatType(type, level.prod),
          own: prodPct,
          bench: benchPct,
          pct: {
            own: prodPct,
            bench: benchPct,
          },
          color: type2color(type),
        }
        return result
      })
      benchData = sortData(benchResult, 'bench', sortCriterium.prod, sortDirection.prod)
      setBenchData(benchData)
    }

    const prodResult = keys(prodValues).map(type => {
      const value = prodValues[type]
      const formattedValue = sourceType === 'time' ? hoursToHHMM(value) : value
      const pct = Math.round((1000 * value) / prodTotal) / 10
      return {
        id: formatType(type, level.prod),
        type,
        value,
        label: formattedValue + (level.prod === 1 ? ` (${pct}%)` : ''),
        pct: `${pct}%`,
        color: type2color(type),
        formattedValue,
      }
    })

    const avoidResult = keys(avoidValues).map(type => {
      const value = avoidValues[type]
      const pct = Math.round((1000 * value) / avoidTotal) / 10
      return {
        id: t(`measurement.chart.legend.avoidable.${type}`),
        type,
        value,
        label: `${pct}%`,
        pct: `${pct}%`,
        color: avoid2color(type),
      }
    })

    const comboResult = keys(comboValues).map(type => {
      const yesValue = pathOr(0, [type, 'yes'], comboValues)
      const noValue = pathOr(0, [type, 'no'], comboValues)
      const yesPct = Math.round((1000 * yesValue) / prodTotal) / 10 + '%'
      const noPct = Math.round((1000 * noValue) / prodTotal) / 10 + '%'
      // const formattedValue = sourceType === 'time' ? hoursToHHMM(value) : value
      return {
        id: formatType(type, level.combo),
        label: formatType(type, level.combo),
        type,
        avoidable: pathOr(0, [type, 'yes'], comboValues),
        no: pathOr(0, [type, 'no'], comboValues),
        color: type2color(type),
        pct: {
          yes: yesPct,
          no: noPct,
        },
        formattedValues: {
          yes: sourceType === 'time' ? hoursToHHMM(yesValue) : yesValue,
          no: sourceType === 'time' ? hoursToHHMM(noValue) : noValue,
        },
        labels: {
          yes: sourceType === 'time' ? hoursToHHMM(yesValue) + (level.combo === 1 ? ` (${yesPct})` : '') : yesValue,
          no: sourceType === 'time' ? hoursToHHMM(noValue) + (level.combo === 1 ? ` (${noPct})` : '') : noValue,
        },
      }
    })

    const prodData = sortData(prodResult, 'prod', sortCriterium.prod, sortDirection.prod)
    setProdData(prodData)
    const avoidData = avoidResult.map(i => ({ ...i, type: t(`charts.labels.avoidable.${i.type}`) }))
    setAvoidData(avoidData)
    const comboData = sortData(comboResult, 'combo', sortCriterium.combo, sortDirection.combo)

    generateCsvData(prodData, avoidData, comboData, benchData)
    setComboData(comboData)
  }, [measurementSelection, sourceType, level, measurements, t, shouldShowBenchmark, filters, targetBenchmark, showingZeroCategories])

  const sortData = (data, chart, criterium, direction) => {
    if (criterium === 'category') {
      const multiplier = direction === 'asc' ? -1 : 1
      const sortFunction = i => multiplier * catList.indexOf(i.type)
      return sortBy(sortFunction, data)
    } else if (criterium === 'amount') {
      const multiplier = direction === 'asc' ? 1 : -1
      const sortFunction = chart === 'prod' ? i => multiplier * i.value : chart === 'bench' ? i => multiplier * i.own : i => multiplier * i.no
      return sortBy(sortFunction, data)
    } else if (criterium === 'avoid') {
      const multiplier = direction === 'asc' ? 1 : -1
      const sortFunction = i => multiplier * i.avoidable
      return sortBy(sortFunction, data)
    }
  }

  const selectBenchmarkProject = projectId => {
    if (!fetchedMeasurements[projectId]) {
      const measurementsRef = firestore.collection(`data/${licenseId}/projects/${projectId}/measurements`).where('upload', '==', true)
      measurementsRef.get().then(snapshot => {
        const sortedMeasurements = sortBy(
          m => m.created.seconds,
          snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })),
        )
        setFetchedMeasurements({ ...fetchedMeasurements, [projectId]: sortedMeasurements })
      })
    }
    return toggleAccordion('project' + projectId)
  }

  const toggleExpanded = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const onChangeSortCriterium = chart => event => {
    if (chart === 'prod') {
      setSortCriterium(oldValue => ({ ...oldValue, [chart]: event.target.value }))
      setProdData(sortData(prodData, chart, event.target.value, sortDirection.prod))
    } else if (chart === 'combo') {
      setSortCriterium(oldValue => ({ ...oldValue, [chart]: event.target.value }))
      setComboData(sortData(comboData, chart, event.target.value, sortDirection.combo))
    } else if (chart === 'bench') {
      setSortCriterium(oldValue => ({ ...oldValue, prod: event.target.value }))
      setBenchData(sortData(benchData, chart, event.target.value, sortDirection.prod))
    }
  }

  const onChangeSortDirection = chart => direction => {
    if (chart === 'prod') {
      setSortDirection(oldValue => ({ ...oldValue, [chart]: direction }))
      setProdData(sortData(prodData, chart, sortCriterium.prod, direction))
    } else if (chart === 'combo') {
      setSortDirection(oldValue => ({ ...oldValue, [chart]: direction }))
      setComboData(sortData(comboData, chart, sortCriterium.combo, direction))
    } else if (chart === 'bench') {
      setSortDirection(oldValue => ({ ...oldValue, prod: direction }))
      setBenchData(sortData(benchData, chart, sortCriterium.prod, direction))
    }
  }

  const targetBenchmarkOptions = allowHottBenchmark
    ? [
        { label: t('charts.hottDatabase'), value: 'hott' },
        { label: t('charts.ownMeasurements'), value: 'own' },
      ]
    : [{ label: t('charts.ownMeasurements'), value: 'own' }]

  const onSaveChartOptions = options => {
    showChartOptions(false)
    if (options.chartName !== chartTitle) {
      setChartTitle(options.chartName)
      setCustomChartTitles(prevValue => ({ ...prevValue, [generateUniqueKeyForMeasurementSelection()]: options.chartName }))
    }
    if (options.transparentBackground !== transparentChartBackground) {
      setTransparentChartBackground(options.transparentBackground)
    }
    if (options.hideLabels !== hidingLabels) {
      hideLabels(options.hideLabels)
    }
    if (options.showZeroCategories !== showingZeroCategories) {
      showZeroCategories(options.showZeroCategories)
    }
    if (options.showZeroSubcategories !== showingZeroSubcategories) {
      showZeroSubcategories(options.showZeroSubcategories)
    }
    setChartOptions(options)
  }

  return (
    <>
      <NavBar />
      <Container maxWidth={'xl'}>
        <ProjectWrapper>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="project">{t(`measurement.project`)}</InputLabel>
            <Select id="project" name="project" value={projectId || ''} onChange={switchProject}>
              {projects.map(project => (
                <MenuItem value={project.id} key={project.id} className={classes.menuItem}>
                  {project.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {<ProjectChips project={projects.find(p => p.id === projectId)} />}
        </ProjectWrapper>
        <MeasurementsControlWrapper>
          <MeasurementsWrapper>
            {measurements.map(measurement => (
              <FormControlLabel
                key={measurement.id}
                control={
                  <Checkbox checked={!!measurementSelection[measurement.id]} onChange={toggleMeasurement(measurement.id)} value={measurement.id} />
                }
                label={measurement.name}
              />
            ))}
          </MeasurementsWrapper>
          <ViewModeControlsWrapper>
            {['chart', 'details'].map(mode => (
              <Button key={mode} variant={viewMode === mode ? undefined : 'contained'} onClick={() => setViewMode(mode)}>
                {t(`measurement.viewMode.${mode}`)}
              </Button>
            ))}
          </ViewModeControlsWrapper>
        </MeasurementsControlWrapper>
        {viewMode === 'chart' && (
          <Wrapper>
            <ChartsWrapper>
              <Tabs value={tabIndex} onChange={switchTab} indicatorColor="primary" textColor="primary">
                <Tab label={t('measurement.chart.productivity')} />
                <Tab label={t('measurement.chart.avoidability')} />
                <Tab label={t('measurement.chart.combo')} />
              </Tabs>
              <TabPanel value={tabIndex} index={0}>
                {/******** PROD CHART ********/}
                <ChartOptions
                  type={sourceType}
                  onChangeType={setSourceType}
                  level={level.prod}
                  onChangeLevel={prodLevel => setLevel({ ...level, prod: prodLevel })}
                  benchmark={shouldShowBenchmark}
                  sortCategory={sortCriterium.prod}
                  onChangeSortCriterium={onChangeSortCriterium(shouldShowBenchmark ? 'bench' : 'prod')}
                  sortDirection={sortDirection.prod}
                  onChangeSortDirection={onChangeSortDirection(shouldShowBenchmark ? 'bench' : 'prod')}
                  chart={shouldShowBenchmark ? 'bench' : 'prod'}
                />
                <ChartContainer id="chart0">
                  {chartType.prod === 'pie' && level.prod === 1 && (
                    <>
                      <PieTitle>{chartTitle}</PieTitle>
                      <ResponsivePie data={prodData} {...pieChartProps} enableSlicesLabels={!hidingLabels} />
                    </>
                  )}
                  {(chartType.prod === 'bar' || level.prod !== 1) && !shouldShowBenchmark && (
                    <ResponsiveBar
                      data={prodData}
                      keys={['value']}
                      colors={({ data }) => data.color}
                      labelTextColor={({ data }) => data.data.color}
                      label={({ data }) => data.label}
                      labelFormat={d => <tspan y={-14}>{d}</tspan>}
                      enableLabel={!hidingLabels}
                      {...barChartProps({ level: level.prod, leftAxisLegend: t(`charts.legends.${sourceType}`) })}
                      tooltip={({ color, data }) => (
                        <>
                          <div style={{ fontSize: '1rem' }}>{data.id}</div>
                          <strong style={{ color }}>{data.label}</strong>
                        </>
                      )}
                      layers={['grid', 'axes', 'bars', ChartTitle, 'legends']}
                    />
                  )}
                  {shouldShowBenchmark && (
                    <ResponsiveBar
                      enableLabel={!hidingLabels}
                      data={benchData}
                      groupMode={'grouped'}
                      keys={['own', 'bench']}
                      colors={data => type2color(data.data.type, data.id === 'bench')}
                      labelTextColor={({ data }) => data.data.color}
                      label={data => `${data.data.pct[data.id]}%`}
                      labelFormat={d => (
                        <tspan y={-14} x={level.prod === 1 ? undefined : 15}>
                          {d}
                        </tspan>
                      )}
                      {...barChartProps({ level: level.prod, leftAxisLegend: t(`charts.legendsBenchmark.${sourceType}`), bench: true })}
                      layers={['grid', 'axes', 'bars', ChartTitle, 'legends']}
                      tooltip={({ id, color, data }) => (
                        <>
                          <div style={{ fontSize: '1rem' }}>{data.id}</div>
                          <strong style={{ color }}>{`${data.pct[id]}%`}</strong>
                        </>
                      )}
                    />
                  )}
                </ChartContainer>
                <ChartOptionsBottom
                  chart="prod"
                  level={level.prod}
                  chartType={chartType.prod}
                  onChangeChartType={type => setChartType({ ...chartType, prod: type })}
                  onChangeBenchmark={showBenchmark}
                  benchmarkActive={shouldShowBenchmark}
                  csvData={csvData}
                  tabIndex={tabIndex}
                  chartTitle={chartTitle}
                  onShowChartOptions={() => showChartOptions(true)}
                  transparentBackground={transparentChartBackground}
                />
              </TabPanel>

              {/******** AVOID CHART ********/}
              <TabPanel value={tabIndex} index={1}>
                <ChartContainer id="chart1">
                  {chartType.avoid === 'pie' && (
                    <>
                      <PieTitle>{chartTitle}</PieTitle>
                      <ResponsivePie data={avoidData} {...pieChartProps} enableSlicesLabels={!hidingLabels} />
                    </>
                  )}
                  {chartType.avoid === 'bar' && (
                    <>
                      <ResponsiveBar
                        data={avoidData}
                        keys={['value']}
                        colors={({ data }) => data.color}
                        labelTextColor={({ data }) => data.data.color}
                        label={({ data }) => `${hoursToHHMM(data.value)} (${data.label})`}
                        labelFormat={d => <tspan y={-14}>{d}</tspan>}
                        enableLabel={!hidingLabels}
                        {...barChartProps({ level: 1, leftAxisLegend: t(`charts.legends.${sourceType}`) })}
                        tooltip={({ color, data }) => (
                          <>
                            <div style={{ fontSize: '1rem' }}>{data.id}</div>
                            <strong style={{ color }}>{data.label}</strong>
                          </>
                        )}
                        layers={['grid', 'axes', 'bars', ChartTitle, 'legends']}
                      />
                    </>
                  )}
                </ChartContainer>
                <ChartOptionsBottom
                  chart="avoid"
                  level={1}
                  chartType={chartType.avoid}
                  csvData={csvData}
                  tabIndex={tabIndex}
                  chartTitle={chartTitle}
                  onShowChartOptions={() => showChartOptions(true)}
                  transparentBackground={transparentChartBackground}
                  onChangeChartType={type => setChartType({ ...chartType, avoid: type })}
                />
              </TabPanel>

              {/******** COMBO CHART ********/}
              <TabPanel value={tabIndex} index={2}>
                <ChartOptions
                  type={sourceType}
                  onChangeType={setSourceType}
                  level={level.combo}
                  onChangeLevel={comboLevel => setLevel({ ...level, combo: comboLevel })}
                  benchmark={shouldShowBenchmark}
                  sortCategory={sortCriterium.combo}
                  onChangeSortCriterium={onChangeSortCriterium('combo')}
                  sortDirection={sortDirection.combo}
                  onChangeSortDirection={onChangeSortDirection('combo')}
                  chart="combo"
                />
                <ChartContainer bottomOffset={70} id="chart2">
                  <ResponsiveBar
                    data={comboData}
                    keys={['no', 'avoidable']}
                    enableLabel={!hidingLabels}
                    colors={({ id, data }) => (id === 'avoidable' ? 'orange' : type2color(data.type))}
                    labelTextColor={({ data }) => data.data.color}
                    label={data => {
                      // if avoidable > 0, return only data when avoidable, otherwise only when not avoidable
                      if ((data.id === 'no' && data.data.avoidable > 0) || (data.id === 'avoidable' && data.data.avoidable === 0)) {
                        return ''
                      }
                      const avoidableValue = sourceType === 'time' ? hoursToHHMM(data.data.avoidable) : data.data.avoidable
                      const unAvoidableValue = sourceType === 'time' ? hoursToHHMM(data.data.no) : data.data.no
                      return (
                        avoidableValue +
                        (level.combo === 1 ? ` (${data.data.pct.yes})` : '') +
                        '#' +
                        unAvoidableValue +
                        (level.combo === 1 ? ` (${data.data.pct.no})` : '')
                      )
                    }}
                    labelFormat={d => {
                      if (!d) {
                        return ''
                      }
                      return (
                        <>
                          <tspan y={-16}>{d.split('#')[1]}</tspan>
                          <tspan
                            dy={-20}
                            style={{ fill: 'orange', alignmentBaseline: 'center' }}
                            x={level.combo === 1 ? '10%' : level.combo === 2 ? 35 : 25}
                          >
                            {/*<tspan dy={-20} style={{ fill: 'orange' }} x={level.combo === 1 ? '10%' : level.combo === 2 ? 35 : 15}>*/}
                            {d.split('#')[0]}
                          </tspan>
                        </>
                      )
                    }}
                    {...barChartProps({ level: level.combo, leftAxisLegend: t(`charts.legends.${sourceType}`) })}
                    layers={['grid', 'axes', 'bars', ChartTitle, 'legends']}
                    tooltip={({ data }) => (
                      <>
                        <div style={{ fontSize: '1rem' }}>{data.id}</div>
                        <div style={{ color: 'orange', display: 'flex', justifyContent: 'space-between' }}>
                          <strong>{t('charts.labels.avoidable.yes')}</strong>
                          <strong style={{ paddingLeft: '10px' }}>{data.labels.yes}</strong>
                        </div>
                        <div style={{ color: data.color, display: 'flex', justifyContent: 'space-between' }}>
                          <strong>{t('charts.labels.avoidable.no')}</strong>
                          <strong style={{ paddingLeft: '10px' }}>{data.labels.no}</strong>
                        </div>
                      </>
                    )}
                    legends={[
                      {
                        dataFrom: 'keys',
                        anchor: 'top-right',
                        direction: 'row',
                        justify: false,
                        translateY: -80,
                        itemsSpacing: 2,
                        itemWidth: 200,
                        translateX: 200, // put second key off the chart
                        itemHeight: 10,
                        itemDirection: 'left-to-right',
                        itemOpacity: 1,
                        symbolSize: 20,
                      },
                    ]}
                  />
                </ChartContainer>
                <ChartOptionsBottom
                  level={level.combo}
                  chart="combo"
                  csvData={csvData}
                  tabIndex={tabIndex}
                  chartTitle={chartTitle}
                  onShowChartOptions={() => showChartOptions(true)}
                  transparentBackground={transparentChartBackground}
                />
              </TabPanel>
            </ChartsWrapper>
            {shouldShowBenchmark && (
              <BenchmarkPanel>
                <BenchmarkHeader>
                  <h2>{t(`charts.benchmark`)}</h2>
                  <span>
                    {benchHours} {t(`charts.hours`)} / {benchCount} {t(`charts.activities`)}
                  </span>
                </BenchmarkHeader>

                <FormControl className={classes.formControl} style={{ marginLeft: '1rem' }}>
                  <InputLabel htmlFor="targetBenchmark">{t(`charts.benchmark`)}</InputLabel>
                  <Select
                    id="targetBenchmark"
                    name="targetBenchmark"
                    value={targetBenchmark || ''}
                    onChange={e => setTargetBenchmark(e.target.value)}
                  >
                    {targetBenchmarkOptions.map(db => (
                      <MenuItem value={db.value} key={db.value} className={classes.menuItem}>
                        {db.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {targetBenchmark === 'hott' ? (
                  <>
                    {['contractType', 'department', 'discipline', 'resource', 'sector', 'shift', 'workshop', 'workType'].map((item, index) => (
                      <ExpansionPanel
                        expanded={expanded === `panel${index}`}
                        onChange={toggleExpanded(`panel${index}`)}
                        className={classes.benchmarkPanel}
                        key={index}
                      >
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography className={classes.heading}>{t(`measurement.${item}`)}</Typography>
                        </ExpansionPanelSummary>
                        <PanelDetails>
                          {measurementOptions(t)
                            [item].filter(option => (item !== 'sector' || licenseType !== '2star' ? true : sectorWhiteList.includes(option.value)))
                            .map(option => (
                              <FormControlLabel
                                key={option.value}
                                control={
                                  <Checkbox checked={filters[item].includes(option.value)} onChange={toggleFilter(item)} value={option.value} />
                                }
                                label={option.label}
                              />
                            ))}
                        </PanelDetails>
                        <PanelButtons>
                          <Button
                            variant={
                              (item === 'sector' && sectorWhiteList
                              ? filters.sector.length !== sectorWhiteList.length
                              : filters[item].length !== measurementOptions(t)[item].length)
                                ? 'contained'
                                : undefined
                            }
                            onClick={toggleFilterAll(item)}
                          >
                            {t('measurement.benchmark.filter.all')}
                          </Button>
                          <Button
                            variant={
                              (item === 'sector' && sectorWhiteList
                              ? filters.sector.length === sectorWhiteList.length
                              : filters[item].length === measurementOptions(t)[item].length)
                                ? 'contained'
                                : undefined
                            }
                            onClick={toggleFilterNone(item)}
                          >
                            {t('measurement.benchmark.filter.none')}
                          </Button>
                        </PanelButtons>
                      </ExpansionPanel>
                    ))}

                    <ExpansionPanel expanded={expanded === 'panelYear'} onChange={toggleExpanded('panelYear')} className={classes.benchmarkPanel}>
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography className={classes.heading}>{t('common.year')}</Typography>
                      </ExpansionPanelSummary>
                      <PanelDetails>
                        {benchmarkYears.map(year => (
                          <FormControlLabel
                            key={year}
                            control={<Checkbox checked={filters.year.includes(year)} onChange={toggleFilter('year')} value={year} />}
                            label={year}
                          />
                        ))}
                      </PanelDetails>
                      <PanelButtons>
                        <Button variant={filters.year.length !== benchmarkYears.length ? 'contained' : undefined} onClick={toggleFilterAll('year')}>
                          {t('measurement.benchmark.filter.all')}
                        </Button>
                        <Button variant={filters.year.length === benchmarkYears.length ? 'contained' : undefined} onClick={toggleFilterNone('year')}>
                          {t('measurement.benchmark.filter.none')}
                        </Button>
                      </PanelButtons>
                    </ExpansionPanel>
                  </>
                ) : (
                  <>
                    {uniq(projects.map(project => project.year)).map(year => (
                      <>
                        <ExpansionPanel
                          expanded={accordionState[`year${year}`]}
                          onChange={toggleAccordion(`year${year}`)}
                          className={classes.benchmarkPanel}
                        >
                          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography className={classes.heading}>{year}</Typography>
                          </ExpansionPanelSummary>
                          <PanelDetails>
                            {projects
                              .filter(project => project.year === year)
                              .map(project => {
                                const measurementIds = (fetchedMeasurements[project.id] || []).map(m => m.id)
                                return (
                                  <ExpansionPanel
                                    expanded={accordionState[project.id]}
                                    onChange={({ ...props }) => selectBenchmarkProject(project.id)(props)}
                                    className={classes.benchmarkSubPanel}
                                  >
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                      <Typography className={classes.heading}>{project.name}</Typography>
                                    </ExpansionPanelSummary>
                                    <PanelDetails>
                                      {fetchedMeasurements[project.id] ? (
                                        fetchedMeasurements[project.id].map(measurement => (
                                          <>
                                            <FormControlLabel
                                              key={measurement.id}
                                              control={
                                                <Checkbox
                                                  checked={filters.measurement.includes(measurement.id)}
                                                  onChange={toggleFilter('measurement')}
                                                  value={measurement.id}
                                                  disabled={measurementSelection[measurement.id]}
                                                />
                                              }
                                              label={measurement.name}
                                            />
                                          </>
                                        ))
                                      ) : (
                                        <CircularProgress />
                                      )}
                                    </PanelDetails>
                                    <PanelButtons>
                                      <Button
                                        variant={difference(measurementIds, filters.measurement).length !== 0 ? 'contained' : undefined}
                                        onClick={() => toggleFilterAllMeasurements(project.id)}
                                      >
                                        {t('measurement.benchmark.filter.all')}
                                      </Button>
                                      <Button
                                        variant={
                                          difference(measurementIds, filters.measurement).length !== measurementIds.length ? 'contained' : undefined
                                        }
                                        onClick={() => toggleFilterNoneMeasurements(project.id)}
                                      >
                                        {t('measurement.benchmark.filter.none')}
                                      </Button>
                                    </PanelButtons>
                                  </ExpansionPanel>
                                )
                              })}
                          </PanelDetails>
                        </ExpansionPanel>
                      </>
                    ))}
                  </>
                )}
              </BenchmarkPanel>
            )}
          </Wrapper>
        )}
        {viewMode === 'details' && <Details measurements={measurements} />}
      </Container>

      {showingChartOptions && <ChartOptionsModal options={chartOptions} onSave={onSaveChartOptions} onCancel={() => showChartOptions(false)} />}
    </>
  )
}

export default Measurements
