import React, { useEffect, useState } from 'react'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import styled from '@emotion/styled'
import firebase from 'firebase/app'
import { evolve, keys, sortBy, sum, values } from 'ramda'
import { isIE } from 'react-device-detect'
import { ResponsiveBar } from '@nivo/bar'
import { ResponsivePie } from '@nivo/pie'
import { useTranslation } from 'react-i18next'

import Button from '@material-ui/core/Button'
import EqualizerIcon from '@material-ui/icons/Equalizer'
import DonutSmallIcon from '@material-ui/icons/DonutSmall'
import { Container, makeStyles } from '@material-ui/core'

import { firebaseAuth, firestore } from '../firebase'
import NavBar from '../components/NavBar'
import { useLicense } from '../hooks/useLicense'
import CloseIcon from '@material-ui/icons/Close'
import { primaryColor } from '../theme'
import { catList, type2color } from '../utils/misc'

const uiConfig = {
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/',
  immediateFederatedRedirect: true,
  signInOptions: [firebase.auth.EmailAuthProvider.PROVIDER_ID],
  credentialHelper: 'none',
}
const useStyles = makeStyles(theme => ({
  iconButton: {
    borderRadius: '50%',
    padding: '12px',
  },
}))
const OptionsBar = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 1rem 2rem;
  background-color: white;
`
const SignInContainer = styled.div`
  display: flex;
  justify-content: center;
  > div {
    position: relative;
    > div {
      display: inline-block;
      min-width: 400px;
    }
  }
`
const CancelSignIn = styled(CloseIcon)`
  position: absolute;
  right: -20px;
  top: -30px;
  cursor: pointer;
  color: #888;
  :hover {
    color: ${primaryColor};
  }
`
const Title = styled.h1`
  font-size: 24px;
`
const Wrapper = styled(Container)`
  padding-top: 4rem;
  padding-bottom: 4rem;
`
const ChartWrapper = styled.div`
  width: 100%;
  margin-top: 2rem;
  padding-bottom: 4rem;
  height 550px;
  background-color: white;
`
const Info = styled.p`
  line-height: 1.6;
  opacity: 0.9;
`

const Home = () => {
  const { t } = useTranslation()
  const { setSelectedLicense, setLicenses } = useLicense()
  const [shouldShowSignInButton, showSignInButton] = useState(false)
  const [shouldShowSignInForm, showSignInForm] = useState(false)
  const [loading, setLoading] = useState(true)
  const [sourceType, setSourceType] = useState('time')
  const [chartType, setChartType] = useState('bar')
  const [data, setData] = useState([])
  const [benchHours, setBenchHours] = useState(null)
  const classes = useStyles()

  useEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged(user => {
      setLoading(false)
      showSignInButton(!user)
      if (user) {
        firestore
          .doc(`users/${user.email.toLowerCase()}`)
          .get()
          .then(doc => {
            if (doc.exists) {
              const { selectedLicenseWeb, licenseMap, licenseIds } = doc.data()
              const licenses = keys(licenseMap).map(id => ({ id, name: licenseMap[id] }))
              const selected = selectedLicenseWeb ? selectedLicenseWeb : licenseIds.length > 0 ? licenseIds[0] : undefined
              setSelectedLicense(selected)
              setLicenses(licenses || [])
            }
          })
      }
    })

    return () => unsubscribe()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetch('https://hott-benchmark.vercel.app/api/home')
      const stats = (await result.json()).data

      setBenchHours(stats.time.dp + stats.time.ip + stats.time.np)
      const prodValues = {}
      let prodTotal = 0

      const prodStats = stats[sourceType]
      prodTotal += sum(values(prodStats))
      keys(prodStats).forEach(key => {
        prodValues[key] = (prodValues[key] || 0) + prodStats[key]
      })

      const prodResult = keys(prodValues).map(type => {
        const value = prodValues[type]
        const pct = Math.round((1000 * value) / prodTotal) / 10
        return {
          label: t(`charts.labels.category.${type}`),
          type,
          id: t(`charts.labels.category.${type}`),
          value,
          pct,
          color: type2color(type),
        }
      })
      setData(sortBy(i => catList.indexOf(i.type), prodResult))
    }
    fetchData()
  }, [sourceType, t])

  // useEffect(() => {
  //   firestore
  //     .doc('benchmark/all2')
  //     .get()
  //     .then(doc => {
  //       const data = values(doc.data().data)
  //       // console.log('asdf', data)
  //       const totalCount = data.reduce((acc, cur) => acc + cur[0], 0)
  //       console.log('total', totalCount)
  //       const totalHours = Math.round(data.reduce((acc, cur) => acc + cur[1], 0) / 3600)
  //       console.log('total', totalHours)
  //
  //       const stats = data.reduce(
  //         (acc, cur) => {
  //           const catId = cur[3]
  //           const cat = catId === 1 ? 'dp' : catId <= 13 ? 'ip' : 'np'
  //           const count = cur[0]
  //           const time = cur[1]
  //           return { time: { ...acc.time, [cat]: time + acc.time[cat] }, count: { ...acc.count, [cat]: count + acc.count[cat] } }
  //         },
  //         { time: { dp: 0, ip: 0, np: 0 }, count: { dp: 0, ip: 0, np: 0 } },
  //       )
  //
  //       const trans = {
  //         time: {
  //           dp: value => Math.round(value / 3600),
  //           ip: value => Math.round(value / 3600),
  //           np: value => Math.round(value / 3600),
  //         },
  //       }
  //       // console.log('result', stats)
  //       console.log('result2', stats)
  //       firestore.doc('benchmark/homepage2').set({ stats })
  //     })
  // }, [])

  const pieChartProps = {
    margin: { top: 80, right: 20, bottom: 50, left: 20 },
    innerRadius: 0.4,
    colors: ({ color }) => color,
    borderWidth: 0,
    enableRadialLabels: true,
    slicesLabelsSkipAngle: 0,
    sliceLabel: ({ pct }) => pct + '%',
    slicesLabelsTextColor: 'white',
    isInteractive: true,
    theme: {
      labels: {
        text: { fontSize: 16 },
      },
    },
    radialLabelsSkipAngle: 0,
    radialLabelsTextXOffset: 10,
    radialLabelsTextColor: ({ color }) => color,
    radialLabelsLinkOffset: 0,
    radialLabelsLinkDiagonalLength: 30,
    radialLabelsLinkHorizontalLength: 36,
    radialLabelsLinkStrokeWidth: 1,
    radialLabelsLinkColor: { from: 'color' },
    tooltip: ({ id, value }) => (
      <>
        <div style={{ fontSize: '1rem', marginBottom: '7px' }}>{id}</div>
        <strong style={{ fontWeight: 'bold' }}>{t(sourceType === 'time' ? 'charts.hourCount' : 'charts.activityCount') + ': ' + value}</strong>
      </>
    ),
  }

  return (
    <>
      <NavBar showLogin={shouldShowSignInButton} onClickSignIn={() => showSignInForm(true)} />

      <Wrapper maxWidth="lg">
        {!loading && !firebaseAuth.currentUser && shouldShowSignInForm && (
          <SignInContainer>
            <div>
              <CancelSignIn onClick={() => showSignInForm(false)} />
              <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebaseAuth} />
            </div>
          </SignInContainer>
        )}
        {!shouldShowSignInForm && (
          <>
            <Title>Hands on Tool Time benchmark</Title>
            <Info style={{ opacity: benchHours ? 1 : 0 }}>{t('home.intro', { hours: benchHours })}</Info>
            <ChartWrapper>
              {chartType === 'bar' && (
                <ResponsiveBar
                  data={data}
                  keys={['value']}
                  indexBy="label"
                  margin={{ top: 30, right: 0, bottom: 60, left: 90 }}
                  padding={0.3}
                  colors={({ data }) => data.color}
                  labelSkipWidth={0}
                  labelSkipHeight={0}
                  labelTextColor={({ data }) => data.data.color}
                  label={({ data }) => data.pct + '%'}
                  labelFormat={d => <tspan y={-14}>{d}</tspan>}
                  animate={true}
                  axisLeft={{
                    tickSize: 0,
                    tickPadding: 25,
                    tickRotation: 2,
                    legend: t(`charts.legends.${sourceType}`),
                    legendPosition: 'middle',
                    legendOffset: -80,
                  }}
                  axisBottom={{
                    tickSize: 0,
                    format: d => <tspan style={{ fontSize: '1rem' }}>{d}</tspan>,
                    tickPadding: isIE ? 25 : 15,
                  }}
                  theme={{
                    labels: {
                      text: { fontSize: 16 },
                    },
                    axis: {
                      fontSize: '30px',
                    },
                  }}
                  tooltip={({ value, indexValue }) => (
                    <div style={{ padding: '9px' }}>
                      <div style={{ marginBottom: '7px' }}>{indexValue}:</div>
                      <strong>
                        {t(sourceType === 'time' ? 'charts.hourCount' : 'charts.activityCount')}: {value}
                      </strong>
                    </div>
                  )}
                />
              )}
              {chartType === 'pie' && <ResponsivePie data={data} {...pieChartProps} />}

              <OptionsBar>
                <div>
                  <Button variant={chartType === 'bar' ? undefined : 'contained'} onClick={() => setChartType('bar')} className={classes.iconButton}>
                    <EqualizerIcon />
                  </Button>
                  <Button variant={chartType === 'pie' ? undefined : 'contained'} onClick={() => setChartType('pie')} className={classes.iconButton}>
                    <DonutSmallIcon />
                  </Button>
                </div>
                <div>
                  <Button variant={sourceType === 'time' ? undefined : 'contained'} onClick={() => setSourceType('time')}>
                    {t('charts.options.time')}
                  </Button>
                  <Button variant={sourceType === 'count' ? undefined : 'contained'} onClick={() => setSourceType('count')}>
                    {t('charts.options.count')}
                  </Button>
                </div>
              </OptionsBar>
            </ChartWrapper>
          </>
        )}
      </Wrapper>
    </>
  )
}

export default Home
