import React, { useEffect, useState } from 'react'
import firebase from 'firebase/app'
import styled from '@emotion/styled'
import { format } from 'date-fns'
import { isNil, omit, pathOr } from 'ramda'
import { useTranslation } from 'react-i18next'

import { Container, makeStyles } from '@material-ui/core'
import Fab from '@material-ui/core/Fab'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'

import activityOptions from './activityOptions'
import Switch from '@material-ui/core/Switch'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableHead from '@material-ui/core/TableHead'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import DoneIcon from '@material-ui/icons/Done'
import ClearIcon from '@material-ui/icons/Clear'
import CreateIcon from '@material-ui/icons/Create'

import { firestore } from '../../firebase'
import { timeDifference } from '../../utils/misc'
import { primaryColor, secondaryColor } from '../../theme'
import DurationModal from './DurationModal'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
    display: 'block',
    margin: '12px 0',
  },
  select_dp: {
    color: 'green !important',
  },
  select_ip: {
    color: 'blue !important',
  },
  select_np: {
    color: 'red !important',
  },
  select_pz: {
    color: 'black !important',
  },
  select_disabled: {
    '& svg': {
      display: 'none !important',
    },
  },
  dp: {
    color: 'white !important',
    backgroundColor: 'green !important',
  },
  ip: {
    color: 'white !important',
    backgroundColor: 'blue !important',
  },
  np: {
    color: 'white !important',
    backgroundColor: 'red !important',
  },
  pz: {
    color: 'white !important',
    backgroundColor: 'black !important',
  },
  table: {
    width: 'auto',
  },
}))

const TimeInfo = styled.div`
  margin-bottom: 1rem;
  display: flex;
  position: relative;
  user-select: none;
`

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 12px;
`

const Clear = styled(ClearIcon)`
  font-size: 16px;
  margin: 0 16px;
  cursor: pointer;
`

const Label = styled.span`
  font-size: 16px;
  color: #616161;
  min-width: 8rem;
  user-select: none;
`
const Yes = styled.span`
  padding-left: 12px;
  color: ${props => secondaryColor};
`
const No = styled.span`
  padding-left: 12px;
`
const PreviewImage = styled.img`
  height: 80px;
`
const FileInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`

const ChangeDurationContainer = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  right: -40px;
  top: 0;
  padding: 15px;
`

const { Timestamp, FieldValue } = firebase.firestore

const cleanState = state => omit(['activity', 'previousActivity', 'nextActivity', 'activityId'], state)

const cleanActivity = activity => {
  let result = { ...activity }
  if (isNil(activity.category) || activity.category === 'pz1') {
    result = omit(['avoidable'], result)
  } else {
    result.avoidable = !!activity.avoidable
  }
  if (activity.subcategory === null) {
    result = omit(['subcategory'], result)
  }
  return omit(['id', 'end', 'picCount'], result)
}

const EditActivity = ({ licenseId, projectId, measurementId, activity, previousActivity, nextActivity, onOk, onCancel }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [values, setValues] = useState({})
  const [isDurationModalOpen, showDurationModal] = useState(false)

  const end = activity && activity.end ? activity.end.seconds : null
  const sliderMinValue = previousActivity ? previousActivity.begin.seconds : values.begin ? values.begin.seconds : 0
  const sliderMaxValue = nextActivity ? (nextActivity.end ? nextActivity.end.seconds : Timestamp.now().seconds) : end

  const projectRef = firestore.doc(`data/${licenseId}/projects/${projectId}`)
  const measurementRef = projectRef.collection('measurements').doc(measurementId)

  const activityRef = measurementRef.collection('activities').doc(activity.id)

  useEffect(() => {
    const begin = new Timestamp(activity.begin.seconds, activity.begin.nanoseconds)

    if (activity) {
      setValues({
        ...omit(['duration'], activity),
        begin,
      })
    } else {
      setValues({ begin })
    }
  }, [])

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value })
  }

  const onChangeDuration = (begin, end) => {
    setValues({ ...values, begin, end })
    showDurationModal(false)
  }

  const onSubmit = async () => {
    const batch = firestore.batch()

    const activityValues = cleanActivity(values)
    onOk(values)
    // const fields = ['begin', 'category', 'subcategory', 'remark', 'avoidable']
    // fields.forEach(field => {
    //   batch.update(activityRef, { [field]: activityValues[field] || FieldValue.delete() })
    // })
    //
    // const didUpdateEndTime = values.end && activity.end && values.end.seconds !== activity.end.seconds
    // if (didUpdateEndTime) {
    //   if (nextActivity) {
    //     const nextActivityRef = measurementRef.collection('activities').doc(nextActivity.id)
    //     batch.update(nextActivityRef, { begin: new Timestamp(values.end.seconds, 0) })
    //   } else if (measurement.end) {
    //     batch.update(measurementRef, { end: new Timestamp(values.end.seconds, 0) })
    //   }
    // }
    // batch.commit()
  }

  const onChangeCategory = event => {
    const { value } = event.target
    setValues({
      ...values,
      category: value,
      subcategory: null,
    })
  }

  return (
    <>
      <Dialog open onClose={onCancel}>
        <DialogTitle>
          <span style={{ color: primaryColor, fontWeight: 'bold', paddingLeft: '14px' }}>Edit activity</span>
        </DialogTitle>
        <DialogContent style={{ minWidth: '30rem' }}>
          <TimeInfo>
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell>{t('activity.create.begin')}</TableCell>
                  {values.end && (
                    <>
                      <TableCell>{t('activity.create.end')}</TableCell>
                      <TableCell style={{ position: 'relative' }}>
                        {t('activity.create.duration')}
                        {/*{!isDurationModalOpen && (*/}
                        {/*  <ChangeDurationContainer onClick={() => showDurationModal(true)}>*/}
                        {/*    <CreateIcon />*/}
                        {/*  </ChangeDurationContainer>*/}
                        {/*)}*/}
                      </TableCell>
                    </>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{values.begin && format(values.begin.toDate(), 'HH:mm')}</TableCell>
                  {values.begin && values.end && (
                    <>
                      <TableCell>{format(new Timestamp(values.end.seconds, 0).toDate(), 'HH:mm')}</TableCell>
                      <TableCell>{timeDifference(values.begin.toDate(), new Timestamp(values.end.seconds, 0).toDate())}</TableCell>
                    </>
                  )}
                </TableRow>
              </TableBody>
            </Table>
          </TimeInfo>

          {isDurationModalOpen && (
            <DurationModal
              onChange={onChangeDuration}
              min={sliderMinValue}
              max={sliderMaxValue}
              onCancel={() => showDurationModal(false)}
              begin={values.begin.seconds}
              end={values.end && values.end.seconds}
            />
          )}
          <InputWrapper>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="category">{t(`activity.category`)}</InputLabel>
              <Select
                id="category"
                value={values.category || ''}
                fullWidth
                onChange={onChangeCategory}
                // className={values.category ? classes[`select_${values.category.substring(0, 2)}`] : null}
              >
                {activityOptions(t).category.map(item => (
                  <MenuItem
                    value={item.value}
                    key={item.value}
                    // className={classes[item.value.substring(0, 2)]}
                  >
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {values.category && <Clear onClick={() => setValues({ ...values, category: null })} />}
          </InputWrapper>
          {values.category && activityOptions(t).subcategory[values.category] && (
            <InputWrapper>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="subcategory">{t(`activity.subcategory`)}</InputLabel>
                <Select
                  id="subcategory"
                  value={values.subcategory || ''}
                  fullWidth
                  onChange={handleChange('subcategory')}
                  // className={classes[`select_${values.category.substring(0, 2)}`]}
                >
                  {activityOptions(t).subcategory[values.category].map(item => (
                    <MenuItem
                      value={item.value}
                      key={item.value}
                      // className={classes[values.category.substring(0, 2)]}
                    >
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {values.subcategory && <Clear onClick={() => setValues({ ...values, subcategory: null })} />}
            </InputWrapper>
          )}
          <InputWrapper>
            <FormControl className={classes.formControl}>
              <TextField
                id="remark"
                fullWidth
                label={t('activity.remark')}
                value={values.remark}
                onChange={handleChange('remark')}
                InputLabelProps={{ shrink: !!values.remark }}
              />
            </FormControl>
            {values.remark && <Clear onClick={() => setValues({ ...values, remark: '' })} />}
          </InputWrapper>
          {values.category !== 'pz1' && (
            <InputWrapper style={{ marginTop: '30px' }}>
              <Label>
                {t('activity.create.avoidable')}: {values.avoidable ? <Yes>{t('common.YES')}</Yes> : <No>{t('common.NO')}</No>}
              </Label>
              <Switch
                checked={!!values.avoidable}
                onChange={e => setValues({ ...values, avoidable: e.target.checked })}
                value="avoidable"
                color="secondary"
              />
            </InputWrapper>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="primary">
            {t('common.CANCEL')}
          </Button>
          <Button onClick={onSubmit} variant="contained" color="secondary">
            {t('common.OK')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default EditActivity
