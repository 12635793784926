import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import endOfDay from 'date-fns/endOfDay'
import firebase from 'firebase/app'
import { pathOr } from 'ramda'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import MenuItem from '@material-ui/core/MenuItem'
import Snackbar from '@material-ui/core/Snackbar'

import TextField from '../../components/form/TextField'
import SelectField from '../../components/form/SelectField'
import DatePickerField from '../../components/form/DatePickerField'
import CheckboxField from '../../components/form/CheckboxField'
import SnackbarBody from '../../components/SnackbarBody'
import { firestore } from '../../firebase'

const { Timestamp } = firebase.firestore

const Title = styled(DialogTitle)`
  color: white !important;
  background-color: #7f9e5d;
  padding-right: 16px !important;
  h2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  span {
    padding-right: 1rem;
  }
`
const ContentWrapper = styled(DialogContent)`
  min-width: 25rem;
  padding-top: 20px;
`
const CreateLicenseDialog = styled(Dialog)`
  .MuiPaper-root {
    padding: 0;
  }
  .MuiCheckbox-root {
    margin-bottom: 4px;
    margin-left: -12px;
  }
`
const CloseModalIcon = styled(CloseIcon)`
  cursor: pointer;
`
const ActionsWrapper = styled.div`
  padding: 8px 15px;
`

const LICENSE_TYPES = ['1star', '2star', '3star', 'training']
const typeOptions = t =>
  LICENSE_TYPES.map(option => ({
    value: option,
    label: t(`accountInfo.licenseTypes.${option}`),
  }))

const CreateLicenseModal = ({ onCancel, onSuccess }) => {
  const { t } = useTranslation()
  const [error, setError] = useState(false)

  return (
    <CreateLicenseDialog open onClose={onCancel}>
      <Formik
        initialValues={{
          tokenCount: null,
          name: '',
          type: null,
          expires: new Date(),
          active: true,
        }}
        validationSchema={Yup.object({
          name: Yup.string()
            .max(20, 'Must be 20 characters or less')
            .required('Required'),
          type: Yup.string()
            .nullable()
            .required('Required'),
          tokenCount: Yup.number()
            .nullable()
            .min(0, 'Must be a positive number')
            .required('Required'),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const { active, tokenCount, expires, type, name } = values
            const license = {
              active,
              tokenCount,
              expires: new Timestamp(endOfDay(expires).getTime() / 1000, 0),
              type,
              name,
              created: Timestamp.now(),
            }
            const { id } = await firestore.collection('data').add(license)
            onSuccess({ ...license, id })
          } catch (e) {
            setError(pathOr('An error occurred', ['message'], e))
          }
          setSubmitting(false)
        }}
      >
        <Form noValidate>
          <Title>
            <span>{t('licenseManager.createModal.title')}</span> <CloseModalIcon onClick={onCancel} />
          </Title>
          <ContentWrapper>
            <TextField fullWidth name="name" label={t('licenseManager.createModal.nameLabel')} required />

            <SelectField name="type" fullWidth label={t('licenseManager.createModal.typeLabel')}>
              {typeOptions(t).map(item => (
                <MenuItem value={item.value} key={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </SelectField>

            <TextField fullWidth name="tokenCount" type="number" label={t('licenseManager.createModal.tokenLabel')} required />

            <DatePickerField name="expires" format="dd MMM yyyy" label={t('licenseManager.createModal.expires')} required />

            <CheckboxField name="active" label={t('licenseManager.createModal.activeLabel')} />
          </ContentWrapper>

          <ActionsWrapper>
            <DialogActions>
              <Button onClick={onCancel} color="primary">
                {t('common.CANCEL')}
              </Button>
              <Button type="submit" variant="contained" color="secondary">
                {t('common.SAVE')}
              </Button>
            </DialogActions>
          </ActionsWrapper>
        </Form>
      </Formik>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={error}
        onClose={() => setError(false)}
      >
        <SnackbarBody onClose={() => setError(false)} variant="error" message={error} />
      </Snackbar>
    </CreateLicenseDialog>
  )
}

export default CreateLicenseModal
